var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('mypage-layout', [_c('dashboard-header'), _c('div', {
    staticClass: "pb-14px pb-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center",
      "justify": "space-between",
      "justify-md": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "small": "",
      "to": "./destinations"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("배송지 관리")]), _vm._v(" "), _c('i', {
    staticClass: "icon icon-pin ml-4px"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "small": "",
      "to": "./orders"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("전체주문 조회")]), _vm._v(" "), _c('v-icon', {
    staticClass: "ml-4px",
    attrs: {
      "size": "16"
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1), _c('v-tabs', {
    attrs: {
      "centered": "",
      "grow": ""
    },
    model: {
      value: _vm.purchaseTab,
      callback: function ($$v) {
        _vm.purchaseTab = $$v;
      },
      expression: "purchaseTab"
    }
  }, [_c('tab-primary', {
    attrs: {
      "tab-value": _vm.PURCHASE_QUERY_CODES.purchaseOnProcess.value
    }
  }, [_vm._v("최근 주문현황")]), _c('tab-primary', {
    attrs: {
      "tab-value": _vm.PURCHASE_QUERY_CODES.claimProcessGoing.value
    }
  }, [_vm._v("반품/교환/취소/환불")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.purchaseTab,
      callback: function ($$v) {
        _vm.purchaseTab = $$v;
      },
      expression: "purchaseTab"
    }
  }, [_c('v-tab-item', {
    staticClass: "fill-height",
    attrs: {
      "value": _vm.PURCHASE_QUERY_CODES.purchaseOnProcess.value
    }
  }, [_c('mypage-dashboard-recent', _vm._b({}, 'mypage-dashboard-recent', {
    active: _vm.purchaseTab == _vm.PURCHASE_QUERY_CODES.purchaseOnProcess.value
  }, false))], 1), _c('v-tab-item', {
    staticClass: "fill-height",
    attrs: {
      "value": _vm.PURCHASE_QUERY_CODES.claimProcessGoing.value
    }
  }, [_c('mypage-dashboard-return', _vm._b({}, 'mypage-dashboard-return', {
    active: _vm.purchaseTab == _vm.PURCHASE_QUERY_CODES.claimProcessGoing.value
  }, false))], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }