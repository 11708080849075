<template>
    <item-dialog v-bind="{ coupon }">
        <template #activator="{ attrs, on }">
            <v-card v-bind="{ ...attrs, loading }" flat tile class="card-coupon" @click="on.click">
                <v-row no-gutters align="stretch" class="fill-height">
                    <v-col>
                        <v-card v-bind="{ disabled }" outlined rounded="xs" class="card-coupon__left">
                            <div class="card-coupon__left-inner">
                                <item-chip v-bind="{ coupon }" class="mb-8px" />
                                <item-name v-bind="{ coupon }" class="mb-12px" />
                                <item-price v-bind="{ coupon }" class="mb-2px" />
                                <item-desc v-bind="{ coupon }" mode="user-coupons" />
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="auto">
                        <v-card rounded="xs" color="indigo" class="card-coupon__right">
                            <div class="card-coupon__right-inner">
                                <div class="card-coupon__right-close">
                                    <v-btn small fab tile color="transparent" @click.prevent.stop.capture="remove">
                                        <v-icon color="white">mdi-close</v-icon>
                                    </v-btn>
                                </div>
                                <span class="card-coupon__right-txt">COUPON</span>

                                <!-- <div class="ma-md-auto" v-if="coupon.code">
                                    <div class="txt txt--xs text-md-center">
                                        쿠폰번호<span class="d-md-none"> : </span><br class="d-none d-md-block" />
                                        <span class="txt--dark">{{ coupon.code }}</span>
                                    </div>
                                </div> -->
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <item-spec v-bind="{ coupon }" />
        </template>
    </item-dialog>
</template>

<script>
import api from "@/api";
import { initUserCoupon } from "@/assets/variables";

import ItemChip from "./item/item-chip.vue";
import ItemDesc from "./item/item-desc.vue";
import ItemDialog from "./item/item-dialog.vue";
import ItemName from "./item/item-name.vue";
import ItemPrice from "./item/item-price.vue";
import ItemSpec from "./item/item-spec.vue";

export default {
    components: {
        ItemChip,
        ItemDesc,
        ItemDialog,
        ItemName,
        ItemPrice,
        ItemSpec,
    },
    props: {
        coupon: { type: Object, default: initUserCoupon },
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        disabled() {
            return this.coupon.isUsed;
        },
    },
    methods: {
        async remove() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { coupon } = await api.v1.me.coupons.delete({
                    _id: this.coupon._id,
                });
                this.loading = false;
                this.$emit("search");
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.card-coupon {
    overflow: hidden;
    &.v-card--disabled {
        pointer-events: auto;
        user-select: auto;
        cursor: pointer;
        opacity: 0.3;
        & > :not(.v-card__progress) {
            opacity: 1;
        }
        &.theme--light {
            &.v-card {
                color: var(--default-color);
            }
            &.v-sheet--outlined {
                border: 1px solid var(--border-color);
            }
        }
    }
    &__left {
        height: 100%;
        overflow: hidden;
        &-inner {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 20px 30px;
        }
    }
    &__right {
        height: 100%;
        width: 60px;
        &-inner {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 100%;
            padding: 12px;
            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0;
                z-index: 1;
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                border: 1px solid var(--border-color);
                background-color: #fff;
            }
            &::before {
                top: 0;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
            &::after {
                bottom: 0;
                -webkit-transform: translate(-50%, 50%);
                -ms-transform: translate(-50%, 50%);
                transform: translate(-50%, 50%);
            }
        }
        &-txt {
            display: flex;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.5em;
            font-family: var(--font-secondary);
            color: #fff;
            transform: rotate(90deg);
            margin-bottom: -0.5em;
        }
        &-close {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .card-coupon {
        &__right {
            width: 80px;
        }
    }
}
@media (min-width: 1024px) {
    .card-coupon {
        &__left {
            &-inner {
                padding: 30px 40px;
            }
        }
    }
}
@media (min-width: 1200px) {
}
</style>
