<template>
    <client-page class="contents-bottom-md-none">
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="교환 신청">
            <!-- S: 상단(모바일) -->
            <tit-wrap-line noDivider class="d-xl-none">
                <v-row align="center" class="ma-n2px ma-sm-n6px">
                    <v-col cols="12" sm="auto" class="pa-2px pa-sm-6px">
                        <span class="txt txt--sm"> 상품정보 </span>
                    </v-col>
                </v-row>
            </tit-wrap-line>
            <!-- E: 상단(모바일) -->
            <!-- S: 상단(PC) -->
            <v-divider style="border-width: 2px 0 0" class="border-dark d-none d-xl-block" />
            <div class="pt-xl-16px mb-xl-n4px">
                <tit-wrap-line noDivider class="d-none d-xl-block">
                    <v-row align="center" class="row--xs">
                        <v-col> </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="w-md-60px text-center">
                                <div class="txt txt--sm txt--dark font-weight-medium">수량</div>
                            </div>
                        </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="w-md-120px text-center">
                                <div class="txt txt--sm txt--dark font-weight-medium">상품금액</div>
                            </div>
                        </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="w-md-60px text-center">
                                <div class="txt txt--sm txt--dark font-weight-medium">신청수량</div>
                            </div>
                        </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="w-md-120px text-center">
                                <div class="txt txt--sm txt--dark font-weight-medium">주문상태</div>
                            </div>
                        </v-col>
                    </v-row>
                </tit-wrap-line>
            </div>
            <!-- E: 상단(PC) -->
            <v-divider :class="$vuetify.breakpoint.xl ? '' : 'border-dark'" />

            <v-card tile>
                <div class="py-20px py-xl-10px">
                    <v-row align-xl="center" class="ma-n6px ma-lg-n12px">
                        <!-- S: 정보(모바일) -->
                        <v-col cols="12" class="pa-6px pa-lg-12px d-xl-none">
                            <div class="txt txt--sm">
                                <span>상품주문번호 : 202401020001_001</span>
                            </div>
                        </v-col>
                        <!-- E: 정보(모바일) -->
                        <v-col cols="12" xl="" class="pa-6px pa-lg-12px">
                            <!-- S: 이미지, 정보(모바일) -->
                            <div class="d-xl-none">
                                <v-row align="center" class="ma-n6px ma-xl-n12px">
                                    <v-col cols="auto" class="pa-6px pa-xl-12px">
                                        <v-card tile outlined class="overflow-hidden">
                                            <router-link to="/">
                                                <v-img :aspect-ratio="130 / 100" width="104">
                                                    <template #placeholder>
                                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                    </template>
                                                </v-img>
                                            </router-link>
                                        </v-card>
                                    </v-col>
                                    <v-col class="pa-6px pa-xl-12px">
                                        <router-link to="/">
                                            <div class="txt txt--dark">볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)</div>
                                        </router-link>
                                        <div class="txt txt--sm txt--dark mt-4px">1,650,000원</div>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- E: 이미지, 정보(모바일) -->

                            <!-- S: 이미지, 정보(PC) -->
                            <div class="d-none d-xl-block">
                                <v-row align="center" class="ma-n6px ma-lg-n12px">
                                    <v-col cols="auto" class="pa-6px pa-lg-12px">
                                        <v-card tile outlined class="overflow-hidden">
                                            <router-link to="/">
                                                <v-img :aspect-ratio="130 / 100" width="130">
                                                    <template #placeholder>
                                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                    </template>
                                                </v-img>
                                            </router-link>
                                        </v-card>
                                    </v-col>
                                    <v-col class="pa-6px pa-lg-12px">
                                        <div class="txt txt--sm mb-4px mb-md-8px mb-lg-14px">
                                            <span class="pr-6px">결제완료</span>
                                            <span>상품주문번호 : 202401020001_001</span>
                                        </div>
                                        <router-link to="/">
                                            <div class="txt txt--dark">볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)</div>
                                        </router-link>
                                        <div class="txt txt--sm mt-4px mt-md-8px">색상 : 블랙 / 수량 : 1개</div>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- E: 이미지, 정보(PC) -->
                        </v-col>
                        <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                            <div class="w-xl-60px text-xl-center d-none d-xl-block">
                                <div class="txt txt--sm">
                                    <span class="d-xl-none">수량 : </span>
                                    <span>1</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="6" md="4" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                            <div class="w-xl-120px text-xl-center">
                                <div class="txt txt--sm">
                                    <span class="d-xl-none">상품금액 : </span>
                                    <span>1,650,000원</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                            <div class="w-xl-60px text-xl-center d-none d-xl-block">
                                <div class="txt txt--sm">
                                    <span class="d-xl-none">신청수량 : </span>
                                    <span>1</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                            <div class="w-xl-120px text-xl-center d-none d-xl-block">
                                <div class="txt txt--sm">
                                    <span class="d-xl-none">주문상태 : </span>
                                    <span>상품준비중</span>
                                </div>
                            </div>
                        </v-col>
                        <!-- S: 수량, 신청수량, 주문상태(모바일) -->
                        <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-xl-none">
                            <div class="text-center grey lighten-5">
                                <v-row no-gutters align="center">
                                    <v-col>
                                        <div class="txt txt--xs pa-12px">
                                            <span>색상 : 블랙</span>
                                            <span class="px-4px">/</span>
                                            <span>수량 : 1</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto" align-self="stretch" class="ml-n1px"><v-divider vertical /></v-col>
                                    <v-col>
                                        <div class="txt txt--xs pa-12px">
                                            <span>신청수량 : </span>
                                            <span>1</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto" align-self="stretch" class="ml-n1px"><v-divider vertical /></v-col>
                                    <v-col>
                                        <div class="txt txt--xs pa-12px">
                                            <span>주문상태 : </span>
                                            <span>상품준비중</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <!-- E: 수량, 신청수량, 주문상태(모바일) -->
                    </v-row>
                </div>
                <v-divider />
            </v-card>

            <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit tit--xxs">사유선택</div>
                    </div>

                    <u-vertical-items>
                        <u-vertical-item :rowProps="{ align: 'center' }" txtContentClass="px-12px py-6px py-md-8px" borderNone>
                            <template #tit> 사유선택 </template>
                            <template #txt>
                                <v-select v-bind="{ ...attrs_input }" dense label="선택" class="w-100 w-md-300px" />
                            </template>
                        </u-vertical-item>
                        <u-vertical-item txtContentClass="px-12px py-6px py-md-8px" borderNone>
                            <template #tit> 상세사유 </template>
                            <template #txt>
                                <v-textarea v-bind="{ ...attrs_input }" dense no-resize />
                            </template>
                        </u-vertical-item>
                    </u-vertical-items>
                </template>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit tit--xxs">수거신청</div>
                    </div>

                    <v-divider class="border-dark" />
                    <u-txt-items>
                        <u-txt-item innerClass="py-20px border-lg-b" titContentClass="w-100 w-md-110px pl-xl-12px">
                            <template #tit> <span class="font-weight-medium">수거 신청 여부</span> </template>
                            <template #txt>
                                <v-radio-group row hide-details="auto">
                                    <v-radio v-bind="{ ...radio_primary }" label="직접 판매자에게 발송" value="직접 판매자에게 발송" />
                                    <v-radio v-bind="{ ...radio_primary }" label="지정 택배사에서 가져가주세요" value="지정 택배사에서 가져가주세요" />
                                </v-radio-group>
                                <div class="txt txt--sm secondary--text mt-10px mt-md-14px">[14558] 경기도 부천시 조마루로 385번길 92(부천 테크노밸리 U1 center)1712호</div>
                            </template>
                        </u-txt-item>
                    </u-txt-items>
                </template>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit tit--xxs">수거지 정보</div>
                    </div>

                    <v-divider class="border-dark" />
                    <u-txt-items rowSize="no-gutters">
                        <u-txt-item innerClass="py-20px border-b" titContentClass="w-100 w-md-110px pl-xl-12px">
                            <template #tit> <span class="font-weight-medium">수거지 선택</span> </template>
                            <template #txt>
                                <v-radio-group row hide-details="auto">
                                    <v-radio v-bind="{ ...radio_primary }" label="배송지 정보와 동일" value="배송지 정보와 동일" />
                                    <v-radio v-bind="{ ...radio_primary }" label="직접입력" value="직접입력" />
                                </v-radio-group>
                            </template>
                        </u-txt-item>
                        <u-txt-item innerClass="py-20px border-lg-b" titContentClass="w-100 w-md-110px pl-xl-12px">
                            <template #tit> <span class="font-weight-medium">수거지 주소</span> </template>
                            <template #txt>
                                <div class="txt txt--sm txt--dark">홍길동</div>
                                <div class="txt txt--sm txt--dark mt-4px">[ 14547 ] 경기도 부천시 길주로 202 (부천시청역) 12</div>
                                <div class="txt txt--sm mt-4px">( 경기도 부천시 중동 1243 부천시청역 12 )</div>
                            </template>
                        </u-txt-item>
                    </u-txt-items>
                </template>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit tit--xxs">환불 방법</div>
                    </div>

                    <v-divider class="border-dark" />
                    <div class="py-12px">
                        <v-row class="ma-n2px">
                            <v-col cols="12" class="pa-2px">
                                <v-row class="ma-n4px txt font-size-12 font-size-md-14">
                                    <v-col cols="auto" class="pa-4px">•</v-col>
                                    <v-col class="pa-4px">예치금은 유효기간 내 현금으로 출금 및 상품 구매 시 사용 가능합니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="pa-2px">
                                <v-row class="ma-n4px txt font-size-12 font-size-md-14">
                                    <v-col cols="auto" class="pa-4px">•</v-col>
                                    <v-col class="pa-4px">전자금융법에 의해 200만원 이상의 보유는 불가합니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="pa-2px">
                                <v-row class="ma-n4px txt font-size-12 font-size-md-14">
                                    <v-col cols="auto" class="pa-4px">•</v-col>
                                    <v-col class="pa-4px">결제 수단이 무통장 또는 가상계좌인 경우 환불계좌 정보는 필수 입력사항입니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="pa-2px">
                                <v-row class="ma-n4px txt font-size-12 font-size-md-14">
                                    <v-col cols="auto" class="pa-4px">•</v-col>
                                    <v-col class="pa-4px">관리자로부터 환불 금액을 입금 받을 계좌 정보를 입력해주세요.</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider :style="$vuetify.breakpoint.mdAndUp ? 'border-width: 2px 0 0' : ''" class="border-dark" />

                    <!-- S: 버튼(PC) -->
                    <div class="btn-wrap btn-wrap--md mx-n12px mx-md-0px d-none d-md-block">
                        <v-row justify="center" class="ma-0px ma-md-n4px">
                            <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                                <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large w-100 min-w-md-140px">신청하기</v-btn>
                            </v-col>
                            <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                                <v-btn v-bind="{ ...btn_secondary }" class="v-size--xx-large min-w-md-140px d-none d-md-block">취소</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- E: 버튼(PC) -->
                </template>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <v-row class="ma-n14px my-lg-n24px">
                        <v-col cols="12" lg="6" class="pa-14px py-lg-24px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit tit--xxs">교환/반품으로 인한 배송비 발생 기준안내</div>
                            </div>

                            <div>
                                <div class="txt txt--sm pb-16px"><b class="red--text">구매자 부담</b><span class="pl-4px pl-md-8px">(반품 배송비 + 최초 배송비)</span></div>
                                <v-divider class="border-dark" />
                                <div class="py-12px">
                                    <v-row align="center" class="row--xxs">
                                        <v-col cols="auto">
                                            <div class="txt txt--sm txt--dark font-weight-bold">반품 → 환불</div>
                                        </v-col>
                                        <v-col>
                                            <div class="txt txt--sm">반품 시 배송비는 반품의 원인을 제공한 자가 부담합니다.<br />구매자의 변심으로 반품을 원할 경우에는 구매자가 배송비를 지불합니다.</div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-divider />
                                <div class="py-12px">
                                    <v-row align="center" class="row--xxs">
                                        <v-col cols="auto">
                                            <div class="txt txt--sm txt--dark font-weight-bold">반품 → 교환</div>
                                        </v-col>
                                        <v-col>
                                            <div class="txt txt--sm">상품 교환 시 배송비는 교환의 원인을 제공한 자가 부담합니다.<br />구매자의 변심으로 교환을 원할 경우에는 구매자가 배송비를 지불합니다.</div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-divider class="border-dark" />
                            </div>

                            <div class="pt-24px">
                                <div class="txt txt--sm pb-16px"><b class="secondary--text">판매자 부담</b><span class="pl-4px pl-md-8px">(반품 배송비 + 최초 배송비)</span></div>
                                <v-divider class="border-dark" />
                                <div class="py-12px">
                                    <v-row align="center" class="row--xxs">
                                        <v-col cols="auto">
                                            <div class="txt txt--sm txt--dark font-weight-bold">반품 → 환불</div>
                                        </v-col>
                                        <v-col>
                                            <div class="txt txt--sm">반품 시 배송비는 반품의 원인을 제공한 자가 부담합니다.<br />상품 하자나 제품 불일치로 인하 반품의 경우에는 판매자가 배송비를 지불합니다.</div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-divider />
                                <div class="py-12px">
                                    <v-row align="center" class="row--xxs">
                                        <v-col cols="auto">
                                            <div class="txt txt--sm txt--dark font-weight-bold">반품 → 교환</div>
                                        </v-col>
                                        <v-col>
                                            <div class="txt txt--sm">상품 교환 시 배송비는 교환의 원인을 제공한 자가 부담합니다.<br />상품 하자나 제품 불일치로 인하 반품의 경우에는 판매자가 배송비를 지불합니다.</div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-divider class="border-dark" />
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6" class="pa-14px py-lg-24px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit tit--xxs">교환/반품 배송비</div>
                            </div>
                            <div>
                                <div class="txt txt--sm txt--dark font-weight-bold mb-4px">교환/반품 배송비 입금</div>
                                <div class="txt txt--sm">반송 시 상품에 배송비를 동봉 하실 경우 발생할 수 있는 분실 사고 및 책임분쟁을 방지하고자 배송비 전용 계좌로 입금을 해주셔야 합니다.</div>
                            </div>
                            <div class="mt-16px mt-md-30px">
                                <div class="txt txt--sm txt--dark font-weight-bold mb-4px">교환/반품 배송비 내역(5000원)</div>
                                <div class="txt txt--sm">
                                    교환 : 반송 착불 배송비 2500 + 재발송 배송비 2500<br />
                                    반품 : 최소 발송 배송비 2500 + 반송 착불 배송비 2500<br />
                                    (최초 배송비를 결제 하신 경우도 해당 배송비는 상품 가격과 함께 환불 처리됩니다)<br />
                                    도서지역 : 제주도를 포함한 도서지역은 왕복 4000원으 추가 배송비가 발생합니다.
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6" class="pa-14px py-lg-24px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit tit--xxs">교환/반품이 가능한 경우는?</div>
                            </div>
                            <div>
                                <div class="txt txt--sm txt--dark font-weight-bold mb-4px">단순변심</div>
                                <div class="txt txt--sm">수령한 상품의 사이즈 변경 또는 디자인, 색상 등이 마음에 들지 않아, 수령 일 기준 7일이내 교환/반품 접수 및 배송비 입금, 반송 처리하시는 경우</div>
                            </div>
                            <div class="mt-16px mt-md-30px">
                                <div class="txt txt--sm txt--dark font-weight-bold mb-4px">배송오류 및 불량</div>
                                <div class="txt txt--sm">
                                    주문하신 상품과 다른 상품을 수령하셨거나, 제조상 명백한 불량의 상품을 수령하신 경우<br />
                                    단, 불량 상품의 경우 세탁 및 수선과정 이후 발생 또는 발견하는 손상 및 불량은 확인이 불가하므로 해당하지 않습니다.
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6" class="pa-14px py-lg-24px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit tit--xxs">교환/반품이 불가능한 경우는?</div>
                            </div>
                            <div>
                                <div class="txt txt--sm txt--dark font-weight-bold mb-4px">청약철회 기간 경과 또는 구매 확정</div>
                                <div class="txt txt--sm">상품 수령 후 7일 이내 교환/반품 접수 및 배송비 입금, 반송 처리를 하지 않으신 경우 또는 구매 확정이 완료되어 포인트가 지급된 경우</div>
                            </div>
                            <div class="mt-16px mt-md-30px">
                                <div class="txt txt--sm txt--dark font-weight-bold mb-4px">상품 착용 또는 훼손</div>
                                <div class="txt txt--sm">상품 착용의 흔적이 있거나, 라벨 및 텍 제거, 제품 박스 및 포장 제거 등으로 새 상품으로서의 가치가 감소한 경우</div>
                            </div>
                            <div class="mt-16px mt-md-30px">
                                <div class="txt txt--sm txt--dark font-weight-bold mb-4px">교환불가(반품가능)</div>
                                <div class="txt txt--sm">구매하신 상품의 사이즈 또는 컬러, 동일가 상품 교환 이외에 교환을 원하시는 상품의 가격이 다른 경우 반품 후 재 주문을 해주셔야 합니다.</div>
                            </div>
                        </v-col>
                    </v-row>

                    <!-- S: 버튼(모바일) -->
                    <div class="btn-wrap btn-wrap--lg mx-n12px mx-md-0px d-md-none">
                        <v-row justify="center" class="ma-0px ma-md-n4px">
                            <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                                <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large w-100 min-w-md-140px">신청하기</v-btn>
                            </v-col>
                            <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                                <v-btn v-bind="{ ...btn_primary3 }" class="v-size--xx-large w-100 d-md-none">취소</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- E: 버튼(모바일) -->
                </template>
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_primary, btn_primary3, btn_secondary, btn_secondary2, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import UTxtItems from "@/components/client/dumb/u-txt-items.vue";
import UTxtItem from "@/components/client/dumb/u-txt-item.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        PageSection,
        TitWrapLine,
        UTxtItems,
        UTxtItem,
        UDialog,
        VerticalFormTable,
    },
    props: {},
    data: function () {
        return {
            btn_primary,
            btn_primary3,
            btn_secondary,
            btn_secondary2,
            attrs_input,

            loading: false,

            dialog: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading) return;
            this.loading = true;
        },
    },

    //     <div class="txt txt--xs">
    //     <router-link to="/">HOME</router-link>
    //     <v-icon small>mdi-chevron-right</v-icon>
    //     <router-link to="/mypage/dashboard">마이페이지</router-link>
    //     <v-icon small>mdi-chevron-right</v-icon>
    //     <router-link to="/mypage/orders">주문/배송</router-link>
    //     <v-icon small>mdi-chevron-right</v-icon>
    //     <router-link to="/mypage/orders/exchange-application">교환신청</router-link>
    // </div>
    computed: {
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                // { text: "교환신청", to: { path: "/mypage/orders/exchange-application" } },
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "교환신청" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
};
</script>
