var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "contents-bottom-md-none",
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('mypage-layout', {
    attrs: {
      "title": "결제취소/환불 신청"
    }
  }, [_c('tit-wrap-line', {
    staticClass: "d-xl-none",
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "ma-n2px ma-sm-n6px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2px pa-sm-6px",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 상품정보 ")])])], 1)], 1), _c('v-divider', {
    staticClass: "border-dark d-none d-xl-block",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "pt-xl-16px mb-xl-n4px"
  }, [_c('tit-wrap-line', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col'), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-60px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("수량")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("상품금액")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-60px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("취소수량")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("주문상태")])])])], 1)], 1)], 1), _c('v-divider', {
    class: _vm.$vuetify.breakpoint.xl ? '' : 'border-dark'
  }), _c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "py-20px py-xl-10px"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align-xl": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', [_vm._v("상품주문번호 : 202401020001_001")])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "12",
      "xl": ""
    }
  }, [_c('div', {
    staticClass: "d-xl-none"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-xl-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-xl-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": "104"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-xl-12px"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v("볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)")])]), _c('div', {
    staticClass: "txt txt--sm txt--dark mt-4px"
  }, [_vm._v("1,650,000원")])], 1)], 1)], 1), _c('div', {
    staticClass: "d-none d-xl-block"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": "130"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px"
  }, [_c('div', {
    staticClass: "txt txt--sm mb-4px mb-md-8px mb-lg-14px"
  }, [_c('span', {
    staticClass: "pr-6px"
  }, [_vm._v("결제완료")]), _c('span', [_vm._v("상품주문번호 : 202401020001_001")])]), _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v("볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)")])]), _c('div', {
    staticClass: "txt txt--sm mt-4px mt-md-8px"
  }, [_vm._v("색상 : 블랙 / 수량 : 1개")])], 1)], 1)], 1)]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-60px text-xl-center d-none d-xl-block"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("수량 : ")]), _c('span', [_vm._v("1")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "6",
      "md": "4",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("상품금액 : ")]), _c('span', [_vm._v("1,650,000원")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-60px text-xl-center d-none d-xl-block"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("취소수량 : ")]), _c('span', [_vm._v("1")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center d-none d-xl-block"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("주문상태 : ")]), _c('span', [_vm._v("상품준비중")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "text-center grey lighten-5"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', [_vm._v("색상 : 블랙")]), _c('span', {
    staticClass: "px-4px"
  }, [_vm._v("/")]), _c('span', [_vm._v("수량 : 1")])])]), _c('v-col', {
    staticClass: "ml-n1px",
    attrs: {
      "cols": "auto",
      "align-self": "stretch"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', [_vm._v("취소수량 : ")]), _c('span', [_vm._v("1")])])]), _c('v-col', {
    staticClass: "ml-n1px",
    attrs: {
      "cols": "auto",
      "align-self": "stretch"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', [_vm._v("주문상태 : ")]), _c('span', [_vm._v("상품준비중")])])])], 1)], 1)])], 1)], 1), _c('v-divider')], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("환불방법")])]), _c('u-vertical-items', [_c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 은행 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-select', _vm._b({
                staticClass: "w-100 w-md-300px",
                attrs: {
                  "dense": "",
                  "label": "선택"
                }
              }, 'v-select', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 예금주 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-text-field', _vm._b({
                staticClass: "w-100 w-md-300px",
                attrs: {
                  "dense": ""
                }
              }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 계좌번호 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-text-field', _vm._b({
                staticClass: "w-100 w-md-358px",
                attrs: {
                  "dense": ""
                }
              }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        })], 1), _c('div', {
          staticClass: "pt-12px"
        }, [_c('v-row', {
          staticClass: "ma-n2px"
        }, [_c('v-col', {
          staticClass: "pa-2px",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-row', {
          staticClass: "ma-n4px txt font-size-12 font-size-md-14"
        }, [_c('v-col', {
          staticClass: "pa-4px",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("•")]), _c('v-col', {
          staticClass: "pa-4px"
        }, [_vm._v("환불방법은 복합결제(마일리지, 쿠폰 사용 등) 및 최초 배송비 계산 등의 이유로 쇼핑몰 관리자와 협의 후 결정됩니다.")])], 1)], 1), _c('v-col', {
          staticClass: "pa-2px",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-row', {
          staticClass: "ma-n4px txt font-size-12 font-size-md-14"
        }, [_c('v-col', {
          staticClass: "pa-4px",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("•")]), _c('v-col', {
          staticClass: "pa-4px"
        }, [_vm._v("결제 수단이 무통장 또는 가상계좌인 경우 환불 계좌 정보는 필수 입력 사항입니다.")])], 1)], 1), _c('v-col', {
          staticClass: "pa-2px",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-row', {
          staticClass: "ma-n4px txt font-size-12 font-size-md-14"
        }, [_c('v-col', {
          staticClass: "pa-4px",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("•")]), _c('v-col', {
          staticClass: "pa-4px"
        }, [_vm._v("관리자로부터 환불 금액을 입금 받을 계좌 정보를 입력해주세요.")])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("사유선택")])]), _c('u-vertical-items', [_c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 사유선택 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-select', _vm._b({
                staticClass: "w-100 w-md-300px",
                attrs: {
                  "dense": "",
                  "label": "선택"
                }
              }, 'v-select', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 상세사유 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-textarea', _vm._b({
                attrs: {
                  "dense": "",
                  "no-resize": ""
                }
              }, 'v-textarea', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("최초 배송비")])]), _c('v-divider', {
          staticClass: "border-dark"
        }), _c('div', {
          staticClass: "py-12px",
          staticStyle: {
            "min-height": "150px"
          }
        }, [_c('v-row', {
          staticClass: "ma-n2px"
        }, [_c('v-col', {
          staticClass: "pa-2px",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-row', {
          staticClass: "ma-n4px txt font-size-12 font-size-md-14"
        }, [_c('v-col', {
          staticClass: "pa-4px",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("•")]), _c('v-col', {
          staticClass: "pa-4px"
        }, [_vm._v("부분 결제 취소 시 추가 배송비가 발생할 수 있으며, 이 때 추가 배송비를 결제해주셔야만 결제취소 처리완료가 가능합니다.")])], 1)], 1), _c('v-col', {
          staticClass: "pa-2px",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-row', {
          staticClass: "ma-n4px txt font-size-12 font-size-md-14"
        }, [_c('v-col', {
          staticClass: "pa-4px",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("•")]), _c('v-col', {
          staticClass: "pa-4px"
        }, [_vm._v("추가 배송비가 발생하는 경우,")])], 1)], 1), _c('v-col', {
          staticClass: "pa-2px",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "txt font-size-12 font-size-md-14"
        }, [_vm._v("① ‘묶음 배송비’ 상품의 배송비 무료(금액별 차등) 조건을 충족하여 배송비 무료")])]), _c('v-col', {
          staticClass: "pa-2px",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "txt font-size-12 font-size-md-14"
        }, [_vm._v("② 부분 결제취소로 배송비 무료 조건을 불충족하는 경우 추가 배송비 부과")])]), _c('v-col', {
          staticClass: "pa-2px",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "txt font-size-12 font-size-md-14"
        }, [_vm._v("③ 추가 배송비는 카드 또는 마일리지로 결제 가능")])])], 1)], 1), _c('v-divider', {
          staticClass: "border-dark d-none d-md-block",
          staticStyle: {
            "border-width": "2px 0 0"
          }
        }), _c('div', {
          staticClass: "btn-wrap btn-wrap--md mx-n12px mx-md-0px"
        }, [_c('v-row', {
          staticClass: "ma-0px ma-md-n4px",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0px pa-md-4px",
          attrs: {
            "cols": "6",
            "md": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 min-w-md-140px"
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("신청하기")])], 1), _c('v-col', {
          staticClass: "pa-0px pa-md-4px",
          attrs: {
            "cols": "6",
            "md": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large min-w-md-140px d-none d-md-block"
        }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("취소")]), _c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 d-md-none"
        }, 'v-btn', Object.assign({}, _vm.btn_primary3), false), [_vm._v("취소")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }