var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-xl-none"
  }, [_c('v-divider', {
    staticClass: "border-dark",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _vm._l(_vm.items, function (item, index) {
    var _item$amount, _item$amount$format;
    return _c('div', {
      key: index,
      staticClass: "border-b"
    }, [_c('div', {
      staticClass: "grey lighten-5"
    }, [_c('div', {
      staticClass: "d-flex align-center px-4px py-12px border-b",
      staticStyle: {
        "min-height": "54px"
      }
    }, [_c('v-row', {
      staticClass: "mx-n6px my-n2px",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "px-6px py-2px",
      attrs: {
        "cols": "auto",
        "sm": ""
      }
    }, [_c('v-row', {
      staticClass: "ma-n4px",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "pa-4px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("날짜")])]), _c('v-col', {
      staticClass: "pa-4px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "txt txt--xs"
    }, [_vm._v(_vm._s(_vm.$dayjs(item.createdAt).format("YYYY.MM.DD")))])])], 1)], 1), _c('v-col', {
      staticClass: "px-6px py-2px",
      attrs: {
        "cols": "auto",
        "sm": ""
      }
    }, [_c('v-row', {
      staticClass: "ma-n4px",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "pa-4px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("유효기간")])]), _c('v-col', {
      staticClass: "pa-4px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "txt txt--xs"
    }, [_vm._v(_vm._s(_vm.formatExpiration(item.usage)))])])], 1)], 1)], 1)], 1)]), _c('div', {
      staticClass: "py-20px"
    }, [_c('v-row', {
      staticClass: "row--xs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "txt txt--sm"
    }, [_c('div', {
      class: {
        'red--text': (item.amount || 0) < 0,
        'secondary--text': 0 < (item.amount || 0)
      }
    }, [_vm._v(" " + _vm._s((item.amount || 0) > 0 ? "+" : "") + _vm._s(((_item$amount = item.amount) === null || _item$amount === void 0 ? void 0 : (_item$amount$format = _item$amount.format) === null || _item$amount$format === void 0 ? void 0 : _item$amount$format.call(_item$amount)) || 0) + "원 ")])])]), _c('v-col', [_c('div', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(_vm._s(item.remark))])])], 1)], 1)]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }