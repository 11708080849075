<template>
    <u-dialog v-model="shows" title="취소 신청" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <div v-bind="{ loading }">
            
            <v-row v-for="(purchase, index) in purchases" :key="index">
                <v-col>
                    <!-- 상품주문일자 -->
                    <div class="tit tit--xs pb-12px">{{ purchase?.createdAt?.toDate?.() }}</div>
                    <v-divider style="border-width: 2px 0 0" class="grey darken-4" />
                    <purchase-item :value="purchase" mode="view" withQuantity />
                </v-col>
            </v-row>
            <v-divider />

            <!-- S:사유 -->
            <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small title="취소 사유"></tit-wrap-x-small>
                <v-select v-model="form.claimReason" placeholder="취소 사유 선택해주세요" :items="claimReasonItems" v-bind="{ ...attrs_input, loading }" />
            </page-section>
            <!-- E:사유 -->

            <!-- S:상세 -->
            <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small title="취소 상세">
                    <span class="font-size-12 red--text">*카드결제 하신 고객께서는 바로 "취소 신청" 버튼을 눌러 주세요.</span>
                </tit-wrap-x-small>
                <v-textarea v-model="form.claimReasonDetails" placeholder="취소 상세를 입력해주세요" v-bind="{ ...attrs_input, loading }" />
            </page-section>
            <!-- E:상세 -->

            <!-- 개발확인 -->
            <!-- S:계좌 -->
            <!-- <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small title="환불계좌 정보">
                    <span class="font-size-12 red--text">*본인 명의의 통장으로만 입금 받으실 수 있습니다.</span>
                </tit-wrap-x-small>
                <v-row class="row--xs">
                    <v-col cols="12">
                        <autocomplete-bank v-bind="{ ...attrs_input, loading }" placeholder="은행사 선택" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-bind="{ ...attrs_input, loading }" placeholder="계좌번호를 입력하세요." />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-bind="{ ...attrs_input, loading }" placeholder="예금주명을 입력하세요." />
                    </v-col>
                </v-row>
            </page-section> -->
            <!-- E:계좌 -->
        </div>

        <template #actions>
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-bind="{ ...btn_tertiary }" block class="v-size--xx-large" @click="close">취소</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-bind="{ ...btn_primary }" block class="v-size--xx-large" @click="save">
                        {{ isClaimCompleted ? "취소 신청" : "취소 요청" }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, initOrder, initPurchase, PURCHASE_CLAIM_STATUSES } from "@/assets/variables";
import { btn_primary, btn_tertiary, radio_primary } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";
import AutocompleteBank from "@/components/dumb/autocomplete-bank.vue";
import PurchaseItem from "./purchase-item.vue";

export default {
    components: {
        UDialog,
        PageSection,
        TitWrapXSmall,
        AutocompleteBank,
        PurchaseItem,
    },
    props: {
        // value: { type: Array, default: () => ([]) }, // purchase
        value: { type: Object, default: initOrder() },
        type: { type: String, default: PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value },
    },
    data: () => ({
        btn_primary,
        btn_tertiary,
        radio_primary,

        purchases: [],
        form: {},

        // ui
        shows: false,
        loading: false,
        claimStatus: null,

        attrs_input,
        PURCHASE_CLAIM_STATUSES,
    }),
    computed: {
        claimReasonItems() {
            let items = [];

            items.push("기타");

            return items;
        },
        isClaimCompleted() {
            return [PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value].includes(this.claimStatus);
        }
    },
    mounted() {
        this.sync();
    },
    watch: {
        shows(shows) {
            if (shows) this.sync();
        },
    },
    methods: {
        sync() {
            const { purchases, paymentMethod } = this.value;
            this.purchases = purchases.map((purchase)=> initPurchase(purchase));
            this.claimStatus = paymentMethod === 'vbank' ? PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value : this.type;
        },

        emit() {
            this.$emit("input", this.purchases);
        },

        close() {
            this.shows = false;
        },

        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                for (let purchase of this.purchases) {
                    let { _id, orderStatus } = purchase;
                    let { claimReason, claimReasonDetails } = this.form;
                    let { claimStatus } = this;

                    let form = {
                        _id,
                        claimStatus,
                        claimReason,
                        claimReasonDetails,
                        orderStatus,
                    };
                    await api.v1.me.purchases.put(form);
                };

                if(this.isClaimCompleted) {
                    await api.v1.me.orders.cancel({ _id: this.value._id });
                    alert("해당 주문에 대한 전체 취소가 완료되었습니다.");
                }else {
                    alert("해당 주문에 대한 전체 취소가 요청되었습니다.");
                }

                this.emit();

                this.close();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
