var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('spec-row', _vm._b({
    scopedSlots: _vm._u([{
      key: "desc",
      fn: function () {
        var _vm$coupon;
        return [_c('span', {
          class: _vm.className
        }, [_vm._v(" " + _vm._s((_vm$coupon = _vm.coupon) === null || _vm$coupon === void 0 ? void 0 : _vm$coupon.stateText) + " ")])];
      },
      proxy: true
    }])
  }, 'spec-row', {
    term: _vm.term
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }