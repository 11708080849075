<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="나의 단골">
            <div class="tabs-wrap tabs-wrap--lg">
                <v-tabs v-model="tabs" centered grow>
                    <tab-primary exact>나의 단골 AS샵</tab-primary>
                    <tab-primary exact>나의 단골 미니홈</tab-primary>
                </v-tabs>
            </div>

            <v-tabs-items v-model="tabs">
                <!-- S: 나의 단골 미니샵 -->
                <v-tab-item>
                    <div class="pb-30px pb-md-8px">
                        <!-- S: Mobile 내 근처 AS샵 보기 -->
                        <v-btn v-bind="{ ...btn_secondary }" block class="d-md-none">
                            <v-img src="/images/icon/icon-marker-pin.png" max-width="16" class="mr-4px" />
                            내 근처 AS샵 보기
                        </v-btn>
                        <!-- E: Mobile 내 근처 AS샵 보기 -->

                        <!-- S: PC 내 근처 AS샵 보기 -->
                        <v-row justify="end" no-gutters>
                            <v-col cols="auto">
                                <v-btn v-bind="{ ...btn_txt }" small color="grey" class="d-none d-md-block ml-auto" to="/sub/store/map"><v-img src="/images/icon/icon-marker-pin-grey.png" max-width="16" class="mr-4px" /> 내 근처 AS샵 보기</v-btn>
                            </v-col>
                        </v-row>
                        <!-- E: PC 내 근처 AS샵 보기 -->
                    </div>
                    <!-- 반복 -->
                    <v-row class="row--sm">
                        <v-col v-for="(shop, index) in follows" :key="index" cols="6" lg="4">
                            <store-profile v-bind="{ value: shop }">
                                <template #avatar>
                                    <v-img v-if="shop.thumb" :src="shop.thumb.url" />
                                    <v-img v-else src="/images/minishop/profile-default.png" />
                                </template>
                            </store-profile>
                        </v-col>
                        <v-col cols="6" lg="4"></v-col>
                        <v-col cols="6" lg="4"></v-col>
                    </v-row>
                    <!-- // 반복 -->
                    <div class="pagination-wrap pagination-wrap--sm">
                        <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                    </div>
                </v-tab-item>
                <!-- E: 나의 단골 미니샵 -->
                <!-- S: 나의 단골 미니홈 -->
                <v-tab-item>
                    <v-row class="row--sm">
                        <v-col v-for="(home, index) in follows" :key="index" cols="6" xl="4">
                            <store-profile v-bind="{ value: home }">
                                <template #avatar>
                                    <v-img v-if="home.thumb" :src="home.thumb.url" />
                                    <v-img v-else src="/images/minishop/profile-default.png" />
                                </template>
                            </store-profile>
                        </v-col>
                        <v-col cols="6" lg="4"></v-col>
                        <v-col cols="6" lg="4"></v-col>
                    </v-row>
                    <!-- // 반복 -->
                    <div class="pagination-wrap pagination-wrap--sm">
                        <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                    </div>
                </v-tab-item>
                <!-- E: 나의 단골 미니홈 -->
            </v-tabs-items>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_secondary, btn_secondary2, btn_txt, USER_TYPES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";

import StoreProfile from "@/components/client/sub/store/store-profile.vue";

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        PageSection,
        TabPrimary,

        StoreProfile,
    },
    data: () => ({
        btn_secondary,
        btn_secondary2,
        btn_txt,

        tabs: null,
        summary: { totalCount: 0 },
        follows: [],

        limit: 6,
    }),
    computed: {
        breadcrumbsItems() {
            const items = [{ text: "HOME" }, { text: "마이페이지" }, { text: "나의 단골" }];
            return items.map((item) => ({ ...item, disabled: false }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            query._user = this.$store.state.payload._user;
            query.type = this.tabs === 0 ? USER_TYPES.COMPANY.value : USER_TYPES.PERSON.value;

            return { ...query };
        },
    },
    methods: {
        async init() {
            this.search();
        },
        async search() {
            try {
                let { skip, limit, params } = this;
                const { summary, follows } = await api.v1.me.follows.getsByType({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.follows = follows.map((item) => item.following);
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        tabs() {
            this.follows = [];
            this.$router.push({...this.params, page: 1})
            this.search();
        },
        "$route.query"() {
            this.search();
        }
    }
};
</script>
