<template>
    <page-section class="page-section--sm pb-0">
        <template slot="containerImmersive">
            <item-head v-model="order" v-on="{ init }" @input="emit" />

            <div class="border-t border-2 border-dark">
                <template v-for="purchase in order.purchases">
                    <purchase-item :key="purchase._id" :value="purchase">
                        <template #actions>
                            <v-row class="row--xs">
                                <purchase-item-claim :value="purchase" v-on="{ init }">
                                    <template #activator="{ attrs, on }">
                                        <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                            <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" block x-small @click.prevent>취소 신청</v-btn>
                                        </v-col>
                                    </template>
                                </purchase-item-claim>
                                <purchase-item-claim :value="purchase" :type="PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value" v-on="{ init }">
                                    <template #activator="{ attrs, on }">
                                        <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                            <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" block x-small @click.prevent>취소 요청</v-btn>
                                        </v-col>
                                    </template>
                                </purchase-item-claim>
                                <purchase-item-claim-withdraw :value="purchase" v-on="{ init }">
                                    <template #activator="{ attrs, on }">
                                        <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                            <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" block x-small @click.prevent>취소 철회</v-btn>
                                        </v-col>
                                    </template>
                                </purchase-item-claim-withdraw>
                                <purchase-item-delivery :value="purchase">
                                    <template #activator="{ attrs, on }">
                                        <v-btn v-bind="{ ...btn_secondary, ...attrs }" v-on="on" block @click.stop>배송 조회</v-btn>
                                    </template>
                                </purchase-item-delivery>
                                <purchase-item-claim :value="purchase" :type="PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value" v-on="{ init }">
                                    <template #activator="{ attrs, on }">
                                        <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                            <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" block x-small @click.prevent>교환·반품 신청</v-btn>
                                        </v-col>
                                    </template>
                                </purchase-item-claim>
                                <purchase-item-claim-withdraw :value="purchase" :type="PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value" v-on="{ init }">
                                    <template #activator="{ attrs, on }">
                                        <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                            <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" block x-small @click.prevent>교환·반품 철회</v-btn>
                                        </v-col>
                                    </template>
                                </purchase-item-claim-withdraw>
                                <purchase-item-claim-withdraw :value="purchase" :type="PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value" v-on="{ init }">
                                    <template #activator="{ attrs, on }">
                                        <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                            <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" block x-small @click.prevent>교환·반품 철회</v-btn>
                                        </v-col>
                                    </template>
                                </purchase-item-claim-withdraw>
                                <purchase-item-claim-reject :value="purchase">
                                    <template #activator="{ attrs, on, title }">
                                        <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                            <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" block x-small @click.prevent>{{ title }} 거절 사유</v-btn>
                                        </v-col>
                                    </template>
                                </purchase-item-claim-reject>
                                <purchase-item-review v-bind="{ value: purchase, points }" v-on="{ init }">
                                    <template #activator="{ attrs, on }">
                                        <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                            <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" block x-small @click.prevent>리뷰 작성</v-btn>
                                        </v-col>
                                    </template>
                                </purchase-item-review>
                                <purchase-item-inquire :value="purchase">
                                    <template #activator="{ attrs, on }">
                                        <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                            <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" block x-small @click.prevent>문의하기</v-btn>
                                        </v-col>
                                    </template>
                                </purchase-item-inquire>
                                <purchase-item-cart :value="purchase">
                                    <template #activator="{ attrs, on }">
                                        <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                            <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" block x-small @click.prevent>장바구니</v-btn>
                                        </v-col>
                                    </template>
                                </purchase-item-cart>
                            </v-row>
                        </template>
                    </purchase-item>
                </template>
            </div>
        </template>
    </page-section>
</template>

<script>
import { initOrder } from "@/assets/variables";
import { ISSUE_EVENT_TYPES, PURCHASE_CLAIM_STATUSES } from "@/assets/variables/constants";
import { btn_secondary, btn_secondary2 } from "@/assets/variables/attrs";

import PageSection from "@/components/client/templates/page-section.vue";

import PurchaseItem from "@/components/client/mypage/purchase/purchase-item.vue";
import PurchaseItemCart from "@/components/client/mypage/purchase/purchase-item-cart.vue";
import PurchaseItemClaim from "@/components/client/mypage/purchase/purchase-item-claim.vue";
import PurchaseItemClaimReject from "@/components/client/mypage/purchase/purchase-item-claim-reject.vue";
import PurchaseItemClaimWithdraw from "@/components/client/mypage/purchase/purchase-item-claim-withdraw.vue";
import PurchaseItemDelivery from "@/components/client/mypage/purchase/purchase-item-delivery.vue";
import PurchaseItemInquire from "@/components/client/mypage/purchase/purchase-item-inquire.vue";
import PurchaseItemReview from "@/components/client/mypage/purchase/purchase-item-review.vue";

import ItemHead from "./item/item-head.vue";

export default {
    components: {
        PageSection,

        PurchaseItem,
        PurchaseItemCart,
        PurchaseItemClaim,
        PurchaseItemClaimReject,
        PurchaseItemClaimWithdraw,
        PurchaseItemDelivery,
        PurchaseItemInquire,
        PurchaseItemReview,

        ItemHead,
    },
    props: {
        value: { type: Object, default: initOrder },
        points: { type: Object, default: () => ({}) },
    },
    data: () => ({
        order: initOrder(),

        ISSUE_EVENT_TYPES,
        PURCHASE_CLAIM_STATUSES,

        btn_secondary,
        btn_secondary2,
    }),
    methods: {
        sync() {
            this.order = initOrder(this.value);
        },
        emit() {
            this.$emit("input", this.order);
        },
        init() {
            this.$emit("init");
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>