<template>
    <tit-wrap-line noDivider>
        <v-row align="baseline" class="row--xs">
            <v-col>
                <v-row align="baseline" class="ma-n2px ma-sm-n6px">
                    <v-col cols="12" sm="auto" class="pa-2px pa-sm-6px">
                        <span class="tit tit--xxs">{{ order?.createdAt?.toDate?.() }}</span>
                    </v-col>
                    <v-col cols="12" sm="auto" class="pa-2px pa-sm-6px">
                        <span class="txt txt--sm"> 주문번호 : {{ order?.orderNo }} </span>
                    </v-col>
                    <v-col cols="12" sm="auto" class="pa-2px pa-sm-6px">
                        <order-view v-model="order" @input="emit">
                            <template #activator="{ attrs, on }">
                                <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" class="v-size--xx-small">상세보기</v-btn>
                            </template>
                        </order-view>
                        <order-vbank-view v-if="isVbank" v-model="order" @input="emit">
                            <template #activator="{ attrs, on }">
                                <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" class="v-size--xx-small">가상계좌</v-btn>
                            </template>
                        </order-vbank-view>
                        <purchases-dialog-claim v-if="claimable" v-model="order" v-bind="{ type }" @input="$emit('init')">
                            <template #activator="{ attrs, on }">
                                <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" class="v-size--xx-small">전체취소</v-btn>
                            </template>
                        </purchases-dialog-claim>
                    </v-col>
                    <v-col cols="12" sm="auto" class="pa-2px pa-sm-6px">
                        <order-item-complete v-bind="{ ...btn_secondary2, order }" v-on="{ init }" class="v-size--xx-small" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="auto" class="d-none d-xl-block">
                <div class="w-md-120px text-center">
                    <div class="txt txt--sm txt--dark font-weight-medium">판매사</div>
                </div>
            </v-col>
            <v-col cols="auto" class="d-none d-xl-block">
                <div class="w-md-120px text-center">
                    <div class="txt txt--sm txt--dark font-weight-medium">구매금액</div>
                </div>
            </v-col>
            <v-col cols="auto" class="d-none d-xl-block">
                <div class="w-md-120px text-center">
                    <div class="txt txt--sm txt--dark font-weight-medium">주문상태</div>
                </div>
            </v-col>
            <!-- v-if -->
            <!-- <v-col cols="auto" class="d-none d-xl-block">
                <div class="w-md-120px text-center">
                    <div class="txt txt--sm txt--dark font-weight-medium">반품/교환/취소/환불</div>
                </div>
            </v-col> -->
            <v-col cols="auto">
                <div class="w-md-120px text-center">
                    <div class="txt txt--sm txt--dark font-weight-medium">주문처리</div>
                </div>
            </v-col>
        </v-row>
    </tit-wrap-line>
</template>

<script>
import { initOrder, PURCHASE_CLAIM_STATUSES, PURCHASE_ORDER_STATUSES, PURCHASE_PURCHASE_STATUSES } from "@/assets/variables";
import { btn_secondary2 } from "@/assets/variables/attrs";

import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";

import OrderItemComplete from "@/components/client/mypage/order/order-item-complete.vue";
import OrderView from "@/components/client/mypage/order/order-view.vue";
import OrderVbankView from "@/components/client/mypage/order/order-vbank-view.vue";
import PurchasesDialogClaim from "@/components/client/mypage/purchase/purchases-dialog-claim.vue";

export default {
    components: {
        TitWrapLine,

        OrderItemComplete,
        OrderView,
        OrderVbankView,
        PurchasesDialogClaim,
    },
    props: {
        value: { type: Object, default: initOrder },
        type: { type: String, default: PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value },
    },
    data: () => ({
        order: initOrder(),

        btn_secondary2,
    }),
    computed: {
        isVbank() {
            return this.value.vbank && this.value.vbank.vbankNumber && this.value.vbank.vbankName;
        },
        validClaimStatus() {
            return [PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value, PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value, PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value, PURCHASE_CLAIM_STATUSES.RETURN_COMPLETED.value, PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value, PURCHASE_CLAIM_STATUSES.EXCHANGE_COMPLETED.value];
        },
        validPurchaseStatus() {
            return [null, PURCHASE_PURCHASE_STATUSES.PURCHASE_AWAITING.value, PURCHASE_PURCHASE_STATUSES.PURCHASE_SCHEDULE.value];
        },
        validOrderStatus() {
            return [PURCHASE_ORDER_STATUSES.ORDER_NONPAYMENT.value, PURCHASE_ORDER_STATUSES.ORDER_PAYMENT.value];
        },
        claimable() {
            let { _purchases, purchases } = this.value;

            // 취소된 건이 없을 때
            let fullOrder = _purchases.every((_purchase) => purchases.map(({ _id }) => _id).includes(_purchase));

            let claimStatusMatches = purchases.map(({ claimStatus }) => !this.validClaimStatus.includes(claimStatus)).every((match) => match);

            let purchaseStatusMatches = purchases.map(({ purchaseStatus }) => this.validPurchaseStatus.includes(purchaseStatus)).every((match) => match);

            let orderStatusMatches = purchases.map(({ orderStatus }) => this.validOrderStatus.includes(orderStatus)).every((match) => match);

            return claimStatusMatches && orderStatusMatches && purchaseStatusMatches && fullOrder;
        },
    },
    methods: {
        sync() {
            this.order = initOrder(this.value);
        },
        emit() {
            this.$emit("input", this.order);
        },
        init() {
            this.$emit("init");
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>