<template>
    <client-page class="contents-bottom-md-none">
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout>
            <v-tabs-items :value="tab" touchless>
                <myinfo-tab-verify @next="tab = 'modify'" />
                <v-tab-item value="modify">
                    <div class="tit-wrap">
                        <div class="tit tit--xxs">회원정보 수정</div>
                    </div>
                    <myinfo-person-form v-if="!hasCompanyScope" v-bind="{ value: form, loading }" @input="save" @cancel="tab = 'verify'"/>
                    <myinfo-company-form v-else v-bind="{ value: form, loading }" @input="save" @cancel="tab = 'verify'"/>

                </v-tab-item>
            </v-tabs-items>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { initUser } from "@/assets/variables";
import { btn_primary, btn_primary3, btn_secondary, checkbox_primary, radio_primary, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import MyinfoTabVerify from "@/components/client/mypage/myinfo/myinfo-tab-verify.vue";
import MyinfoPersonForm from "@/components/client/mypage/myinfo/myinfo-person-form.vue";
import MyinfoCompanyForm from "@/components/client/mypage/myinfo/myinfo-company-form.vue";
import { mapGetters } from 'vuex';

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        MyinfoTabVerify,
        MyinfoPersonForm,
        MyinfoCompanyForm
    },
    data: () => ({
        btn_primary,
        btn_primary3,
        btn_secondary,
        checkbox_primary,
        radio_primary,
        attrs_input,
        loading: false,

        form: initUser(),

        tab: "verify",
        // tab: "modify",
    }),
    computed: {
        ...mapGetters(["hasCompanyScope"]),
        accessToken() {
            return this.$store.state.accessToken;
        },
        breadcrumbsItems() {
            const items = [
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "회원정보 수정" },
            ];
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (!this.accessToken) {
                    this.$router.push("/login");
                    return;
                }

                this.form = initUser((await api.v1.me.get())?.user);
                this.form.birthday = this.form.birthday?.toDate();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        async save(value) {
            if(this.loading) return;
            else this.loading = true;
            try {
                let { password, _businessRegistration, businessRegistration, ...form } = value;

                if (password) form.password = CryptoAES.encrypt(password);
                form.geolocation = await this.$getGeolocationWithKakaoMaps(form.address1);
                
                const { user } = await api.v1.me.put(form);

                if(businessRegistration instanceof File && this.hasCompanyScope) {
                    businessRegistration = (await api.v1.files.post({ userId: user._id, path: "businessRegistration" }, businessRegistration))?.file;

                    if(_businessRegistration && _businessRegistration !== businessRegistration._id) {
                        await api.v1.files.delete({_id: _businessRegistration});
                    }
                    
                    await api.v1.users.put({ _id: user._id, _businessRegistration: businessRegistration._id });
                }

                this.form = user;

                alert("수정되었습니다");
                this.loading = false;
                this.init();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            } finally{
                this.loading = false;
            }
        },
    },
};
</script>
