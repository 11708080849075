<template>
    <client-page class="contents-bottom-md-none">
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="결제취소/환불 신청">
            <!-- S: 상단(모바일) -->
            <tit-wrap-line noDivider class="d-xl-none">
                <v-row align="center" class="ma-n2px ma-sm-n6px">
                    <v-col cols="12" sm="auto" class="pa-2px pa-sm-6px">
                        <span class="txt txt--sm"> 상품정보 </span>
                    </v-col>
                </v-row>
            </tit-wrap-line>
            <!-- E: 상단(모바일) -->
            <!-- S: 상단(PC) -->
            <v-divider style="border-width: 2px 0 0" class="border-dark d-none d-xl-block" />
            <div class="pt-xl-16px mb-xl-n4px">
                <tit-wrap-line noDivider class="d-none d-xl-block">
                    <v-row align="center" class="row--xs">
                        <v-col> </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="w-md-60px text-center">
                                <div class="txt txt--sm txt--dark font-weight-medium">수량</div>
                            </div>
                        </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="w-md-120px text-center">
                                <div class="txt txt--sm txt--dark font-weight-medium">상품금액</div>
                            </div>
                        </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="w-md-60px text-center">
                                <div class="txt txt--sm txt--dark font-weight-medium">취소수량</div>
                            </div>
                        </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="w-md-120px text-center">
                                <div class="txt txt--sm txt--dark font-weight-medium">주문상태</div>
                            </div>
                        </v-col>
                    </v-row>
                </tit-wrap-line>
            </div>
            <!-- E: 상단(PC) -->
            <v-divider :class="$vuetify.breakpoint.xl ? '' : 'border-dark'" />

            <v-card tile>
                <div class="py-20px py-xl-10px">
                    <v-row align-xl="center" class="ma-n6px ma-lg-n12px">
                        <!-- S: 정보(모바일) -->
                        <v-col cols="12" class="pa-6px pa-lg-12px d-xl-none">
                            <div class="txt txt--sm">
                                <span>상품주문번호 : 202401020001_001</span>
                            </div>
                        </v-col>
                        <!-- E: 정보(모바일) -->
                        <v-col cols="12" xl="" class="pa-6px pa-lg-12px">
                            <!-- S: 이미지, 정보(모바일) -->
                            <div class="d-xl-none">
                                <v-row align="center" class="ma-n6px ma-xl-n12px">
                                    <v-col cols="auto" class="pa-6px pa-xl-12px">
                                        <v-card tile outlined class="overflow-hidden">
                                            <router-link to="/">
                                                <v-img :aspect-ratio="130 / 100" width="104">
                                                    <template #placeholder>
                                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                    </template>
                                                </v-img>
                                            </router-link>
                                        </v-card>
                                    </v-col>
                                    <v-col class="pa-6px pa-xl-12px">
                                        <router-link to="/">
                                            <div class="txt txt--dark">볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)</div>
                                        </router-link>
                                        <div class="txt txt--sm txt--dark mt-4px">1,650,000원</div>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- E: 이미지, 정보(모바일) -->

                            <!-- S: 이미지, 정보(PC) -->
                            <div class="d-none d-xl-block">
                                <v-row align="center" class="ma-n6px ma-lg-n12px">
                                    <v-col cols="auto" class="pa-6px pa-lg-12px">
                                        <v-card tile outlined class="overflow-hidden">
                                            <router-link to="/">
                                                <v-img :aspect-ratio="130 / 100" width="130">
                                                    <template #placeholder>
                                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                    </template>
                                                </v-img>
                                            </router-link>
                                        </v-card>
                                    </v-col>
                                    <v-col class="pa-6px pa-lg-12px">
                                        <div class="txt txt--sm mb-4px mb-md-8px mb-lg-14px">
                                            <span class="pr-6px">결제완료</span>
                                            <span>상품주문번호 : 202401020001_001</span>
                                        </div>
                                        <router-link to="/">
                                            <div class="txt txt--dark">볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)</div>
                                        </router-link>
                                        <div class="txt txt--sm mt-4px mt-md-8px">색상 : 블랙 / 수량 : 1개</div>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- E: 이미지, 정보(PC) -->
                        </v-col>
                        <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                            <div class="w-xl-60px text-xl-center d-none d-xl-block">
                                <div class="txt txt--sm">
                                    <span class="d-xl-none">수량 : </span>
                                    <span>1</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="6" md="4" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                            <div class="w-xl-120px text-xl-center">
                                <div class="txt txt--sm">
                                    <span class="d-xl-none">상품금액 : </span>
                                    <span>1,650,000원</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                            <div class="w-xl-60px text-xl-center d-none d-xl-block">
                                <div class="txt txt--sm">
                                    <span class="d-xl-none">취소수량 : </span>
                                    <span>1</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                            <div class="w-xl-120px text-xl-center d-none d-xl-block">
                                <div class="txt txt--sm">
                                    <span class="d-xl-none">주문상태 : </span>
                                    <span>상품준비중</span>
                                </div>
                            </div>
                        </v-col>
                        <!-- S: 수량, 취소수량, 주문상태(모바일) -->
                        <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-xl-none">
                            <div class="text-center grey lighten-5">
                                <v-row no-gutters align="center">
                                    <v-col>
                                        <div class="txt txt--xs pa-12px">
                                            <span>색상 : 블랙</span>
                                            <span class="px-4px">/</span>
                                            <span>수량 : 1</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto" align-self="stretch" class="ml-n1px"><v-divider vertical /></v-col>
                                    <v-col>
                                        <div class="txt txt--xs pa-12px">
                                            <span>취소수량 : </span>
                                            <span>1</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto" align-self="stretch" class="ml-n1px"><v-divider vertical /></v-col>
                                    <v-col>
                                        <div class="txt txt--xs pa-12px">
                                            <span>주문상태 : </span>
                                            <span>상품준비중</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <!-- E: 수량, 취소수량, 주문상태(모바일) -->
                    </v-row>
                </div>
                <v-divider />
            </v-card>

            <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit tit--xxs">환불방법</div>
                    </div>

                    <u-vertical-items>
                        <u-vertical-item :rowProps="{ align: 'center' }" txtContentClass="px-12px py-6px py-md-8px" borderNone>
                            <template #tit> 은행 </template>
                            <template #txt>
                                <v-select v-bind="{ ...attrs_input }" dense label="선택" class="w-100 w-md-300px" />
                            </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" txtContentClass="px-12px py-6px py-md-8px" borderNone>
                            <template #tit> 예금주 </template>
                            <template #txt>
                                <v-text-field v-bind="{ ...attrs_input }" dense class="w-100 w-md-300px" />
                            </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" txtContentClass="px-12px py-6px py-md-8px" borderNone>
                            <template #tit> 계좌번호 </template>
                            <template #txt>
                                <v-text-field v-bind="{ ...attrs_input }" dense class="w-100 w-md-358px" />
                            </template>
                        </u-vertical-item>
                    </u-vertical-items>
                    <div class="pt-12px">
                        <v-row class="ma-n2px">
                            <v-col cols="12" class="pa-2px">
                                <v-row class="ma-n4px txt font-size-12 font-size-md-14">
                                    <v-col cols="auto" class="pa-4px">•</v-col>
                                    <v-col class="pa-4px">환불방법은 복합결제(마일리지, 쿠폰 사용 등) 및 최초 배송비 계산 등의 이유로 쇼핑몰 관리자와 협의 후 결정됩니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="pa-2px">
                                <v-row class="ma-n4px txt font-size-12 font-size-md-14">
                                    <v-col cols="auto" class="pa-4px">•</v-col>
                                    <v-col class="pa-4px">결제 수단이 무통장 또는 가상계좌인 경우 환불 계좌 정보는 필수 입력 사항입니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="pa-2px">
                                <v-row class="ma-n4px txt font-size-12 font-size-md-14">
                                    <v-col cols="auto" class="pa-4px">•</v-col>
                                    <v-col class="pa-4px">관리자로부터 환불 금액을 입금 받을 계좌 정보를 입력해주세요.</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </template>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit tit--xxs">사유선택</div>
                    </div>

                    <u-vertical-items>
                        <u-vertical-item :rowProps="{ align: 'center' }" txtContentClass="px-12px py-6px py-md-8px" borderNone>
                            <template #tit> 사유선택 </template>
                            <template #txt>
                                <v-select v-bind="{ ...attrs_input }" dense label="선택" class="w-100 w-md-300px" />
                            </template>
                        </u-vertical-item>
                        <u-vertical-item txtContentClass="px-12px py-6px py-md-8px" borderNone>
                            <template #tit> 상세사유 </template>
                            <template #txt>
                                <v-textarea v-bind="{ ...attrs_input }" dense no-resize />
                            </template>
                        </u-vertical-item>
                    </u-vertical-items>
                </template>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit tit--xxs">최초 배송비</div>
                    </div>

                    <v-divider class="border-dark" />
                    <div class="py-12px" style="min-height: 150px">
                        <v-row class="ma-n2px">
                            <v-col cols="12" class="pa-2px">
                                <v-row class="ma-n4px txt font-size-12 font-size-md-14">
                                    <v-col cols="auto" class="pa-4px">•</v-col>
                                    <v-col class="pa-4px">부분 결제 취소 시 추가 배송비가 발생할 수 있으며, 이 때 추가 배송비를 결제해주셔야만 결제취소 처리완료가 가능합니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="pa-2px">
                                <v-row class="ma-n4px txt font-size-12 font-size-md-14">
                                    <v-col cols="auto" class="pa-4px">•</v-col>
                                    <v-col class="pa-4px">추가 배송비가 발생하는 경우,</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="pa-2px">
                                <div class="txt font-size-12 font-size-md-14">① ‘묶음 배송비’ 상품의 배송비 무료(금액별 차등) 조건을 충족하여 배송비 무료</div>
                            </v-col>
                            <v-col cols="12" class="pa-2px">
                                <div class="txt font-size-12 font-size-md-14">② 부분 결제취소로 배송비 무료 조건을 불충족하는 경우 추가 배송비 부과</div>
                            </v-col>
                            <v-col cols="12" class="pa-2px">
                                <div class="txt font-size-12 font-size-md-14">③ 추가 배송비는 카드 또는 마일리지로 결제 가능</div>
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider style="border-width: 2px 0 0" class="border-dark d-none d-md-block" />

                    <div class="btn-wrap btn-wrap--md mx-n12px mx-md-0px">
                        <v-row justify="center" class="ma-0px ma-md-n4px">
                            <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                                <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large w-100 min-w-md-140px">신청하기</v-btn>
                            </v-col>
                            <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                                <v-btn v-bind="{ ...btn_secondary }" class="v-size--xx-large min-w-md-140px d-none d-md-block">취소</v-btn>
                                <v-btn v-bind="{ ...btn_primary3 }" class="v-size--xx-large w-100 d-md-none">취소</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </template>
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_primary, btn_primary3, btn_secondary, btn_secondary2, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import UTxtItems from "@/components/client/dumb/u-txt-items.vue";
import UTxtItem from "@/components/client/dumb/u-txt-item.vue";
import UVerticalItems from "@/components/client/dumb/u-vertical-items.vue";
import UVerticalItem from "@/components/client/dumb/u-vertical-item.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        PageSection,
        TitWrapLine,
        UTxtItems,
        UTxtItem,
        UVerticalItems,
        UVerticalItem,
        UDialog,
        VerticalFormTable,
    },
    props: {},
    data: function () {
        return {
            btn_primary,
            btn_primary3,
            btn_secondary,
            btn_secondary2,
            attrs_input,

            loading: false,

            dialog: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading) return;
            this.loading = true;
        },
    },
    computed: {
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                // { text: "결제취소/환불신청", to: { path: "/mypage/orders/cancel-application" } },
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "결제취소/환불신청" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
};
</script>
