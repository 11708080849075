<template>
    <div class="pt-14px pt-md-26px">
        <v-row align="center" class="row--xs text-center">
            <v-col cols="12" lg="auto" class="d-none d-lg-block">
                <v-row align="center" class="row--xxs">
                    <v-col cols="auto">
                        <div class="txt txt--sm txt--dark">기간</div>
                    </v-col>
                    <v-col cols="auto">
                        <v-dates-toggle v-model="query['createdAt']" tile @input="emit" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="">
                <v-row align="center" class="ma-n4px">
                    <v-col lg="" class="pa-4px">
                        <v-date-field v-model="query['createdAt'][0]" v-bind="{ ...attrs_input, loading }" dense />
                    </v-col>
                    <v-col cols="auto" class="pa-4px">
                        <div class="txt txt--sm txt--dark">~</div>
                    </v-col>
                    <v-col lg="" class="pa-4px">
                        <v-date-field v-model="query['createdAt'][1]" v-bind="{ ...attrs_input, loading }" dense />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="auto" class="d-none d-lg-block">
                <v-row align="center" class="ma-n4px">
                    <v-col class="pa-4px">
                        <v-text-field v-model="query['product.name']" v-bind="{ ...attrs_input }" dense class="w-100 w-lg-300px" placeholder="상품명을 입력해 주세요." />
                    </v-col>
                    <v-col cols="auto" class="pa-4px">
                        <v-btn v-bind="{ ...btn_primary, loading }" small class="w-70px" @click="emit">조회</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { attrs_input, btn_primary } from "@/assets/variables";

import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import VDatesToggle from "@/components/plugins/vuetify/v-dates-toggle.vue";

const initQuery = (query = {}) => ({
    ["product.name"]: query["product.name"] || null,
    ["createdAt"]: JSON.parse(JSON.stringify(query["createdAt"] || [null, null])),
});

export default {
    components: {
        VDateField,
        VDatesToggle,
    },
    props: {
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        attrs_input,
        btn_primary,
    }),
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            let { ...query } = this.query;

            if (!query["product.name"]) delete query["product.name"];
            if (!query["createdAt"].some((item) => !!item)) delete query["createdAt"];
            delete query.page;

            this.$router.push({ query });
        },
    },
    watch: {
        $route() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style lang="scss" scoped></style>
