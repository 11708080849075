var render = function render(){
  var _vm$order, _vm$order$createdAt, _vm$order$createdAt$t, _vm$order2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tit-wrap-line', {
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "baseline"
    }
  }, [_c('v-col', [_c('v-row', {
    staticClass: "ma-n2px ma-sm-n6px",
    attrs: {
      "align": "baseline"
    }
  }, [_c('v-col', {
    staticClass: "pa-2px pa-sm-6px",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('span', {
    staticClass: "tit tit--xxs"
  }, [_vm._v(_vm._s((_vm$order = _vm.order) === null || _vm$order === void 0 ? void 0 : (_vm$order$createdAt = _vm$order.createdAt) === null || _vm$order$createdAt === void 0 ? void 0 : (_vm$order$createdAt$t = _vm$order$createdAt.toDate) === null || _vm$order$createdAt$t === void 0 ? void 0 : _vm$order$createdAt$t.call(_vm$order$createdAt)))])]), _c('v-col', {
    staticClass: "pa-2px pa-sm-6px",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 주문번호 : " + _vm._s((_vm$order2 = _vm.order) === null || _vm$order2 === void 0 ? void 0 : _vm$order2.orderNo) + " ")])]), _c('v-col', {
    staticClass: "pa-2px pa-sm-6px",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('order-view', {
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-small"
        }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("상세보기")])];
      }
    }]),
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }), _vm.isVbank ? _c('order-vbank-view', {
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-small"
        }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("가상계좌")])];
      }
    }], null, false, 1750347763),
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }) : _vm._e(), _vm.claimable ? _c('purchases-dialog-claim', _vm._b({
    on: {
      "input": function ($event) {
        return _vm.$emit('init');
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var attrs = _ref3.attrs,
          on = _ref3.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-small"
        }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("전체취소")])];
      }
    }], null, false, 1578197646),
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }, 'purchases-dialog-claim', {
    type: _vm.type
  }, false)) : _vm._e()], 1), _c('v-col', {
    staticClass: "pa-2px pa-sm-6px",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('order-item-complete', _vm._g(_vm._b({
    staticClass: "v-size--xx-small"
  }, 'order-item-complete', Object.assign({}, _vm.btn_secondary2, {
    order: _vm.order
  }), false), {
    init: _vm.init
  }))], 1)], 1)], 1), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("판매사")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("구매금액")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("주문상태")])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("주문처리")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }