var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('mypage-layout', {
    attrs: {
      "title": "쿠폰 내역"
    }
  }, [_c('mypage-coupons-nav', _vm._b({}, 'mypage-coupons-nav', {
    summary: _vm.summary
  }, false)), _c('page-section', {
    staticClass: "page-section--sm py-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('v-tabs-items', {
          attrs: {
            "value": _vm.tab
          }
        }, [_c('v-tab-item', {
          attrs: {
            "value": null
          }
        }, [_c('div', {
          staticClass: "mx-auto",
          staticStyle: {
            "max-width": "940px"
          }
        }, [_c('mypage-coupons-list', _vm._g({}, {
          setSummary: _vm.setSummary
        }))], 1)]), _c('v-tab-item', {
          attrs: {
            "value": "download"
          }
        }, [_c('div', {
          staticClass: "mx-auto",
          staticStyle: {
            "max-width": "940px"
          }
        }, [_c('mypage-coupons-down')], 1)])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }