var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "contents-bottom-md-none",
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_vm.form.product ? _c('mypage-layout', {
    attrs: {
      "title": "나의 상품후기"
    }
  }, [_c('div', [_c('vertical-form-table', _vm._b({
    staticClass: "vertical-form-table--border",
    attrs: {
      "topBorderMdNone": ""
    },
    scopedSlots: _vm._u([{
      key: "작성자",
      fn: function () {
        return [_c('v-text-field', _vm._b({
          staticClass: "w-md-358px",
          attrs: {
            "disabled": "",
            "dense": ""
          },
          model: {
            value: _vm.username,
            callback: function ($$v) {
              _vm.username = $$v;
            },
            expression: "username"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
          loading: _vm.loading
        }), false))];
      },
      proxy: true
    }, {
      key: "상품",
      fn: function () {
        var _vm$form$product, _vm$form$product$thum;
        return [_c('v-row', {
          staticClass: "ma-n6px",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-6px",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-card', {
          staticClass: "overflow-hidden",
          attrs: {
            "tile": "",
            "outlined": ""
          }
        }, [_c('router-link', {
          attrs: {
            "to": `/shop/products/${_vm.form.product._id}`
          }
        }, [_c('v-img', {
          attrs: {
            "src": (_vm$form$product = _vm.form.product) === null || _vm$form$product === void 0 ? void 0 : (_vm$form$product$thum = _vm$form$product.thumb) === null || _vm$form$product$thum === void 0 ? void 0 : _vm$form$product$thum.url,
            "aspect-ratio": 70 / 54,
            "width": "70"
          },
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function () {
              return [_c('v-overlay', {
                attrs: {
                  "absolute": "",
                  "opacity": "0.1"
                }
              }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
            },
            proxy: true
          }], null, false, 2495918059)
        })], 1)], 1)], 1), _c('v-col', {
          staticClass: "pa-6px"
        }, [_c('div', {
          staticClass: "txt txt--sm txt--dark pb-2px"
        }, [_vm._v(_vm._s(_vm.form.product.name))]), _c('div', {
          staticClass: "txt txt--sm txt--dark"
        }, [_vm._v(_vm._s(_vm.form.product.salePrice) + "원")])])], 1)];
      },
      proxy: true
    }, {
      key: "평점",
      fn: function () {
        return [_c('v-row', {
          staticClass: "ma-n4px",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-4px",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-rating', _vm._b({
          attrs: {
            "half-increments": "",
            "size": "22",
            "empty-icon": "mdi-star",
            "background-color": "grey lighten-3"
          },
          model: {
            value: _vm.form.star,
            callback: function ($$v) {
              _vm.$set(_vm.form, "star", $$v);
            },
            expression: "form.star"
          }
        }, 'v-rating', Object.assign({}, _vm.attrs_rating), false))], 1)], 1)];
      },
      proxy: true
    }], null, false, 453851865)
  }, 'vertical-form-table', {
    items: _vm.items
  }, false)), _c('div', {
    staticClass: "py-12px py-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--dark font-weight-medium pb-8px d-md-none"
  }, [_vm._v("내용")]), _c('v-textarea', _vm._b({
    attrs: {
      "rows": "14",
      "placeholder": "내용을 입력해주세요."
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-divider', {
    staticClass: "border-dark d-none d-md-block",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "py-12px d-md-none"
  }, [_c('div', {
    staticClass: "txt txt--dark font-weight-medium pb-4px"
  }, [_vm._v("첨부파일 추가")]), _c('div', {
    staticClass: "overflow-x-auto overflow-y-hidden mr-n12px pt-4px"
  }, [_c('div', {
    staticClass: "d-flex flex-nowrap pr-8px"
  }, [_c('div', {
    staticClass: "pr-4px"
  }, [_c('v-btn', {
    attrs: {
      "tile": "",
      "width": "72",
      "height": "72px !important",
      "color": "grey lighten-5"
    },
    on: {
      "click": _vm.upload
    }
  }, [_c('i', {
    staticClass: "icon icon-image"
  })]), _c('input', {
    attrs: {
      "type": "file",
      "id": "fileUpload",
      "hidden": "",
      "multiple": "",
      "accept": "image/*"
    },
    on: {
      "change": _vm.handleFiles
    }
  })], 1), _vm._l(_vm.previews, function (preivew, index) {
    return _c('div', {
      key: index,
      staticClass: "pr-4px"
    }, [_c('v-card', {
      staticClass: "attachment-img",
      attrs: {
        "tile": ""
      }
    }, [_c('v-btn', {
      staticClass: "attachment-img__close",
      attrs: {
        "fab": "",
        "color": "primary"
      }
    }, [_c('v-icon', {
      attrs: {
        "x-small": "",
        "color": "white"
      },
      on: {
        "click": function ($event) {
          return _vm.close(index);
        }
      }
    }, [_vm._v("mdi-close")])], 1), _c('v-img', {
      attrs: {
        "src": preivew,
        "aspect-ratio": 1 / 1,
        "width": "72"
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-overlay', {
            attrs: {
              "absolute": "",
              "opacity": "0.1"
            }
          }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
        },
        proxy: true
      }], null, true)
    })], 1)], 1);
  })], 2)])]), _c('div', {
    staticClass: "btn-wrap mx-n12px mx-md-0px"
  }, [_c('v-row', {
    staticClass: "ma-0px ma-md-n4px",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 w-md-140px",
    on: {
      "click": _vm.upload
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v("이미지 추가")]), _c('input', {
    attrs: {
      "type": "file",
      "id": "fileUpload",
      "hidden": "",
      "multiple": "",
      "accept": "image/*"
    },
    on: {
      "change": _vm.handleFiles
    }
  }), _c('div', {
    staticClass: "pt-16px"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, _vm._l(_vm.previews, function (preivew, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', {
      staticClass: "attachment-img",
      attrs: {
        "tile": ""
      }
    }, [_c('v-btn', {
      staticClass: "attachment-img__close",
      attrs: {
        "fab": "",
        "color": "primary"
      }
    }, [_c('v-icon', {
      attrs: {
        "x-small": "",
        "color": "white"
      },
      on: {
        "click": function ($event) {
          return _vm.close(index);
        }
      }
    }, [_vm._v("mdi-close")])], 1), _c('v-img', {
      attrs: {
        "src": preivew,
        "aspect-ratio": 1 / 1,
        "width": "72"
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-overlay', {
            attrs: {
              "absolute": "",
              "opacity": "0.1"
            }
          }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
        },
        proxy: true
      }], null, true)
    })], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-0px pa-md-4px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "ma-0px ma-md-n4px"
  }, [_c('v-col', {
    staticClass: "pa-0px pa-md-4px",
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-md-140px",
    on: {
      "click": _vm.save
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("저장")])], 1), _c('v-col', {
    staticClass: "pa-0px pa-md-4px",
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large min-w-md-140px d-none d-md-block",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v("취소")]), _c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 d-md-none",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary3, {
    loading: _vm.loading
  }), false), [_vm._v("취소")])], 1)], 1)], 1)], 1)], 1)], 1)]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }