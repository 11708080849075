<template>
    <div class="txt pb-20px pb-md-30px">
        {{ user?.name }}({{ user?.username }})님이 보유한 마일리지는 <b class="txt--dark">{{ user?.point?.format?.() || 0 }}M</b> 입니다.
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState(["user"]),
    },
};
</script>

<style></style>
