<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="마일리지">
            <point-head />

            <page-section class="page-section--sm py-0">
                <template #containerImmersive>
                    <point-body v-bind="{ items, loading }" />

                    <div class="py-6">
                        <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                    </div>
                </template>
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapActions, mapState } from "vuex";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";

import PointBody from "@/components/client/mypage/points/point-body.vue";
import PointHead from "@/components/client/mypage/points/point-head.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        SubVisualPrimary,

        PointBody,
        PointHead,
    },

    data: () => ({
        points: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        ...mapState(["user"]),
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                // { text: "마일리지", to: { path: "/mypage/mileage" } },
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "마일리지" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
        items() {
            return [...this.points].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            this.getUser();
            this.search();
        },

        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, points } = await api.v1.me.points.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.points = points;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
