var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('body-mb', _vm._b({}, 'body-mb', {
    items: _vm.items
  }, false)), _c('body-pc', _vm._b({}, 'body-pc', {
    items: _vm.items,
    loading: _vm.loading
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }