<template>
    <v-card tile>
        <div :class="mode == 'list' ? 'py-20px py-md-22px' : ''">
            <v-row align-xl="center" class="ma-n6px ma-lg-n12px">
                <v-col cols="12" class="pa-6px pa-lg-12px d-xl-none">
                    <div class="txt txt--sm">
                        <!-- <span :class="statusText?.includes?.('배송완료') ? 'primary--text' : 'txt--dark'" class="pr-6px">{{ statusText }}</span> -->
                        <span class="pr-6px">{{ statusText }}</span>
                        <span>상품주문번호 : {{ value?.purchaseNo }}</span>
                    </div>
                </v-col>

                <template v-if="mode == 'list'">
                    <v-col cols="12" xl="" class="pa-6px pa-lg-12px">
                        <!-- S: 이미지, 정보(모바일) -->
                        <div class="pb-16px pb-md-8px d-xl-none">
                            <v-row align="center" class="ma-n6px ma-xl-n12px">
                                <v-col cols="auto" class="pa-6px pa-xl-12px">
                                    <v-card tile outlined class="overflow-hidden">
                                        <router-link v-bind="{ to }">
                                            <v-img v-bind="{ src }" :aspect-ratio="130 / 100" width="104">
                                                <template #placeholder>
                                                    <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                </template>
                                            </v-img>
                                        </router-link>
                                    </v-card>
                                </v-col>
                                <v-col class="pa-6px pa-xl-12px">
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="value?.product?.flags?.length">{{ value?.product.flags.map((text) => `[${text}]`).join(" ") }}</div>
                                    <router-link v-bind="{ to }">
                                        <div class="txt txt--dark">{{ value?.product?.name }}</div>
                                    </router-link>
                                    <div class="txt txt--sm txt--dark mt-4px">{{ (value?.salePrice * value?.amount).format() }}원</div>
                                </v-col>
                            </v-row>
                        </div>
                        <!-- E: 이미지, 정보(모바일) -->

                        <!-- S: 이미지, 정보(PC) -->
                        <div class="pb-16px pb-md-8px pb-xl-0 d-none d-xl-block">
                            <v-row align="center" class="ma-n6px ma-lg-n12px">
                                <v-col cols="auto" class="pa-6px pa-lg-12px">
                                    <v-card tile outlined class="overflow-hidden">
                                        <router-link v-bind="{ to }">
                                            <v-img v-bind="{ src }" :aspect-ratio="130 / 100" width="130">
                                                <template #placeholder>
                                                    <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                </template>
                                            </v-img>
                                        </router-link>
                                    </v-card>
                                </v-col>
                                <v-col class="pa-6px pa-lg-12px">
                                    <div class="txt txt--sm mb-4px mb-md-8px mb-lg-14px">
                                        <!-- <span :class="statusText?.includes?.('배송완료') ? 'primary--text' : 'txt--dark'" class="pr-6px">{{ statusText }}</span> -->
                                        <span class="pr-6px">{{ statusText }}</span>
                                        <span>상품주문번호 : {{ value?.purchaseNo }}</span>
                                    </div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="value?.product?.flags?.length">{{ value?.product.flags.map((text) => `[${text}]`).join(" ") }}</div>
                                    <router-link v-bind="{ to }">
                                        <div class="txt txt--dark">{{ value?.product?.name }}</div>
                                    </router-link>
                                    <!-- <div class="txt txt--sm mt-4px mt-md-8px">색상 : 블랙 / 수량 : {{ value?.amount }}개</div> -->
                                    <div class="txt txt--sm mt-4px mt-md-8px">
                                        {{ $decode__productOptionName(option) }}
                                        <template v-if="!withQuantity"> / 수량 : {{ value?.amount }}개 </template>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <!-- E: 이미지, 정보(PC) -->
                    </v-col>
                    <v-col cols="6" md="4" xl="auto" class="pa-6px pa-lg-12px d-xl-none">
                        <div class="w-xl-120px text-xl-center">
                            <!-- <div class="txt txt--sm">색상 : 블랙 / 수량 : 1개</div> -->
                            <div class="txt txt--sm">수량 : {{ value?.amount }}개</div>
                        </div>
                    </v-col>
                    <v-col cols="6" md="4" xl="auto" class="pa-6px pa-lg-12px">
                        <div class="w-xl-120px text-xl-center">
                            <div class="txt txt--sm">
                                <span class="d-xl-none">판매사 : </span>
                                <span :class="$vuetify.breakpoint.xl ? 'txt--dark' : ''">{{ value?.seller?.nickname }}</span>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="6" md="4" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                        <div class="w-xl-120px text-xl-center">
                            <div class="txt txt--sm">
                                <span class="d-xl-none">구매금액 : </span>
                                <span :class="$vuetify.breakpoint.xl ? 'txt--dark' : ''">{{ (value?.salePrice * value?.amount).format() }}원</span>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                        <div class="w-xl-120px text-xl-center">
                            <div class="txt txt--sm">
                                <span class="d-xl-none">주문상태 : </span>
                                <span class="accent2--text">{{ status }}</span>
                            </div>
                        </div>
                    </v-col>
                    <!-- v-if -->
                    <!-- <v-col cols="6" md="4" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                        <div class="w-xl-120px text-xl-center">
                            <div class="txt txt--sm">
                                <span class="d-xl-none">반품/교환/취소/환불 : </span>
                                <span>반품완료</span>
                            </div>
                        </div>
                    </v-col> -->
                    <!-- S: 주문상태, 반품/교환/취소/환불(모바일) -->
                    <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-xl-none">
                        <div class="text-center grey lighten-5">
                            <v-row no-gutters align="center">
                                <v-col>
                                    <div class="txt txt--xs pa-12px">
                                        <span class="d-xl-none">주문상태 : </span>
                                        <span class="accent2--text">{{ status }}</span>
                                    </div>
                                </v-col>
                                <!-- v-if -->
                                <!-- <v-col cols="auto" align-self="stretch" class="ml-n1px"><v-divider vertical /></v-col>
                                <v-col>
                                    <div class="txt txt--xs pa-12px">
                                        <span class="d-xl-none">반품/교환/취소/환불 : </span>
                                        <span>반품완료</span>
                                    </div>
                                </v-col> -->
                            </v-row>
                        </div>
                    </v-col>
                    <!-- E: 주문상태, 반품/교환/취소/환불(모바일) -->
                </template>

                <v-col v-if="mode == 'view'" class="pa-6px pa-lg-12px">
                    <v-row align="center" class="ma-n6px ma-lg-n12px">
                        <v-col cols="auto" class="pa-6px pa-lg-12px">
                            <v-card tile outlined class="overflow-hidden">
                                <router-link v-bind="{ to }">
                                    <v-img v-bind="{ src }" :aspect-ratio="130 / 100" :width="$vuetify.breakpoint.mdAndUp ? 130 : 104">
                                        <template #placeholder>
                                            <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                        </template>
                                    </v-img>
                                </router-link>
                            </v-card>
                        </v-col>
                        <v-col class="pa-6px pa-lg-12px">
                            <div class="txt txt--sm mb-4px mb-md-8px">상품주문번호 : {{ value?.purchaseNo }}</div>
                            <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="value?.product?.flags?.length">{{ value?.product.flags.map((text) => `[${text}]`).join(" ") }}</div>
                            <router-link v-bind="{ to }">
                                <div class="txt txt--sm txt--dark mb-12px">{{ value?.product?.name }}</div>
                            </router-link>
                            <v-row class="row--xxs">
                                <v-col v-if="value._option || value._supply || value._related" cols="12" sm="auto">
                                    <v-chip v-if="value._option" x-small outlined color="grey">옵션</v-chip>
                                    <v-chip v-if="value._supply" x-small outlined color="grey">추가</v-chip>
                                    <v-chip v-if="value._related" x-small outlined color="grey">추가</v-chip>
                                </v-col>
                                <v-col cols="12" sm="">
                                    <span class="txt txt--sm d-sm-inline-block pt-sm-4px">{{ $decode__productOptionName(option) }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col v-if="$slots['actions']" cols="12" xl="auto" class="pa-6px pa-lg-12px">
                    <div class="w-xl-120px">
                        <slot name="actions" />
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-divider v-if="mode == 'list'" />
    </v-card>
</template>

<script>
import { PURCHASE_ORDER_STATUSES } from "@/assets/variables";
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // purchase
        mode: { type: String, default: "list" },
        withQuantity: { type: Boolean, default: false },
    },
    computed: {
        to() {
            let _id = this.value?.product?._id;
            if (this.value?.product?.variation?.enabled) {
                _id = this.value?.product?.variation?.head?._id || _id;
            }

            return `/shop/products/${_id}`;
        },
        src() {
            let { thumb } = this.value?.product || {};
            if (thumb) return thumb?.url;
        },
        statusText() {
            return this.$getStatusText(this.value);
        },
        option() {
            let option = this.value?.name || "";
            if (this.withQuantity) option += ` / ${this.value?.amount}개`;
            return option;
        },
        status() {
            return PURCHASE_ORDER_STATUSES[this?.value?.orderStatus].text || "-";
        },
    },
};
</script>

<style></style>
