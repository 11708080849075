<template>
    <spec-row v-bind="{ term }">
        <template #desc>
            <span :class="className"> {{ coupon?.stateText }} </span>
        </template>
    </spec-row>
</template>

<script>
import { initShopCoupon } from "@/assets/variables";

import SpecRow from "./spec-row.vue";

export default {
    components: {
        SpecRow,
    },
    props: {
        term: { type: String, default: "상태" },
        coupon: { type: Object, default: initShopCoupon },
    },
    computed: {
        className() {
            let color;
            if (this.coupon.isUsed) color = "grey";
            else if (this.coupon.isExpired) color = "red";
            else if (this.coupon.isDisabled) color = "red";
            else color = "secondary";
            return `${color}--text`;
        },
    },
};
</script>

<style></style>
