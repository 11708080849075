var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "contents-bottom-md-none",
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('mypage-layout', {
    attrs: {
      "title": "반품 신청"
    }
  }, [_c('tit-wrap-line', {
    staticClass: "d-xl-none",
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "ma-n2px ma-sm-n6px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2px pa-sm-6px",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 상품정보 ")])])], 1)], 1), _c('v-divider', {
    staticClass: "border-dark d-none d-xl-block",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "pt-xl-16px mb-xl-n4px"
  }, [_c('tit-wrap-line', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col'), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-60px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("수량")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("상품금액")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-60px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("신청수량")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("주문상태")])])])], 1)], 1)], 1), _c('v-divider', {
    class: _vm.$vuetify.breakpoint.xl ? '' : 'border-dark'
  }), _c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "py-20px py-xl-10px"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align-xl": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', [_vm._v("상품주문번호 : 202401020001_001")])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "12",
      "xl": ""
    }
  }, [_c('div', {
    staticClass: "d-xl-none"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-xl-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-xl-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": "104"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-xl-12px"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v("볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)")])]), _c('div', {
    staticClass: "txt txt--sm txt--dark mt-4px"
  }, [_vm._v("1,650,000원")])], 1)], 1)], 1), _c('div', {
    staticClass: "d-none d-xl-block"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": "130"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px"
  }, [_c('div', {
    staticClass: "txt txt--sm mb-4px mb-md-8px mb-lg-14px"
  }, [_c('span', {
    staticClass: "pr-6px"
  }, [_vm._v("결제완료")]), _c('span', [_vm._v("상품주문번호 : 202401020001_001")])]), _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v("볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)")])]), _c('div', {
    staticClass: "txt txt--sm mt-4px mt-md-8px"
  }, [_vm._v("색상 : 블랙 / 수량 : 1개")])], 1)], 1)], 1)]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-60px text-xl-center d-none d-xl-block"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("수량 : ")]), _c('span', [_vm._v("1")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "6",
      "md": "4",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("상품금액 : ")]), _c('span', [_vm._v("1,650,000원")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-60px text-xl-center d-none d-xl-block"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("신청수량 : ")]), _c('span', [_vm._v("1")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center d-none d-xl-block"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("주문상태 : ")]), _c('span', [_vm._v("상품준비중")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "text-center grey lighten-5"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', [_vm._v("색상 : 블랙")]), _c('span', {
    staticClass: "px-4px"
  }, [_vm._v("/")]), _c('span', [_vm._v("수량 : 1")])])]), _c('v-col', {
    staticClass: "ml-n1px",
    attrs: {
      "cols": "auto",
      "align-self": "stretch"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', [_vm._v("신청수량 : ")]), _c('span', [_vm._v("1")])])]), _c('v-col', {
    staticClass: "ml-n1px",
    attrs: {
      "cols": "auto",
      "align-self": "stretch"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', [_vm._v("주문상태 : ")]), _c('span', [_vm._v("상품준비중")])])])], 1)], 1)])], 1)], 1), _c('v-divider')], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("사유선택")])]), _c('u-vertical-items', [_c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 사유선택 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-select', _vm._b({
                staticClass: "w-100 w-md-300px",
                attrs: {
                  "dense": "",
                  "label": "선택"
                }
              }, 'v-select', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 상세사유 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-textarea', _vm._b({
                attrs: {
                  "dense": "",
                  "no-resize": ""
                }
              }, 'v-textarea', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("수거신청")])]), _c('v-divider', {
          staticClass: "border-dark"
        }), _c('u-txt-items', [_c('u-txt-item', {
          attrs: {
            "innerClass": "py-20px border-lg-b",
            "titContentClass": "w-100 w-md-110px pl-xl-12px"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_c('span', {
                staticClass: "font-weight-medium"
              }, [_vm._v("수거 신청 여부")])];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-radio-group', {
                attrs: {
                  "row": "",
                  "hide-details": "auto"
                }
              }, [_c('v-radio', _vm._b({
                attrs: {
                  "label": "직접 판매자에게 발송",
                  "value": "직접 판매자에게 발송"
                }
              }, 'v-radio', Object.assign({}, _vm.radio_primary), false)), _c('v-radio', _vm._b({
                attrs: {
                  "label": "지정 택배사에서 가져가주세요",
                  "value": "지정 택배사에서 가져가주세요"
                }
              }, 'v-radio', Object.assign({}, _vm.radio_primary), false))], 1), _c('div', {
                staticClass: "txt txt--sm secondary--text mt-10px mt-md-14px"
              }, [_vm._v("[14558] 경기도 부천시 조마루로 385번길 92(부천 테크노밸리 U1 center)1712호")])];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("수거지 정보")])]), _c('v-divider', {
          staticClass: "border-dark"
        }), _c('u-txt-items', {
          attrs: {
            "rowSize": "no-gutters"
          }
        }, [_c('u-txt-item', {
          attrs: {
            "innerClass": "py-20px border-b",
            "titContentClass": "w-100 w-md-110px pl-xl-12px"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_c('span', {
                staticClass: "font-weight-medium"
              }, [_vm._v("수거지 선택")])];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-radio-group', {
                attrs: {
                  "row": "",
                  "hide-details": "auto"
                }
              }, [_c('v-radio', _vm._b({
                attrs: {
                  "label": "배송지 정보와 동일",
                  "value": "배송지 정보와 동일"
                }
              }, 'v-radio', Object.assign({}, _vm.radio_primary), false)), _c('v-radio', _vm._b({
                attrs: {
                  "label": "직접입력",
                  "value": "직접입력"
                }
              }, 'v-radio', Object.assign({}, _vm.radio_primary), false))], 1)];
            },
            proxy: true
          }])
        }), _c('u-txt-item', {
          attrs: {
            "innerClass": "py-20px border-lg-b",
            "titContentClass": "w-100 w-md-110px pl-xl-12px"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_c('span', {
                staticClass: "font-weight-medium"
              }, [_vm._v("수거지 주소")])];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('div', {
                staticClass: "txt txt--sm txt--dark"
              }, [_vm._v("홍길동")]), _c('div', {
                staticClass: "txt txt--sm txt--dark mt-4px"
              }, [_vm._v("[ 14547 ] 경기도 부천시 길주로 202 (부천시청역) 12")]), _c('div', {
                staticClass: "txt txt--sm mt-4px"
              }, [_vm._v("( 경기도 부천시 중동 1243 부천시청역 12 )")])];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("환불정보")])]), _c('u-vertical-items', [_c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 은행 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-select', _vm._b({
                staticClass: "w-100 w-md-300px",
                attrs: {
                  "dense": "",
                  "label": "선택"
                }
              }, 'v-select', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 예금주 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-text-field', _vm._b({
                staticClass: "w-100 w-md-300px",
                attrs: {
                  "dense": ""
                }
              }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 계좌번호 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-text-field', _vm._b({
                staticClass: "w-100 w-md-358px",
                attrs: {
                  "dense": ""
                }
              }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        })], 1), _c('div', {
          staticClass: "py-12px",
          staticStyle: {
            "min-height": "120px"
          }
        }, [_c('v-row', {
          staticClass: "ma-n2px"
        }, [_c('v-col', {
          staticClass: "pa-2px",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-row', {
          staticClass: "ma-n4px txt font-size-12 font-size-md-14"
        }, [_c('v-col', {
          staticClass: "pa-4px",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("•")]), _c('v-col', {
          staticClass: "pa-4px"
        }, [_vm._v("환불방법은 복합결제(마일리지, 쿠폰 사용 등) 및 최초 배송비 계산 등의 이유로 쇼핑몰 관리자와 협의 후 결정됩니다.")])], 1)], 1), _c('v-col', {
          staticClass: "pa-2px",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-row', {
          staticClass: "ma-n4px txt font-size-12 font-size-md-14"
        }, [_c('v-col', {
          staticClass: "pa-4px",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("•")]), _c('v-col', {
          staticClass: "pa-4px"
        }, [_vm._v("결제 수단이 무통장 또는 가상계좌인 경우 환불 계좌 정보는 필수 입력 사항입니다.")])], 1)], 1), _c('v-col', {
          staticClass: "pa-2px",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-row', {
          staticClass: "ma-n4px txt font-size-12 font-size-md-14"
        }, [_c('v-col', {
          staticClass: "pa-4px",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("•")]), _c('v-col', {
          staticClass: "pa-4px"
        }, [_vm._v("관리자로부터 환불 금액을 입금 받을 계좌 정보를 입력해주세요.")])], 1)], 1)], 1)], 1), _c('v-divider', {
          staticClass: "border-dark d-none d-md-block",
          staticStyle: {
            "border-width": "2px 0 0"
          }
        }), _c('div', {
          staticClass: "btn-wrap btn-wrap--md mx-n12px mx-md-0px d-none d-md-block"
        }, [_c('v-row', {
          staticClass: "ma-0px ma-md-n4px",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0px pa-md-4px",
          attrs: {
            "cols": "6",
            "md": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 min-w-md-140px"
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("신청하기")])], 1), _c('v-col', {
          staticClass: "pa-0px pa-md-4px",
          attrs: {
            "cols": "6",
            "md": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large min-w-md-140px d-none d-md-block"
        }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("취소")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('v-row', {
          staticClass: "ma-n14px my-lg-n24px"
        }, [_c('v-col', {
          staticClass: "pa-14px py-lg-24px",
          attrs: {
            "cols": "12",
            "lg": "6"
          }
        }, [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("교환/반품으로 인한 배송비 발생 기준안내")])]), _c('div', [_c('div', {
          staticClass: "txt txt--sm pb-16px"
        }, [_c('b', {
          staticClass: "red--text"
        }, [_vm._v("구매자 부담")]), _c('span', {
          staticClass: "pl-4px pl-md-8px"
        }, [_vm._v("(반품 배송비 + 최초 배송비)")])]), _c('v-divider', {
          staticClass: "border-dark"
        }), _c('div', {
          staticClass: "py-12px"
        }, [_c('v-row', {
          staticClass: "row--xxs",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "txt txt--sm txt--dark font-weight-bold"
        }, [_vm._v("반품 → 환불")])]), _c('v-col', [_c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v("반품 시 배송비는 반품의 원인을 제공한 자가 부담합니다."), _c('br'), _vm._v("구매자의 변심으로 반품을 원할 경우에는 구매자가 배송비를 지불합니다.")])])], 1)], 1), _c('v-divider'), _c('div', {
          staticClass: "py-12px"
        }, [_c('v-row', {
          staticClass: "row--xxs",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "txt txt--sm txt--dark font-weight-bold"
        }, [_vm._v("반품 → 교환")])]), _c('v-col', [_c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v("상품 교환 시 배송비는 교환의 원인을 제공한 자가 부담합니다."), _c('br'), _vm._v("구매자의 변심으로 교환을 원할 경우에는 구매자가 배송비를 지불합니다.")])])], 1)], 1), _c('v-divider', {
          staticClass: "border-dark"
        })], 1), _c('div', {
          staticClass: "pt-24px"
        }, [_c('div', {
          staticClass: "txt txt--sm pb-16px"
        }, [_c('b', {
          staticClass: "secondary--text"
        }, [_vm._v("판매자 부담")]), _c('span', {
          staticClass: "pl-4px pl-md-8px"
        }, [_vm._v("(반품 배송비 + 최초 배송비)")])]), _c('v-divider', {
          staticClass: "border-dark"
        }), _c('div', {
          staticClass: "py-12px"
        }, [_c('v-row', {
          staticClass: "row--xxs",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "txt txt--sm txt--dark font-weight-bold"
        }, [_vm._v("반품 → 환불")])]), _c('v-col', [_c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v("반품 시 배송비는 반품의 원인을 제공한 자가 부담합니다."), _c('br'), _vm._v("상품 하자나 제품 불일치로 인하 반품의 경우에는 판매자가 배송비를 지불합니다.")])])], 1)], 1), _c('v-divider'), _c('div', {
          staticClass: "py-12px"
        }, [_c('v-row', {
          staticClass: "row--xxs",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "txt txt--sm txt--dark font-weight-bold"
        }, [_vm._v("반품 → 교환")])]), _c('v-col', [_c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v("상품 교환 시 배송비는 교환의 원인을 제공한 자가 부담합니다."), _c('br'), _vm._v("상품 하자나 제품 불일치로 인하 반품의 경우에는 판매자가 배송비를 지불합니다.")])])], 1)], 1), _c('v-divider', {
          staticClass: "border-dark"
        })], 1)]), _c('v-col', {
          staticClass: "pa-14px py-lg-24px",
          attrs: {
            "cols": "12",
            "lg": "6"
          }
        }, [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("교환/반품 배송비")])]), _c('div', [_c('div', {
          staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
        }, [_vm._v("교환/반품 배송비 입금")]), _c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v("반송 시 상품에 배송비를 동봉 하실 경우 발생할 수 있는 분실 사고 및 책임분쟁을 방지하고자 배송비 전용 계좌로 입금을 해주셔야 합니다.")])]), _c('div', {
          staticClass: "mt-16px mt-md-30px"
        }, [_c('div', {
          staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
        }, [_vm._v("교환/반품 배송비 내역(5000원)")]), _c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v(" 교환 : 반송 착불 배송비 2500 + 재발송 배송비 2500"), _c('br'), _vm._v(" 반품 : 최소 발송 배송비 2500 + 반송 착불 배송비 2500"), _c('br'), _vm._v(" (최초 배송비를 결제 하신 경우도 해당 배송비는 상품 가격과 함께 환불 처리됩니다)"), _c('br'), _vm._v(" 도서지역 : 제주도를 포함한 도서지역은 왕복 4000원으 추가 배송비가 발생합니다. ")])])]), _c('v-col', {
          staticClass: "pa-14px py-lg-24px",
          attrs: {
            "cols": "12",
            "lg": "6"
          }
        }, [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("교환/반품이 가능한 경우는?")])]), _c('div', [_c('div', {
          staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
        }, [_vm._v("단순변심")]), _c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v("수령한 상품의 사이즈 변경 또는 디자인, 색상 등이 마음에 들지 않아, 수령 일 기준 7일이내 교환/반품 접수 및 배송비 입금, 반송 처리하시는 경우")])]), _c('div', {
          staticClass: "mt-16px mt-md-30px"
        }, [_c('div', {
          staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
        }, [_vm._v("배송오류 및 불량")]), _c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v(" 주문하신 상품과 다른 상품을 수령하셨거나, 제조상 명백한 불량의 상품을 수령하신 경우"), _c('br'), _vm._v(" 단, 불량 상품의 경우 세탁 및 수선과정 이후 발생 또는 발견하는 손상 및 불량은 확인이 불가하므로 해당하지 않습니다. ")])])]), _c('v-col', {
          staticClass: "pa-14px py-lg-24px",
          attrs: {
            "cols": "12",
            "lg": "6"
          }
        }, [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("교환/반품이 불가능한 경우는?")])]), _c('div', [_c('div', {
          staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
        }, [_vm._v("청약철회 기간 경과 또는 구매 확정")]), _c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v("상품 수령 후 7일 이내 교환/반품 접수 및 배송비 입금, 반송 처리를 하지 않으신 경우 또는 구매 확정이 완료되어 포인트가 지급된 경우")])]), _c('div', {
          staticClass: "mt-16px mt-md-30px"
        }, [_c('div', {
          staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
        }, [_vm._v("상품 착용 또는 훼손")]), _c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v("상품 착용의 흔적이 있거나, 라벨 및 텍 제거, 제품 박스 및 포장 제거 등으로 새 상품으로서의 가치가 감소한 경우")])]), _c('div', {
          staticClass: "mt-16px mt-md-30px"
        }, [_c('div', {
          staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
        }, [_vm._v("교환불가(반품가능)")]), _c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v("구매하신 상품의 사이즈 또는 컬러, 동일가 상품 교환 이외에 교환을 원하시는 상품의 가격이 다른 경우 반품 후 재 주문을 해주셔야 합니다.")])])])], 1), _c('div', {
          staticClass: "btn-wrap btn-wrap--lg mx-n12px mx-md-0px d-md-none"
        }, [_c('v-row', {
          staticClass: "ma-0px ma-md-n4px",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0px pa-md-4px",
          attrs: {
            "cols": "6",
            "md": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 min-w-md-140px"
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("신청하기")])], 1), _c('v-col', {
          staticClass: "pa-0px pa-md-4px",
          attrs: {
            "cols": "6",
            "md": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 d-md-none"
        }, 'v-btn', Object.assign({}, _vm.btn_primary3), false), [_vm._v("취소")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }