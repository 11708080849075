var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isCompleted ? _c('product-form-modal-cart', {
    ref: "modal-cart",
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on)), _vm._t("activator", null, null, {
          attrs: {
            disabled: _vm.disabled,
            loading: _vm.loading
          },
          on: {
            click: _vm.click
          }
        })];
      }
    }], null, true)
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }