var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('mypage-layout', {
    attrs: {
      "title": "나의 단골"
    }
  }, [_c('div', {
    staticClass: "tabs-wrap tabs-wrap--lg"
  }, [_c('v-tabs', {
    attrs: {
      "centered": "",
      "grow": ""
    },
    model: {
      value: _vm.tabs,
      callback: function ($$v) {
        _vm.tabs = $$v;
      },
      expression: "tabs"
    }
  }, [_c('tab-primary', {
    attrs: {
      "exact": ""
    }
  }, [_vm._v("나의 단골 AS샵")]), _c('tab-primary', {
    attrs: {
      "exact": ""
    }
  }, [_vm._v("나의 단골 미니홈")])], 1)], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tabs,
      callback: function ($$v) {
        _vm.tabs = $$v;
      },
      expression: "tabs"
    }
  }, [_c('v-tab-item', [_c('div', {
    staticClass: "pb-30px pb-md-8px"
  }, [_c('v-btn', _vm._b({
    staticClass: "d-md-none",
    attrs: {
      "block": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_c('v-img', {
    staticClass: "mr-4px",
    attrs: {
      "src": "/images/icon/icon-marker-pin.png",
      "max-width": "16"
    }
  }), _vm._v(" 내 근처 AS샵 보기 ")], 1), _c('v-row', {
    attrs: {
      "justify": "end",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "d-none d-md-block ml-auto",
    attrs: {
      "small": "",
      "color": "grey",
      "to": "/sub/store/map"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_c('v-img', {
    staticClass: "mr-4px",
    attrs: {
      "src": "/images/icon/icon-marker-pin-grey.png",
      "max-width": "16"
    }
  }), _vm._v(" 내 근처 AS샵 보기")], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--sm"
  }, [_vm._l(_vm.follows, function (shop, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "lg": "4"
      }
    }, [_c('store-profile', _vm._b({
      scopedSlots: _vm._u([{
        key: "avatar",
        fn: function () {
          return [shop.thumb ? _c('v-img', {
            attrs: {
              "src": shop.thumb.url
            }
          }) : _c('v-img', {
            attrs: {
              "src": "/images/minishop/profile-default.png"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, 'store-profile', {
      value: shop
    }, false))], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "4"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "4"
    }
  })], 2), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "row--sm"
  }, [_vm._l(_vm.follows, function (home, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "xl": "4"
      }
    }, [_c('store-profile', _vm._b({
      scopedSlots: _vm._u([{
        key: "avatar",
        fn: function () {
          return [home.thumb ? _c('v-img', {
            attrs: {
              "src": home.thumb.url
            }
          }) : _c('v-img', {
            attrs: {
              "src": "/images/minishop/profile-default.png"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, 'store-profile', {
      value: home
    }, false))], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "4"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "4"
    }
  })], 2), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }