<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="개인결제">
            <list-search-payments
                v-bind="{
                    status: USER_PAYMENT_STATUS.PAY_AWAITING.value,
                    loading,
                }"
            />

            <page-section class="page-section--sm pb-0">
                <template #containerImmersive>
                    <!-- S: PC -->
                    <v-data-table v-bind="{ items, headers, loading }" :headers="headers" :items="items" hide-default-footer disable-sort disable-filtering disable-pagination mobile-breakpoint="0" class="v-data-table--default v-data-table--table-fixed v-data-table--min-w-500px v-data-table--md d-none d-xl-block">
                        <template v-slot:[`item.status`]="{ item }">
                            <div :class="isPaid(item?.status) ? 'txt--dark' : 'red--text'">{{ USER_PAYMENT_STATUS[item?.status]?.text }}</div>
                        </template>
                        <template v-slot:[`item.createdAt`]="{ item }">
                            <div>{{ item?.createdAt?.toDateTime?.() || "-" }}</div>
                        </template>
                        <template v-slot:[`item.expire`]="{ item }">
                            <div>{{ item?.expire?.isActive ? `${item.expire?.startsAt?.toDate?.()} ~ ${item.expire?.endsAt?.toDate?.()}` : "무기한" }}</div>
                        </template>
                        <template v-slot:[`item.product`]="{ item }">
                            <div class="txt--dark text-left">{{ item?.product?.name }} {{ item?.product?.code }}</div>
                        </template>
                        <template v-slot:[`item.price`]="{ item }">
                            <div class="red--text">{{ item.price.format() }}원</div>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-row v-if="!isCanceled(item.status)" class="ma-n4px" justify="center">
                                <v-col v-if="!isPaid(item.status)" class="pa-4px">
                                    <v-btn v-bind="{ ...btn_primary }" x-small @click="pay(item)">결제하기</v-btn>
                                </v-col>
                                <v-col v-else class="pa-4px">
                                    <v-btn v-bind="{ ...btn_primary }" x-small @click="cancel(item)">취소하기</v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                    <!-- E: PC -->
                    <!-- S: 모바일 -->
                    <div class="d-xl-none">
                        <v-divider class="border-dark" style="border-width: 2px 0 0" />
                        <!-- 반복 -->
                        <div v-for="(item, index) in items" :key="index" class="border-b">
                            <div class="grey lighten-5">
                                <div class="d-flex align-center px-4px py-12px border-b" style="min-height: 54px">
                                    <v-row align="center" class="mx-n6px my-n2px">
                                        <v-col cols="12" sm="" class="px-6px py-2px">
                                            <v-row align="center" class="ma-n4px">
                                                <v-col cols="auto" class="pa-4px">
                                                    <span class="txt txt--xs txt--dark font-weight-medium">날짜</span>
                                                </v-col>
                                                <v-col cols="auto" class="pa-4px">
                                                    <span class="txt txt--xs">{{ item?.createdAt?.toDateTime?.() || "-" }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="" class="px-6px py-2px">
                                            <v-row align="center" class="ma-n4px">
                                                <v-col cols="auto" class="pa-4px">
                                                    <span class="txt txt--xs txt--dark font-weight-medium">유효기간</span>
                                                </v-col>
                                                <v-col cols="auto" class="pa-4px">
                                                    <span class="txt txt--xs">
                                                        {{ item?.expire?.isActive ? `${item.expire?.startsAt?.toDate?.()} ~ ${item.expire?.endsAt?.toDate?.()}` : "무기한" }}
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <div :class="item.paymentStatus == '결제대기' ? 'pb-12px' : 'pb-20px'" class="pt-20px">
                                <v-row class="row--xs">
                                    <v-col cols="auto">
                                        <div class="txt txt--sm font-weight-medium">
                                            <div :class="isPaid(item?.status) ? 'txt--dark' : 'red--text'">[{{ USER_PAYMENT_STATUS[item?.status]?.text }}]</div>
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div class="txt txt--sm txt--dark">{{ item?.product?.name }} {{ item?.product?.code }}</div>
                                        <div class="txt txt--sm red--text mt-8px">{{ item.price.format() }}원</div>
                                    </v-col>
                                </v-row>
                                <div v-if="!isCanceled(item.status)" class="btn-wrap">
                                    <v-row no-gutters>
                                        <v-col>
                                            <v-btn v-if="!isPaid(item.status)" v-bind="{ ...btn_primary }" x-small block @click="pay(item)">결제하기</v-btn>
                                            <v-btn v-else v-bind="{ ...btn_primary }" x-small block>결제취소</v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                        <!-- //반복 -->
                    </div>
                    <!-- E: 모바일 -->
                </template>
            </page-section>
        </mypage-layout>
        <nice-payments ref="nicePay" v-on="{ complete }" />
    </client-page>
</template>

<script>
import api from "@/api";
import { mapActions, mapState } from "vuex";
import NicePayments from "@/components/plugins/nice/nice-payments.vue";
import { btn_primary, btn_secondary, btn_secondary2, btn_tertiary, attrs_input, USER_PAYMENT_STATUS, NICEPAY_PURCAHSE_TYPES } from "@/assets/variables";

import ListSearchPayments from "@/components/client/mypage/payments/list-search-payments.vue";
import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import PageSection from "@/components/client/templates/page-section.vue";

const headers = [
    { width: "8%", align: "center", text: "결제상태", value: "status" },
    { width: "14%", align: "center", text: "날짜", value: "createdAt" },
    { width: "16%", align: "center", text: "유효기간", value: "expire" },
    { width: "38%", align: "center", text: "결제상품", value: "product" },
    { width: "14%", align: "center", text: "주문금액", value: "price" },
    { width: "10%", align: "center", text: "관리", value: "actions" },
];

export default {
    components: {
        ClientPage,
        NicePayments,
        ListSearchPayments,
        SubVisualPrimary,
        MypageLayout,
        VerticalFormTable,
        VAddressField,
        VPhoneField,
        VDateField,
        TabPrimary,
        UDialog,
        PageSection,
    },
    data: () => ({
        USER_PAYMENT_STATUS,
        btn_primary,
        btn_secondary,
        btn_secondary2,
        btn_tertiary,
        attrs_input,

        headers,

        loading: false,
        summary: {
            totalCount: 0,
        },
        payments: [],
        form: {},
    }),
    computed: {
        ...mapState(["accessToken"]),
        params() {
            let { ...query } = this.$route.query;
            delete query.page;
            return query;
        },
        items() {
            return [...this.payments];
        },
        breadcrumbsItems() {
            const items = [{ text: "HOME" }, { text: "마이페이지" }, { text: "개인결제" }];
            return items.map((item) => ({ ...item, disabled: false }));
        },
        user() {
            return this.$store.state.user;
        },
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            this.search();
            await this.getUser();
        },
        async search() {
            if (this.loading) return;
            else this.loading = false;
            try {
                let { summary, payments } = await api.v1.me.payments.gets({
                    params: this.params,
                });
                this.summary = summary;
                this.payments = payments;
                this.loading = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async pay(item) {
            if (this.loading) return;
            else this.loading = false;

            try {
                this.validate(item);
                this.$refs.nicePay.collection = NICEPAY_PURCAHSE_TYPES.USER_PAYMENTS.value;
                this.$refs.nicePay.orderNo = item.orderNo;
                this.$refs.nicePay.orderName = item.product.name;
                this.$refs.nicePay.paymentMethod = "card";
                this.$refs.nicePay.paymentGateway = "";
                this.$refs.nicePay.paymentAmount = item.price;
                this.$refs.nicePay.buyerName = this.user.name;
                this.$refs.nicePay.buyerPhone = this.user.phone;
                this.$refs.nicePay.buyerEmail = this.user.email;
                this.$refs.nicePay.pay();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async cancel(item) {
            if (this.loading) return;
            else this.loading = false;

            try {
                this.validate(item);
                if(confirm("결제를 취소하시겠습니까?")) {
                    let form = {
                        ...item,
                        status: USER_PAYMENT_STATUS.PAY_CANCELED.value,
                    };
                    await api.v1.me.payments.put(form);

                    alert("결제가 취소되었습니다.");
                    this.init();
                }

            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validate(item) {
            let {
                expire: { startsAt, endsAt, isActive },
            } = item;
            const now = this.$dayjs();
            if (!this.accessToken) throw new Error("로그인이 필요합니다.");
            if (isActive) {
                startsAt = this.$dayjs(startsAt).startOf("day");
                endsAt = this.$dayjs(endsAt).endOf("day");
                if (now.isAfter(endsAt) || now.isBefore(startsAt)) throw new Error("유효기간이 만료되었습니다.");
            }
        },
        isPaid(status) {
            return status === USER_PAYMENT_STATUS.PAY_COMPLETED.value;
        },
        isCanceled(status) {
            return status === USER_PAYMENT_STATUS.PAY_CANCELED.value;
        },
        complete(payload) {
            alert(["0000", "3001"].includes(payload?.resCode) ? "결제가 완료되었습니다." : payload?.resMessage);
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.query"() {
            this.init();
        },
    },
};
</script>
