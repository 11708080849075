<template>
    <client-page>
        <mypage-layout title="대량구매요청">
            <page-section class="py-0">
                <v-card color="grey lighten-5">
                    <div class="pa-20px pa-md-40px">
                        <h3 class="tit tit--xs">모아고</h3>
                        <p class="txt txt--sm txt--dark mt-8px">아래의 신청 게시판에 글을 남겨주시면 빠른 시간 내에 답변 드리겠습니다.</p>
                        <template v-if="isCompany">
                            <p class="txt txt--xs txt--light mt-16px mt-md-20px">* 설치비용은 제품 및 지역에 따라 다르게 책정될 수 있습니다.</p>
                            <div class="btn-wrap">
                                <v-btn :to="`${$route.path}/inquire`" v-bind="{ ...btn_primary }" class="min-w-120px">요청하기</v-btn>
                            </div>
                        </template>
                        <template v-else>
                            <p class="txt txt--xs txt--light mt-16px mt-md-20px">* 기업회원만 사용할 수 있는 기능입니다.</p>
                            <div class="btn-wrap">
                                <v-btn :to="`/login`" v-bind="{ ...btn_secondary }" class="min-w-120px mr-2">로그인하기</v-btn>
                                <v-btn :to="`/join`" v-bind="{ ...btn_primary }" class="min-w-120px">가입하기</v-btn>
                            </div>
                        </template>
                    </div>
                </v-card>
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import { btn_primary, btn_secondary, USER_TYPES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
    },
    data: () => ({
        btn_primary,
        btn_secondary,
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        isCompany() {
            return this.$store.state.user?.type == USER_TYPES.COMPANY.value;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            if (!this.accessToken) {
                this.$router.push("/login");
            }
        },
    },
};
</script>
