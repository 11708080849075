var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--table-fixed v-data-table--min-w-500px v-data-table--md d-none d-xl-block",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "mobile-breakpoint": "0",
      "no-data-text": "마일리지 내역이 없습니다"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.amount`,
      fn: function (_ref2) {
        var _value$format;
        var value = _ref2.value;
        return [_c('span', {
          class: {
            'red--text': (value || 0) < 0,
            'secondary--text': 0 < (value || 0)
          }
        }, [_vm._v(" " + _vm._s((value || 0) > 0 ? "+" : "") + _vm._s((value === null || value === void 0 ? void 0 : (_value$format = value.format) === null || _value$format === void 0 ? void 0 : _value$format.call(value)) || 0) + "원 ")])];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }