<template>
    <v-tab-item :value="tab">
        <div class="tit-wrap" :class="$vuetify.breakpoint.mdAndUp ? 'text-center' : ''">
            <h3 class="tit font-weight-light line-height-15">안전한 회원정보 수정을 위해 <br />비밀번호를 다시 한번 입력해 주세요.</h3>
        </div>

        <div>
            <v-divider class="border-dark d-none d-md-block" />
            <v-card :min-height="$vuetify.breakpoint.mdAndUp ? 300 : 400" class="d-flex align-center justify-center">
                <div>
                    <div class="txt txt--sm txt--dark text-center mb-10px">비밀번호를 입력하세요.</div>
                    <v-password-field v-model="form.password" class="font-size-20" @keydown.enter="verify"/>
                </div>
            </v-card>
            <v-divider class="border-dark d-none d-md-block" />
        </div>

        <div class="btn-wrap btn-wrap--lg mx-n12px mx-md-0px">
            <v-row justify="center" class="row--sm">
                <v-col cols="12" md="auto">
                    <v-btn v-bind="{ ...btn_primary, loading }" class="v-size--xx-large w-100 min-w-md-140px" @click="verify">확인</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-tab-item>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { btn_primary } from "@/assets/variables";

import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";

export default {
    components: {
        TitWrapSubtitle,
        VerticalFormTable,
        VPasswordField,
    },
    props: {
        tab: { type: String, default: "verify" },
    },
    data: () => ({
        btn_primary,

        form: {
            password: null,
        },

        loading: false,
    }),
    computed: {
        username() {
            return this.$store.state.user?.username;
        },
    },
    methods: {
        async verify() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { password } = this.form;
                password = CryptoAES.encrypt(password);
                await api.v1.me.verify({ password });
                this.$emit("next");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>