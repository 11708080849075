<template>
    <v-sheet>
        <body-mb v-bind="{ items }" />
        <body-pc v-bind="{ items, loading }" />
    </v-sheet>
</template>

<script>
import BodyMb from "./body/body-mb.vue";
import BodyPc from "./body/body-pc.vue";

export default {
    props: {
        items: { type: Array, default: () => [] }, // points
        loading: { type: Boolean, default: false },
    },
    components: {
        BodyMb,
        BodyPc,
    },
};
</script>

<style>
</style>