var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('mypage-layout', {
    attrs: {
      "title": "반품/교환 상세"
    }
  }, [_c('tit-wrap-line', {
    staticClass: "d-xl-none",
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "ma-n2px ma-sm-n6px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2px pa-sm-6px",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 상품정보 ")])])], 1)], 1), _c('v-divider', {
    staticClass: "border-dark d-none d-xl-block",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "pt-xl-16px mb-xl-n4px"
  }, [_c('tit-wrap-line', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col'), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-60px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("수량")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("상품금액")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-60px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("취소수량")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("주문상태")])])])], 1)], 1)], 1), _c('v-divider', {
    class: _vm.$vuetify.breakpoint.xl ? '' : 'border-dark'
  }), _c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "py-20px py-xl-10px"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align-xl": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', [_vm._v("상품주문번호 : 202401020001_001")])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "12",
      "xl": ""
    }
  }, [_c('div', {
    staticClass: "d-xl-none"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-xl-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-xl-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": "104"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-xl-12px"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v("볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)")])]), _c('div', {
    staticClass: "txt txt--sm txt--dark mt-4px"
  }, [_vm._v("1,650,000원")])], 1)], 1)], 1), _c('div', {
    staticClass: "d-none d-xl-block"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": "130"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px"
  }, [_c('div', {
    staticClass: "txt txt--sm mb-4px mb-md-8px mb-lg-14px"
  }, [_c('span', {
    staticClass: "pr-6px"
  }, [_vm._v("결제완료")]), _c('span', [_vm._v("상품주문번호 : 202401020001_001")])]), _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v("볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)")])]), _c('div', {
    staticClass: "txt txt--sm mt-4px mt-md-8px"
  }, [_vm._v("색상 : 블랙 / 수량 : 1개")])], 1)], 1)], 1)]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-60px text-xl-center d-none d-xl-block"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("수량 : ")]), _c('span', [_vm._v("1")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "6",
      "md": "4",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("상품금액 : ")]), _c('span', [_vm._v("1,650,000원")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-60px text-xl-center d-none d-xl-block"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("취소수량 : ")]), _c('span', [_vm._v("1")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center d-none d-xl-block"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("주문상태 : ")]), _c('span', [_vm._v("상품준비중")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "text-center grey lighten-5"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', [_vm._v("색상 : 블랙")]), _c('span', {
    staticClass: "px-4px"
  }, [_vm._v("/")]), _c('span', [_vm._v("수량 : 1")])])]), _c('v-col', {
    staticClass: "ml-n1px",
    attrs: {
      "cols": "auto",
      "align-self": "stretch"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', [_vm._v("취소수량 : ")]), _c('span', [_vm._v("1")])])]), _c('v-col', {
    staticClass: "ml-n1px",
    attrs: {
      "cols": "auto",
      "align-self": "stretch"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', [_vm._v("주문상태 : ")]), _c('span', [_vm._v("상품준비중")])])])], 1)], 1)])], 1)], 1), _c('v-divider')], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("교환상품 배송정보")])]), _c('v-data-table', {
          staticClass: "v-data-table--default v-data-table--no-hover v-data-table--md v-data-table--min-w-500px d-none d-lg-block",
          attrs: {
            "hide-default-footer": "",
            "disable-filtering": "",
            "disable-pagination": "",
            "disable-sort": "",
            "mobile-breakpoint": "0"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "width": "10%"
                }
              }, [_vm._v("교환상태")]), _c('td', {
                staticStyle: {
                  "width": "40%"
                }
              }, [_vm._v("배송완료")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "width": "10%"
                }
              }, [_vm._v("회수인")]), _c('td', {
                staticStyle: {
                  "width": "40%"
                }
              }, [_vm._v("홍길동")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("교환접수번호")]), _c('td', [_vm._v("C23234222")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("휴대폰")]), _c('td', [_vm._v("010-1234-5678")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("교환 접수일")]), _c('td', [_vm._v("2023년12월12일")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("연락처")]), _c('td', [_vm._v("010-1234-5678")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("환불 완료일")]), _c('td', [_vm._v("2023년 12월14일")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("주소")]), _c('td', [_vm._v("[ 14547 ] 경기도 부천시 길주로 202 (부천시청역) 12")])])])];
            },
            proxy: true
          }])
        }), _c('u-vertical-items', {
          staticClass: "d-lg-none"
        }, [_c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 교환상태 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 배송완료 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_c('span', {
                staticClass: "letter-spacing-n04"
              }, [_vm._v("교환접수번호")])];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" C23234222 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 교환 접수일 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 2023년12월12일 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 환불 완료일 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 2023년 12월14일 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 회수인 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 홍길동 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 휴대폰 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 010-1234-5678 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 연락처 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 010-1234-5678 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 주소 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" [ 14547 ] 경기도 부천시 길주로 202 (부천시청역) 12 ")];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("배송운송장 정보")])]), _c('v-data-table', {
          staticClass: "v-data-table--default v-data-table--no-hover v-data-table--md v-data-table--min-w-500px d-none d-lg-block",
          attrs: {
            "hide-default-footer": "",
            "disable-filtering": "",
            "disable-pagination": "",
            "disable-sort": "",
            "mobile-breakpoint": "0"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "width": "10%"
                }
              }, [_vm._v("택배")]), _c('td', {
                staticStyle: {
                  "width": "90%"
                }
              }, [_vm._v("CJ대한통운")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("송장번호")]), _c('td', [_vm._v("12345456465464")])])])];
            },
            proxy: true
          }])
        }), _c('u-vertical-items', {
          staticClass: "d-lg-none"
        }, [_c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 택배 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" CJ대한통운 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 송장번호 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 12345456465464 ")];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("상품 회수정보")])]), _c('v-data-table', {
          staticClass: "v-data-table--default v-data-table--no-hover v-data-table--md v-data-table--min-w-500px d-none d-lg-block",
          attrs: {
            "hide-default-footer": "",
            "disable-filtering": "",
            "disable-pagination": "",
            "disable-sort": "",
            "mobile-breakpoint": "0"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "width": "10%"
                }
              }, [_c('span', {
                staticClass: "letter-spacing-n04"
              }, [_vm._v("상품회수 진행여부")])]), _c('td', {
                staticStyle: {
                  "width": "40%"
                }
              }, [_vm._v("판매자 전달완료")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "width": "10%"
                }
              }, [_vm._v("반송장정보")]), _c('td', {
                staticStyle: {
                  "width": "40%"
                }
              })]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("회수인")]), _c('td', [_vm._v("홍길동")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("택배")]), _c('td', [_vm._v("CJ대한통운")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("휴대폰")]), _c('td', [_vm._v("010-1234-5456")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("송장번호")]), _c('td', [_vm._v("12345456465464")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("연락처")]), _c('td', [_vm._v("010-4546-45465")]), _c('th', {
                staticClass: "text-center"
              }), _c('td')]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("주소")]), _c('td', [_vm._v("[ 14547 ] 경기도 부천시 길주로 202 (부천시청역) 12")]), _c('th', {
                staticClass: "text-center"
              }), _c('td')])])];
            },
            proxy: true
          }])
        }), _c('u-vertical-items', {
          staticClass: "d-lg-none"
        }, [_c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 진행여부 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 판매자 전달완료 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 회수인 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 홍길동 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 휴대폰 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 010-1234-5456 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 연락처 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 010-4546-45465 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 주소 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" [ 14547 ] 경기도 부천시 길주로 202 (부천시청역) 12 ")];
            },
            proxy: true
          }])
        })], 1), _c('u-vertical-items', {
          staticClass: "d-lg-none mt-20px"
        }, [_c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 반송장정보 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return undefined;
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 택배 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" CJ대한통운 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 송장번호 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 12345456465464 ")];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("교환사유")])]), _c('v-data-table', {
          staticClass: "v-data-table--default v-data-table--no-hover v-data-table--md v-data-table--min-w-500px d-none d-lg-block",
          attrs: {
            "hide-default-footer": "",
            "disable-filtering": "",
            "disable-pagination": "",
            "disable-sort": "",
            "mobile-breakpoint": "0"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "width": "10%"
                }
              }, [_vm._v("교환사유")]), _c('td', {
                staticStyle: {
                  "width": "90%"
                }
              }, [_vm._v("색상/ 사이즈가 기대와 다름")])])])];
            },
            proxy: true
          }])
        }), _c('u-vertical-items', {
          staticClass: "d-lg-none"
        }, [_c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 교환사유 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 색상/ 사이즈가 기대와 다름 ")];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }