<template>
  <div>
        <v-divider class="border-dark d-none d-md-block" />
        <!-- 반복 -->
        <div v-for="(item, index) in items" :key="index" :class="$vuetify.breakpoint.mdAndUp ? 'border-b' : ''" class="py-10px">
            <v-row align="center" class="ma-n10px">
                <v-col cols="12" md="" class="pa-10px">
                    <v-row align-md="center" class="ma-n6px">
                        <v-col cols="auto" class="pa-6px">
                            <v-card tile outlined class="overflow-hidden">
                                <router-link to="/">
                                    <v-img :src="item?.product?.thumb?.url" :aspect-ratio="70 / 54" width="70">
                                        <template #placeholder>
                                            <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                        </template>
                                    </v-img>
                                </router-link>
                            </v-card>
                        </v-col>
                        <v-col class="pa-6px">
                            <div class="txt txt--xs pb-2px">{{ item.deliveredAt ? item.deliveredAt.toDate() : "" }} 배송완료</div>
                            <div class="txt txt--sm txt--dark">상품 : {{ item.product.name }}</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="auto" class="pa-10px">
                    <v-btn v-bind="{ ...btn_secondary2 }" small block :to="{path: './reviews-input', query: {_product: item._product, _purchase: item._id}}">후기작성</v-btn>
                </v-col>
            </v-row>
        </div>
        <!-- // 반복 -->

        <div class="pagination-wrap pagination-wrap--sm">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div>
  </div>
</template>

<script>
import api from "@/api";
import { btn_secondary2 } from "@/assets/variables";
export default {
    data: () => ({
        btn_secondary2,

        purchases: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed:{
        items() {
            return [...this.purchases];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            return { ...query };
        },
    },
    methods:{
        async init() {
            this.purchases = [];
            this.summary.totalCount = 0;
            this.loading = false;

            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit } = this;

                var { summary, purchases } = await api.v1.me.purchases.gets({
                    headers: { skip, limit },
                    params: { reviewable: true },
                });

                this.summary = summary;
                this.purchases = purchases;
            } finally {
                this.loading = false;
            }
        },
    },
    mounted(){
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
}
</script>

<style>

</style>