<template>
    <v-row :class="rowSize">
        <slot />
    </v-row>
</template>

<script>
export default {
    props: {
        rowSize: { type: String, default: "row--sm" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
