<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="관심상품">
            <template v-if="items.length">
                <!-- 상단조작버튼 -->
                <!-- <div class="pb-16px pb-md-20px">
                    <v-row align="center" class="row--xs">
                        <v-col cols="auto">
                            <select-all-btn v-model="selected" v-bind="{ items }">
                                <template #activator="{ active, toggle }">
                                    <v-checkbox v-bind="{ ...checkbox_primary }" :value="active" label="전체선택" class="v-size--large" @click="toggle" />
                                </template>
                            </select-all-btn>
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                            <remove-targets-btn v-model="loading" :filter="({ _id }) => selected.some((item) => item?._id == _id)" v-bind="{ items, remove }" v-on="{ search }">
                                <template #activator="{ attrs, on }">
                                    <v-btn v-bind="{ ...btn_tertiary, ...attrs }" v-on="on" small>
                                        <span><v-icon color="grey lighten-3" class="mr-6px">mdi-close</v-icon>선택삭제</span>
                                    </v-btn>
                                </template>
                            </remove-targets-btn>
                        </v-col>
                        <v-col cols="auto">
                            <remove-targets-btn v-model="loading" :filter="({ product }) => !product?.sells" v-bind="{ items, remove }" v-on="{ search }">
                                <template #activator="{ attrs, on }">
                                    <v-btn v-bind="{ ...btn_tertiary, ...attrs }" v-on="on" small>
                                        <span><v-icon color="grey lighten-3" class="mr-6px">mdi-close</v-icon>주문불가삭제</span>
                                    </v-btn>
                                </template>
                            </remove-targets-btn>
                        </v-col>
                    </v-row>
                </div> -->

                <!-- 찜한상품목록 -->
                <!-- <div class="border-t border-2 border-dark">
                    <like-product-item v-for="item in items" :key="item._id" v-model="selected" v-bind="{ item }" v-on="{ remove }" />
                </div> -->

                <v-row class="mx-n4px my-n14px mx-md-n16px my-md-n30px">
                    <v-col v-for="(item, index) in items" :key="index" cols="6" md="4" class="px-4px py-14px px-md-16px py-md-30px">
                        <v-row class="ma-n8px ma-md-n6px">
                            <v-col cols="12" class="pa-8px pa-md-6px">
                                <v-card tile outlined class="overflow-hidden">
                                    <router-link :to="`/shop/products/${item?.product?._id}`">
                                        <v-img :src="item.product?.thumb?.url" :aspect-ratio="160 / 122">
                                            <template #placeholder>
                                                <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                            </template>
                                        </v-img>
                                    </router-link>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="pa-8px pa-md-6px">
                                <div class="txt txt--sm pb-4px">{{ item?.product?.seller?.nickname }}</div>
                                <router-link :to="`/shop/products/${item?.product?._id}`">
                                    <div class="txt txt--sm txt--dark font-weight-medium line-height-16">{{ item?.product?.name }}</div>
                                </router-link>
                                <div class="pt-12px pt-md-14px">
                                    <v-row align="center" class="ma-n6px ma-md-n4px">
                                        <v-col v-if="item?.product?.discountRate" cols="auto" order-md="3" class="pa-6px pa-md-4px">
                                            <span class="font-size-16 font-weight-bold red--text">{{ item?.product?.discountRate }}%</span>
                                        </v-col>
                                        <v-col cols="auto" order-md="1" class="pa-4px">
                                            <span class="font-size-16 font-weight-bold accent1--text">{{ item?.product?.salePrice.format() }}원</span>
                                        </v-col>
                                        <v-col v-if="item?.product?.discountRate" cols="auto" order-md="2" class="pa-4px d-none d-md-block">
                                            <span class="font-size-14 grey--text text-decoration-line-through">{{ item?.product?.price.format() }}</span>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="pt-12px pt-md-18px">
                                    <v-row align="center" :class="$vuetify.breakpoint.xl ? 'ma-n4px' : 'no-gutters'">
                                        <v-col xl="auto" :class="$vuetify.breakpoint.xl ? 'pa-4px' : ''">
                                            <!-- S: dialog -->
                                            <u-dialog>
                                                <!-- <template #activator="{ attrs, on }">
                                                    <v-btn v-bind="{ ...btn_primary, ...attrs }" v-on="on" small :style="$vuetify.breakpoint.xl ? 'min-width: 190px' : 'width:100%'">장바구니 담기</v-btn>
                                                </template> -->

                                                <div>
                                                    <div class="tit-wrap tit-wrap--sm">
                                                        <div class="tit tit--xxs">장바구니 담기</div>
                                                    </div>

                                                    <v-divider class="border-dark mb-20px" style="border-width: 2px 0 0" />
                                                    <v-row class="ma-n8px ma-md-n6px">
                                                        <v-col cols="auto" class="pa-8px pa-md-6px">
                                                            <v-card tile outlined class="overflow-hidden">
                                                                <router-link :to="`/shop/products/${item?.product?._id}`">
                                                                    <v-img :src="item.product?.thumb?.url" :aspect-ratio="130 / 100" width="130">
                                                                        <template #placeholder>
                                                                            <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                                        </template>
                                                                    </v-img>
                                                                </router-link>
                                                            </v-card>
                                                        </v-col>
                                                        <v-col class="pa-8px pa-md-6px">
                                                            <div class="txt txt--sm pb-4px">{{ item?.product?.seller?.nickname }}</div>
                                                            <router-link :to="`/shop/products/${item.product?._id}`">
                                                            <div class="txt txt--sm txt--dark font-weight-medium line-height-16">{{ item?.product?.name }}</div>
                                                            </router-link>
                                                            <div class="pt-12px pt-md-14px">
                                                                <v-row align="center" class="ma-n6px ma-md-n4px">
                                                                    <v-col v-if="item.product?.discountRate" cols="auto" order-md="3" class="pa-6px pa-md-4px">
                                                                        <span class="font-size-16 font-weight-bold red--text">{{ item.product?.discountRate }}%</span>
                                                                    </v-col>
                                                                    <v-col cols="auto" order-md="1" class="pa-4px">
                                                                        <span class="font-size-16 font-weight-bold accent1--text">{{ item.product?.salePrice.format() }}원</span>
                                                                    </v-col>
                                                                    <v-col v-if="item.product?.discountRate" cols="auto" order-md="2" class="pa-4px d-none d-md-block">
                                                                        <span class="font-size-14 grey--text text-decoration-line-through">{{ item.product?.price.format() }}</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider class="mt-20px" />
                                                    <div class="py-20px">
                                                        <div>
                                                            <div class="txt txt--dark pb-6px pb-md-10px">필수선택</div>
                                                            <v-select v-bind="{ ...attrs_input }" dense label="선택" />
                                                        </div>
                                                        <div class="pt-20px pt-md-30px">
                                                            <div class="txt txt--dark pb-6px pb-md-10px">옵션선택</div>
                                                            <v-select v-bind="{ ...attrs_input }" dense label="선택" />
                                                        </div>
                                                        <div class="pt-20px pt-md-30px">
                                                            <div class="txt txt--dark pb-6px pb-md-10px">배송선택</div>
                                                            <v-select v-bind="{ ...attrs_input }" dense label="선택" />
                                                        </div>
                                                    </div>
                                                    <v-divider class="border-dark mb-20px" style="border-width: 2px 0 0" />
                                                    <div>
                                                        <v-row class="row--xxs txt">
                                                            <v-col cols="auto">상품금액</v-col>
                                                            <v-col class="text-right">1,780,000원</v-col>
                                                        </v-row>
                                                    </div>
                                                    <div class="pt-12px">
                                                        <v-row class="row--xxs txt">
                                                            <v-col cols="auto">+ 총 배송비</v-col>
                                                            <v-col class="text-right">0원</v-col>
                                                        </v-row>
                                                    </div>
                                                    <div class="pt-12px">
                                                        <v-row class="row--xxs txt">
                                                            <v-col cols="auto">- 총 할인금액</v-col>
                                                            <v-col class="text-right">-356,000원</v-col>
                                                        </v-row>
                                                    </div>
                                                    <div class="pt-12px">
                                                        <v-row class="row--xxs txt">
                                                            <v-col cols="auto">총 상품금액</v-col>
                                                            <v-col class="text-right">
                                                                <span class="txt--dark"><span class="font-size-18 font-size-md-24">1,424,000</span>원</span>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </div>

                                                <template v-slot:actions>
                                                    <div class="w-100 mx-auto">
                                                        <v-row no-gutters justify="center">
                                                            <v-col cols="12" md="auto">
                                                                <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large w-100 min-w-sm-140px">추가</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </template>
                                            </u-dialog>
                                            <!-- E: dialog -->
                                        </v-col>
                                        <v-col cols="auto" :class="$vuetify.breakpoint.xl ? 'pa-4px' : ''">
                                            <v-btn v-bind="{ ...btn_secondary2 }" small :style="$vuetify.breakpoint.xl ? 'width: 80px' : 'width:100%'" @click="remove(item)">삭제</v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!-- 페이지네이션 -->
                <div class="pagination-wrap">
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </div>

                <v-divider class="border-dark mt-50px mx-n12px mt-md-80px mx-md-0px" style="border-width: 2px 0 0" />

                <div class="btn-wrap btn-wrap--md">
                    <v-row justify="center" class="ma-md-n4px">
                        <v-col cols="12" md="auto" class="pa-md-4px">
                            <v-btn v-bind="{ ...btn_secondary, loading }" class="v-size--xx-large w-100 min-w-md-140px" @click="removeAll">전체 삭제</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </template>

            <template v-else>
                <div class="py-20px text-center">
                    <div class="mb-18px mb-md-24px">
                        <v-icon size="60px" color="primary">mdi-heart</v-icon>
                    </div>
                    <div class="tit tit--sm">관심상품이 없습니다.</div>
                    <div class="txt txt--sm mt-8px">원하시는 상품을 담아주세요.</div>

                    <div class="btn-wrap btn-wrap--md">
                        <v-row justify="center" class="ma-n4px">
                            <v-col cols="12" md="auto" class="pa-4px">
                                <v-btn v-bind="{ ...btn_primary }" to="/shop" class="v-size--xx-large min-w-md-140px">쇼핑 계속하기</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapActions, mapMutations } from "vuex";
import { btn_primary, btn_secondary, btn_secondary2, btn_tertiary, attrs_input, checkbox_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";

import SelectAllBtn from "@/components/client/dumb/select-all-btn.vue";
import LikeProductItem from "@/components/client/mypage/likes/like-product-item.vue";
import RemoveTargetsBtn from "@/components/client/dumb/remove-targets-btn.vue";

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        UDialog,

        SelectAllBtn,
        LikeProductItem,
        RemoveTargetsBtn,
    },
    data() {
        return {
            btn_primary,
            btn_secondary,
            btn_secondary2,
            btn_tertiary,
            attrs_input,
            checkbox_primary,

            likes: [],

            limit: 9,
            summary: { totalCount: 0 },

            // selected: [],
            loading: false,
        };
    },
    computed: {
        items() {
            return [...this.likes].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query, withPopulate: true };
        },

        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                // { text: "관심상품", to: { path: "/mypage/likes" } },
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "관심상품" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        ...mapActions(["getLikes"]),
        ...mapMutations(["setLikes"]),
        async init() {
            try {
                // this.selected = [];
                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, likes } = await api.v1.me.likes.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.likes = likes;
                await this.getLikes();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async remove(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                await api.v1.me.likes.delete({ _id: item._id });

                this.likes = this.likes.filter(({ _id }) => _id != item?._id);
                this.summary.totalCount -= 1;

                this.setLikes({likes: this.likes});
                this.loading = false;
                if (!this.loading) this.search();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async removeAll(){
            if (this.loading) return;
            else this.loading = true;

            try {
                const likes = this.$store.state.likes;
                this.likes = [];
                this.setLikes({ likes: this.likes });
                
                await Promise.all(likes.map(async ({ _id }) => await api.v1.me.likes.delete({ _id })));
                this.loading = false;
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        }
    },
};
</script>

<style></style>
