<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="나의 상품후기">
            <div class="tabs-wrap tabs-wrap--lg">
                <v-tabs v-model="tabs" centered grow>
                    <tab-primary exact>작성 가능 후기</tab-primary>
                    <tab-primary exact>작성한 후기</tab-primary>
                </v-tabs>
            </div>

            <v-tabs-items v-model="tabs">
                <!-- S: 작성 가능 후기 -->
                <v-tab-item>
                    <reviewable-tab-item/>
                </v-tab-item>
                <!-- E: 작성 가능 후기 -->
                <!-- S: 작성한 후기 -->
                <v-tab-item>
                    <!-- S: PC -->
                    <v-data-table v-bind="{ headers, items, loading }" dense hide-default-footer disable-filtering disable-sort no-data-text="작성된 후기가 없습니다" mobile-breakpoint="0" class="v-data-table--default v-data-table--no-hover v-data-table--table-fixed d-none d-xl-block">
                        <template #body>
                            <tbody v-for="(item,index) in items" :key="index">
                                <!-- 반복 -->
                                <tr>
                                    <td style="border-bottom: 0" class="pl-0">
                                        <div class="overflow-hidden">
                                            <v-row align="center" class="ma-n6px text-left">
                                                <v-col cols="auto" class="pa-6px">
                                                    <v-card tile outlined class="overflow-hidden">
                                                        <router-link :to="`/shop/products/${item._product}`">
                                                            <v-img :src="item.product?.thumb?.url" :aspect-ratio="70 / 54" width="70">
                                                                <template #placeholder>
                                                                    <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                                </template>
                                                            </v-img>
                                                        </router-link>
                                                    </v-card>
                                                </v-col>
                                                <v-col class="pa-6px">
                                                    <div class="txt txt--sm txt--dark">
                                                        {{ item.product.name }}
                                                        <!-- <v-avatar size="12" color="secondary" tile class="font-size-10 white--text">N</v-avatar> -->
                                                    </div>
                                                    <div class="pt-8px">
                                                        <div class="txt txt--sm">{{ $decode__productOptionName(item?.purchase?.name) }} / 수량 : {{ item?. purchase.amount }}개</div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </td>
                                    <td style="border-bottom: 0" class="text-center">
                                        <v-rating v-model="item.star" v-bind="{ ...attrs_rating }" size="16" readonly empty-icon="mdi-star" background-color="grey lighten-3" />
                                    </td>
                                    <td style="border-bottom: 0" class="text-center">{{ item.createdAt.toDate() }}</td>
                                    <td style="border-bottom: 0" class="pr-0">
                                        <div class="overflow-hidden">
                                            <v-row class="ma-n4px" justify="end">
                                                <v-col cols="auto" class="pa-4px">
                                                    <v-btn v-bind="{ ...btn_secondary2 }" x-small :to="`./reviews-input/${item._id}`">수정</v-btn>
                                                </v-col>
                                                <v-col cols="auto" class="pa-4px">
                                                    <v-btn v-bind="{ ...btn_secondary2 }" x-small @click="remove(item)">삭제</v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="item._images">
                                    <td :colspan="4" style="padding: 0 0 16px; border-bottom: 0">
                                        <div class="overflow-hidden">
                                            <div class="d-flex flex-wrap">
                                                <!-- 반복 -->
                                                <div v-for="(image, index) in item.images" :key="index" class="pr-4px">
                                                    <v-card tile class="attachment-img">
                                                        <v-img :src="image?.url" :aspect-ratio="1 / 1" width="72">
                                                            <template #placeholder>
                                                                <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                            </template>
                                                        </v-img>
                                                    </v-card>
                                                </div>
                                                <!-- // 반복 -->
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td :colspan="4" style="padding: 0 0 12px">
                                        <div class="min-h-150px grey lighten-5 pa-18px">
                                            <div class="txt txt--sm txt--dark">
                                                {{ item?.content }}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <!-- // 반복 -->
                            </tbody>
                        </template>
                    </v-data-table>
                    <!-- E: PC -->
                    <!-- S: 모바일 -->
                    <div class="d-xl-none">
                        <!-- 반복 -->
                        <div v-for="(item,index) in items" :key="index" class="py-20px border-b">
                            <div class="pb-10px">
                                <v-row class="ma-n6px text-left">
                                    <v-col cols="auto" class="pa-6px">
                                        <v-card tile outlined class="overflow-hidden">
                                            <router-link :to="`/shop/products/${item._product}`">
                                                <v-img :src="item.product?.thumb?.url" :aspect-ratio="70 / 54" width="70">
                                                    <template #placeholder>
                                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                    </template>
                                                </v-img>
                                            </router-link>
                                        </v-card>
                                    </v-col>
                                    <v-col class="pa-6px">
                                        <div class="txt txt--sm txt--dark">
                                            {{ item.product.name }}
                                            <!-- <v-avatar size="12" color="secondary" tile class="font-size-10 white--text">N</v-avatar> -->
                                        </div>
                                        <div class="pt-4px">
                                            <div class="txt txt--sm">{{ $decode__productOptionName(item?.purchase?.name) }} / 수량 : {{ item?. purchase.amount }}개</div>
                                        </div>
                                        <div class="pt-4px">
                                            <v-row class="ma-n2px">
                                                <v-col cols="auto" class="pa-2px">
                                                    <v-rating v-model="item.star" v-bind="{ ...attrs_rating }" readonly size="16" empty-icon="mdi-star" background-color="grey lighten-3" />
                                                </v-col>
                                                <v-col cols="auto" class="pa-2px">
                                                    <div class="txt txt--xs">{{ item.createdAt.toDate() }}</div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-if="item._images" class="overflow-x-auto overflow-y-hidden mr-n12px pt-10px">
                                <div class="d-flex flex-nowrap pr-8px">
                                    <!-- 반복 -->
                                    <div v-for="(image, index) in item.images" :key="index" class="pr-4px">
                                        <v-card tile class="attachment-img">
                                            <v-img :src="image?.url" :aspect-ratio="1 / 1" width="72">
                                                <template #placeholder>
                                                    <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                </template>
                                            </v-img>
                                        </v-card>
                                    </div>
                                    <!-- // 반복 -->
                                </div>
                            </div>
                            <div class="min-h-150px grey lighten-5 pa-18px mt-10px">
                                <div class="txt txt--sm txt--dark"> {{ item?.content }} </div>
                            </div>
                            <div class="btn-wrap">
                                <v-row no-gutters>
                                    <v-col>
                                        <v-btn v-bind="{ ...btn_secondary2 }" x-small block :to="`./reviews-input/${item._id}`">수정</v-btn>
                                    </v-col>
                                    <v-col class="ml-n1px">
                                        <v-btn v-bind="{ ...btn_secondary2 }" x-small block @click="remove(item)">삭제</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                    <!-- // 반복 -->
                    <!-- E: 모바일 -->

                    <div class="pagination-wrap pagination-wrap--sm">
                        <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                    </div>
                </v-tab-item>
                <!-- E: 작성한 후기 -->
            </v-tabs-items>

            <!-- <div class="border-t border-2 border-dark">
                <template v-for="item in reviews">
                    <review-list-item :value="item" :key="item._id" v-on="{ search }" />
                </template>
            </div> -->
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_secondary2, attrs_rating } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
import ReviewableTabItem from "@/components/client/mypage/reviews/list/reviewable-tab-item.vue";
import ReviewListItem from "@/components/client/mypage/reviews/list/review-list-item.vue";

const headers = [
    { width: "68%", text: "제목", value: "subject", align: "center" },
    { width: "10%", text: "평가", value: "rating", align: "center" },
    { width: "10%", text: "날짜", value: "date", align: "center" },
    { width: "12%", text: "", value: "actions", align: "center" },
].map((item) => ({ ...item }));

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        PageSection,
        TabPrimary,

        ReviewListItem,
        ReviewableTabItem
    },
    data: () => ({
        btn_secondary2,
        attrs_rating,

        reviews: [],

        limit: 6,
        summary: { totalCount: 0 },

        loading: false,

        tabs: null,

        headers,
    }),
    computed: {
        items() {
            return [...this.reviews];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            return { ...query };
        },
        breadcrumbsItems() {
            const items = [
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "나의 상품후기" },
            ];
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.reviews = [];
            this.summary.totalCount = 0;
            this.loading = false;

            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, reviews } = await api.v1.me.reviews.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.reviews = reviews;
            } finally {
                this.loading = false;
            }
        },

        async remove(item){
            if (this.loading) return;
            else this.loading = true;

            try {
                if(confirm("해당 리뷰를 삭제하시겠습니까?")){
                    let { review } = await api.v1.me.reviews.delete({ _id: item._id });
                    
                    if(review._images.length) {
                        await Promise.all(
                            review._images.map(async(_image)=>{
                                await api.v1.me.reviews.images.delete({ _id: _image, _review: review._id });
                            })
                        )
                    }
                    this.loading = false;
                    this.search();
                };
            } finally {
                this.loading = false;
            }
        }
    },
};
</script>