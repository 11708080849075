var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('mypage-layout', {
    attrs: {
      "title": "마일리지"
    }
  }, [_c('point-head'), _c('page-section', {
    staticClass: "page-section--sm py-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('point-body', _vm._b({}, 'point-body', {
          items: _vm.items,
          loading: _vm.loading
        }, false)), _c('div', {
          staticClass: "py-6"
        }, [_c('v-pagination', {
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 11
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }