var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !!_vm.coupon.usage.endsAt ? _c('spec-row', _vm._b({
    scopedSlots: _vm._u([{
      key: "desc",
      fn: function () {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$dayjs(_vm.coupon.usage.endsAt).endOf("day").toISOString().toTil()) + " ")])];
      },
      proxy: true
    }], null, false, 1190001530)
  }, 'spec-row', {
    term: _vm.term
  }, false)) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }