<template>
    <v-btn-toggle v-model="dates" v-bind="{ ...$attrs }" color="primary" @change="emit">
        <template v-for="{ text, value } in computedItems">
            <v-btn :key="text" v-bind="{ ...$attrs, value }" height="40" color="white">{{ text }}</v-btn>
        </template>
    </v-btn-toggle>
</template>

<script>
export default {
    props: {
        value: { type: Array, default: () => [null, null] },
        items: {
            type: Array,
            default: () => [
                { text: "3개월", value: 3, unit: "month" },
                { text: "6개월", value: 6, unit: "month" },
                { text: "9개월", value: 9, unit: "month" },
                { text: "1년", value: 1, unit: "year" },
            ],
        },
    },
    data: () => ({
        dates: [null, null],
    }),
    computed: {
        computedItems() {
            return this.items.map(({ text, value, unit }) => ({
                text,
                value: [this.$dayjs().subtract(value, unit).startOf("day").format("YYYY-MM-DD"), this.$dayjs().endOf("day").format("YYYY-MM-DD")],
            }));
        },
    },
    methods: {
        sync() {
            this.dates = [...this.value];
        },
        emit() {
            this.$emit("input", this.dates || [null, null]);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>