var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-12px py-30px px-md-20px py-lg-80px",
    style: _vm.$vuetify.breakpoint.xs ? 'width:272px; margin:auto;' : ''
  }, [_c('v-row', {
    staticClass: "mx-n2px my-n12px mx-md-n14px",
    attrs: {
      "align-md": "center",
      "justify-sm": "center"
    }
  }, [_vm._l(_vm.items, function (_ref, index) {
    var text = _ref.text,
      value = _ref.value,
      code = _ref.code;
    return [index != 0 ? _c('v-col', {
      key: `divider-${index}`,
      staticClass: "px-2px py-12px px-md-14px",
      attrs: {
        "cols": "auto"
      }
    }, [_vm.$props.code == _vm.PURCHASE_QUERY_CODES.claimProcessGoing.value ? [_c('i', {
      staticClass: "icon icon-slash",
      style: _vm.$vuetify.breakpoint.smAndDown ? 'width:7px; height:12px; margin-top: 2px;' : ''
    })] : _vm._e(), _vm.$props.code == _vm.PURCHASE_QUERY_CODES.purchaseOnProcess.value ? [_c('i', {
      staticClass: "icon icon-chevron-right",
      style: _vm.$vuetify.breakpoint.smAndDown ? 'width:16px; height:16px; margin-top: 2px;' : ''
    })] : _vm._e()], 2) : _vm._e(), _c('v-col', {
      key: text,
      staticClass: "px-2px py-12px px-md-14px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', _vm._b({
      staticClass: "text-center grey--text",
      style: _vm.$vuetify.breakpoint.smAndDown ? 'width:60px;' : 'width:64px;',
      attrs: {
        "tile": "",
        "color": "transparent",
        "to": {
          query: {
            code
          }
        },
        "loader-height": "100%"
      }
    }, 'v-card', {
      loading: _vm.loading
    }, false), [_c('div', {
      staticClass: "font-size-18 font-size-md-40 font-weight-bold"
    }, [_vm._v(_vm._s(value || 0))]), _c('div', {
      staticClass: "font-size-12 font-size-md-14 mt-8px mt-md-6px",
      style: _vm.$vuetify.breakpoint.lgAndUp ? 'font-weight:700;' : ''
    }, [_vm._v(_vm._s(text))])])], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }