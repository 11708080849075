var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('mypage-layout', {
    attrs: {
      "title": "취소/환불"
    }
  }, [_c('list-search-default', _vm._b({}, 'list-search-default', {
    code: _vm.PURCHASE_QUERY_CODES.claimedWithCancel.value,
    loading: _vm.loading
  }, false)), _c('purchase-list', _vm._b({
    on: {
      "update:loading": function (bool) {
        return _vm.loading = bool;
      }
    }
  }, 'purchase-list', {
    code: _vm.code,
    loading: _vm.loading
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }