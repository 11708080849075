<template>
    <div>
        <div class="pb-30px pb-40px">
            <div class="txt pb-20px pb-md-30px">사용하고 계신 SNS 또는 외부 계정으로 간편하게 로그인하여 쇼핑몰을 이용하실 수 있습니다. 여러개를 함께 이용하실 수도 있습니다.</div>
            <div style="max-width: 416px">
                <v-row class="row--xxs">
                    <v-col cols="12" lg="6">
                        <kakao-login client_id="21f7a966f13bf90dc3f3c6e0401f4445" :disabled="!!form.kakao">카카오 로그인</kakao-login>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <naver-login client_id="U5mxgu3NbIYiYj7likp6" :disabled="!!form.naver">네이버 로그인</naver-login>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-form v-model="isValid" v-if="form">
            <div class="txt txt--xs text-right pb-16px pb-md-20px"><span class="red--text">• </span>필수입력 항목입니다.</div>
            <vertical-form-table v-model="form" v-bind="{ items }" class="vertical-form-table--border">
                <template slot="아이디">
                    {{ form?.username }}
                </template>
                <template slot="비밀번호 확인">
                    <v-password-confirm-field :password="form.password" dense placeholder="동일한 비밀번호를 재입력하세요." isModify class="w-md-358px" />
                </template>
                <template slot="마케팅 및 광고 활용 동의">
                    <v-row class="row--xs">
                        <v-col cols="auto">
                            <v-checkbox v-model="form.meta.isReceiveEmail" v-bind="{ ...checkbox_primary }" label="이메일수신" />
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox v-model="form.meta.isReceiveSMS" v-bind="{ ...checkbox_primary }" label="SMS 수신" />
                        </v-col>
                    </v-row>
                </template>
                <template slot="주소">
                    <v-address-field v-model="form" outlined persistent-placeholder :btnAttrs="{ large: true, color: 'primary' }" />
                </template>
                <template slot="성별">
                    <v-radio-group v-model="form.gender" row hide-details="auto">
                        <v-radio v-bind="{ ...radio_primary }" label="남성" value="MALE"/>
                        <v-radio v-bind="{ ...radio_primary }" label="여성" value="FEMALE" />
                    </v-radio-group>
                </template>
                <template slot="휴대폰번호">
                    <myinfo-phone-field v-model="form.phone" :type="form.type"  dense outlined hasCertification @input="certify"/>
                </template>

                <!-- <template slot="추천인코드">
                    {{ form?.code_promoter?.code }}
                </template> -->
            </vertical-form-table>
            <div class="btn-wrap btn-wrap--lg mx-n12px mx-md-0px">
                <v-row justify="center" class="ma-0px ma-md-n4px">
                    <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                        <v-btn v-bind="{ ...btn_primary, disabled, loading }" class="v-size--xx-large w-100 min-w-md-140px" @click="emit">수정</v-btn>
                    </v-col>
                    <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                        <v-btn v-bind="{ ...btn_secondary, loading }" class="v-size--xx-large min-w-md-140px d-none d-md-block" @click="$emit('cancel')">취소</v-btn>
                        <v-btn v-bind="{ ...btn_primary3, loading }" class="v-size--xx-large w-100 d-md-none" @click="$emit('cancel')">취소</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-form>
    </div>
</template>

<script>
import { initUser, rules } from '@/assets/variables';
import { btn_primary, btn_primary3, btn_secondary, checkbox_primary, radio_primary, attrs_input } from "@/assets/variables";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import MyinfoPhoneField from "./myinfo-phone-field.vue"
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import KakaoLogin from "@/components/plugins/kakao/kakao-login.vue";
import NaverLogin from "@/components/plugins/naver/naver-login.vue";

const items = [
    {
        term: "아이디",
    },
    {
        key: "password",
        term: "비밀번호",
        type: "password",
        placeholder: "비밀번호 변경 시 새로운 비밀번호를 입력하세요",
        rules: rules.passwordModify,
    },
    {
        key: "password",
        term: "비밀번호 확인",
        placeholder: "동일한 비밀번호를 재입력하세요.",
    },
    {
        key: "name",
        term: "이름",
        type: "text",
        rules: rules.name,
        required: true,
        disabled: true,
        placeholder: "이름을 입력하세요.",
        class: "w-md-358px",
    },
    {
        key: "email",
        term: "이메일",
        type: "email",
        required: true,
        placeholder: "이메일을 입력하세요.",
        rules: rules.email,
    },
    {
        // key: "phone",
        term: "휴대폰번호",
        // type: "phone",
        // required: true,
        // disabled: true,
        // hasCertification: true,
    },
    {
        term: "마케팅 및 광고 활용 동의",
    },
    {
        term: "주소",
        required: true,
    },
    {
        key: "nickname",
        term: "닉네임",
        type: "text",
        required: true,
        class: "w-md-358px",
        rules: rules.nickname,
    },
    {
        key: "birthday",
        term: "생년일일",
        type: "date",
        // rules: rules.birthday,
        required: true,
        disabled: true,
        ...attrs_input,
    },
    {
        key: "gender",
        term: "성별",
        required: true,
        rules: rules.gender,
        ...attrs_input
    },
].map((item) => ({ ...item, dense: true, outlined: true }));

export default {
    components:{
        KakaoLogin,
        NaverLogin,
        VAddressField,
        VerticalFormTable,
        VPasswordConfirmField,
        MyinfoPhoneField
    },
    props: {
        value: { type: Object, default: initUser }, //form
        loading: { type: Boolean, default: false }
    },
    data: () => ({
        btn_primary,
        btn_primary3,
        btn_secondary,
        checkbox_primary,
        radio_primary,
        attrs_input,
        items,

        form: initUser(),
        isValid: false,
    }),
    computed: {
        disabled() {
            return !this.isValid;
        },
    },
    methods: {
        init(){
            this.sync();
        },
        sync() {
            this.form = initUser(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
        certify(value){
            this.form = initUser({
                ...this.form,
                ...value,
                kakao: null,
                naver: null,
                apple: null,
            });
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        }
    }
}
</script>

<style>

</style>