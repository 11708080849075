var render = function render(){
  var _vm$value, _vm$value$vbank, _vm$value2, _vm$value2$vbank, _vm$value3, _vm$value3$vbank, _vm$value4, _vm$value5, _vm$value5$vbank;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "persistent": "",
      "width": "350",
      "max-width": "100%",
      "title": "가상계좌"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    staticClass: "text-start",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "font-size-md-16 grey--text text--darken-4"
  }, [_vm._v("입금계좌 : " + _vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$vbank = _vm$value.vbank) === null || _vm$value$vbank === void 0 ? void 0 : _vm$value$vbank.vbankNumber))])]), _c('v-col', {
    staticClass: "text-start",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "font-size-md-16 grey--text text--darken-4"
  }, [_vm._v("예금주 : " + _vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$vbank = _vm$value2.vbank) === null || _vm$value2$vbank === void 0 ? void 0 : _vm$value2$vbank.vbankHolder))])]), _c('v-col', {
    staticClass: "text-start",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "font-size-md-16 grey--text text--darken-4"
  }, [_vm._v("은행명 : " + _vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : (_vm$value3$vbank = _vm$value3.vbank) === null || _vm$value3$vbank === void 0 ? void 0 : _vm$value3$vbank.vbankName))])]), _c('v-col', {
    staticClass: "text-start",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "font-size-md-16 grey--text text--darken-4"
  }, [_vm._v("금액 : " + _vm._s((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : _vm$value4.totalPrice) + "원")])]), _c('v-col', {
    staticClass: "text-start",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "font-size-md-16 grey--text text--darken-4"
  }, [_vm._v("입금 만료일 : " + _vm._s((_vm$value5 = _vm.value) === null || _vm$value5 === void 0 ? void 0 : (_vm$value5$vbank = _vm$value5.vbank) === null || _vm$value5$vbank === void 0 ? void 0 : _vm$value5$vbank.vbankExpDate.toDate()))])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }