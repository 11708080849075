<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout>
            <dashboard-header />

            <div class="pb-14px pb-md-20px">
                <v-row align="center" justify="space-between" justify-md="end" class="row--xs">
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_txt }" small to="./destinations"><span class="font-size-14">배송지 관리</span> <i class="icon icon-pin ml-4px" /></v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_txt }" small to="./orders"><span class="font-size-14">전체주문 조회</span> <v-icon size="16" class="ml-4px">mdi-chevron-right</v-icon></v-btn>
                    </v-col>
                </v-row>
            </div>

            <v-tabs v-model="purchaseTab" centered grow>
                <tab-primary :tab-value="PURCHASE_QUERY_CODES.purchaseOnProcess.value">최근 주문현황</tab-primary>
                <tab-primary :tab-value="PURCHASE_QUERY_CODES.claimProcessGoing.value">반품/교환/취소/환불</tab-primary>
            </v-tabs>
            <v-tabs-items v-model="purchaseTab">
                <v-tab-item class="fill-height" :value="PURCHASE_QUERY_CODES.purchaseOnProcess.value">
                    <mypage-dashboard-recent v-bind="{ active: purchaseTab == PURCHASE_QUERY_CODES.purchaseOnProcess.value }" />
                </v-tab-item>
                <v-tab-item class="fill-height" :value="PURCHASE_QUERY_CODES.claimProcessGoing.value">
                    <mypage-dashboard-return v-bind="{ active: purchaseTab == PURCHASE_QUERY_CODES.claimProcessGoing.value }" />
                </v-tab-item>
            </v-tabs-items>
        </mypage-layout>
    </client-page>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { btn_txt, PURCHASE_QUERY_CODES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";

import DashboardHeader from "@/components/client/mypage/dashboard/dashboard-header.vue";
import MypageDashboardRecent from "@/components/client/mypage/dashboard/mypage-dashboard-recent.vue";
import MypageDashboardReturn from "@/components/client/mypage/dashboard/mypage-dashboard-return.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        SubVisualPrimary,
        TabPrimary,
        TitWrapLine,

        DashboardHeader,
        MypageDashboardRecent,
        MypageDashboardReturn,
    },
    data: () => ({
        purchaseTab: null,

        PURCHASE_QUERY_CODES,

        btn_txt,
    }),
    computed: {
        ...mapState(["user"]),

        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                { text: "HOME" },
                { text: "마이페이지" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            this.getUser();
            this.replacePath();
        },
        replacePath() {
            let path = "/mypage/dashboard";
            if (!this.$route.path.includes(path)) this.$router.replace({ path });
        },
    },
    watch: {
        purchaseTab() {
            if (this.$route.query.code) {
                this.$router.replace({ query: {} });
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style></style>
