var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "contents-bottom-md-none",
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('mypage-layout', {
    attrs: {
      "title": "회원탈퇴"
    }
  }, [_c('v-form', [_vm.leave === 'main' ? _c('div', [_c('div', {
    staticClass: "txt line-height-15"
  }, [_c('p', [_vm._v(" 회원탈퇴란, 사이트에 대한 이용해지를 의미합니다. "), _c('br'), _vm._v(" 발송하는 광고성 이메일의 경우 회원탈퇴 요청 접수 후 24시간 이내에 발송이 중지됩니다."), _c('br'), _vm._v(" 다만, 드물게 데이터 처리사정상 일부 지연될 수 있으니 혹 회원탈퇴 요청 후 48시간이 지난 후에 광고성 이메일/SMS를 접수하시는 경우 당사에 연락하여 주시기 바랍니다. ")]), _c('p', [_c('b', [_vm._v("회원탈퇴 시 유의사항")]), _c('br'), _vm._v(" 회원탈퇴 시 보유하고 계신 쿠폰 및 적립금은 즉시 소멸되며 동일한 아이디로 재가입 하더라도 복원되지 않습니다. ")]), _c('p', [_c('b', [_vm._v("탈퇴회원 회원정보 보전기간")]), _c('br'), _vm._v(" - 회원탈퇴가 완료되더라도 판/구매자의 권익을 보호하기 위해 다음과 같이 회원정보가 일정기간 보존됨을 알려드립니다."), _c('br'), _vm._v(" - 관계 법령에 의거하여 보존이 필요한 경우에 한하여 보존됩니다."), _c('br'), _vm._v(" 1) 계약 및 청약철회 등에 관한기록 : 5년"), _c('br'), _vm._v(" 2) 대금결제 및 재화등의 공급에 관한 기록 : 5년"), _c('br'), _vm._v(" 3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년"), _c('br'), _vm._v(" 4) 부정이용 등에 관한 기록 : 1년 ")])])]) : _vm._e(), _vm.leave === 'sub' ? _c('div', [_c('vertical-form-table', _vm._b({
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false), [_c('template', {
    slot: "탈퇴사유"
  }), _c('template', {
    slot: "남기실 말씀"
  })], 2)], 1) : _vm._e(), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg mx-n12px mx-md-0px"
  }, [_c('v-row', {
    staticClass: "ma-0px ma-md-n4px",
    attrs: {
      "justify": "center"
    }
  }, [_vm.leave === 'main' ? _c('v-col', {
    staticClass: "pa-0px pa-md-4px",
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-md-140px",
    on: {
      "click": function ($event) {
        _vm.leave = 'sub';
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("탈퇴신청")])], 1) : _vm._e(), _vm.leave === 'sub' ? _c('v-col', {
    staticClass: "pa-0px pa-md-4px",
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-md-140px",
    on: {
      "click": _vm.withdraw
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("탈퇴신청")])], 1) : _vm._e(), _vm.leave === 'sub' ? _c('v-col', {
    staticClass: "pa-0px pa-md-4px",
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large min-w-md-140px d-none d-md-block",
    on: {
      "click": function ($event) {
        _vm.leave = 'main';
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("취소")]), _c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 d-md-none",
    on: {
      "click": function ($event) {
        _vm.leave = 'main';
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary3), false), [_vm._v("취소")])], 1) : _vm._e()], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }