var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pt-14px px-lg-20px txt txt--sm"
  }, [_c('v-row', {
    staticClass: "ma-n2px"
  }, [_c('spec-price', _vm._b({}, 'spec-price', {
    coupon: _vm.coupon
  }, false)), _c('spec-row', {
    scopedSlots: _vm._u([{
      key: "term",
      fn: function () {
        return [_vm._v(" 발급일 ")];
      },
      proxy: true
    }, {
      key: "desc",
      fn: function () {
        var _vm$coupon;
        return [_vm._v(" " + _vm._s(_vm.$dayjs((_vm$coupon = _vm.coupon) === null || _vm$coupon === void 0 ? void 0 : _vm$coupon.createdAt).format("YYYY.MM.DD")) + " ")];
      },
      proxy: true
    }])
  }), _c('spec-period', _vm._b({}, 'spec-period', {
    coupon: _vm.coupon
  }, false)), _c('spec-remain', _vm._b({}, 'spec-remain', {
    coupon: _vm.coupon
  }, false)), _c('spec-state', _vm._b({}, 'spec-state', {
    coupon: _vm.coupon
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }