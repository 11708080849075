var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('spec-row', _vm._b({
    scopedSlots: _vm._u([{
      key: "desc",
      fn: function () {
        return [_c('span', [_vm._v(_vm._s(_vm.coupon.value.format()) + _vm._s(_vm.unitText) + " 할인")]), _vm.coupon.limit ? _c('span', [_vm._v(", 최대 " + _vm._s(_vm.coupon.limit.format()) + "원 ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, 'spec-row', {
    term: _vm.term
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }