var render = function render(){
  var _vm$$attrs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    class: _vm.innerClass
  }, [_c('v-row', _vm._b({
    class: _vm.rowSize
  }, 'v-row', (_vm$$attrs = _vm.$attrs) === null || _vm$$attrs === void 0 ? void 0 : _vm$$attrs.rowProps, false), [this.$slots['tit'] ? _c('v-col', {
    class: _vm.titClass,
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "txt font-weight-bold",
    class: [_vm.titSize, _vm.titColor, _vm.titContentClass],
    style: _vm.titContentStyle
  }, [_vm._t("tit")], 2)]) : _vm._e(), this.$slots['txt'] ? _c('v-col', {
    class: _vm.txtClass,
    attrs: {
      "cols": "12",
      "xl": ""
    }
  }, [_c('div', {
    staticClass: "txt",
    class: [_vm.txtSize, _vm.txtColor, _vm.txtContentClass]
  }, [_vm._t("txt")], 2)]) : _c('v-col', {
    class: _vm.contentClass
  }, [_vm._t("default")], 2)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }