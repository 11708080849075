var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('div', {
    staticClass: "pb-16px pt-md-10px pb-md-30px"
  }, [_c('div', {
    staticClass: "py-16px py-md-20px"
  }, [_c('v-row', {
    staticClass: "ma-n8px",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', [_c('list-item-purchase', _vm._b({}, 'list-item-purchase', _vm.value, false))], 1), _c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "align-self": "start",
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs txt--light"
  }, [_vm._v(_vm._s(_vm.value.createdAt.toDate()))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "mw-100 flex-grow-1 flex-md-grow-0 flex-shrink-0",
    attrs: {
      "cols": "4",
      "md": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-small",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.remove
    }
  }, 'v-btn', Object.assign({}, _vm.btn_tertiary), false), [_vm._v("삭제")])], 1), _c('v-col', {
    staticClass: "mw-100 flex-grow-1 flex-md-grow-0 flex-shrink-0",
    attrs: {
      "cols": "4",
      "md": "auto"
    }
  }, [_c('review-form', _vm._b({
    on: {
      "input": function ($event) {
        return _vm.$emit('search');
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-small",
          attrs: {
            "block": ""
          }
        }, 'v-btn', Object.assign({}, _vm.btn_tertiary, attrs), false), on), [_vm._v(" 수정 ")])];
      }
    }])
  }, 'review-form', {
    value: _vm.value,
    purchase: _vm.value.purchase
  }, false))], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "border-dark",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', [_vm.src ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto",
      "order-md": "2"
    }
  }, [_c('v-card', [_c('v-img', _vm._b({
    attrs: {
      "width": "64",
      "height": "64"
    }
  }, 'v-img', {
    src: _vm.src
  }, false))], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "mb-4px mb-md-8px"
  }, [_c('v-rating', {
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": _vm.value.star
    }
  })], 1), _c('div', {
    staticClass: "txt txt--xs",
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.content) + " ")])])], 1)], 1)])], 1), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }