<template>
    <div class="pt-14px px-lg-20px txt txt--sm">
        <v-row class="ma-n2px">
            <spec-price v-bind="{ coupon }" />
            <spec-row>
                <template #term> 발급일 </template>
                <template #desc> {{ $dayjs(coupon?.createdAt).format("YYYY.MM.DD") }} </template>
            </spec-row>
            <spec-period v-bind="{ coupon }" />
            <spec-remain v-bind="{ coupon }" />
            <!-- <v-col cols="12" class="pa-2px">
                <v-row no-gutters>
                    <v-col cols="auto">
                        <div class="w-70px">제한금액</div>
                    </v-col>
                    <v-col> : 100원 이상 구매 시 </v-col>
                </v-row>
            </v-col> // 미개발 -->
            <spec-state v-bind="{ coupon }" />
            <!-- <v-col cols="12" class="pa-2px d-none d-md-block">
                <v-row align="center" no-gutters>
                    <v-col cols="auto">
                        <div class="w-70px">적용대상</div>
                    </v-col>
                    <v-col>
                        <v-btn v-bind="{ ...btn_secondary2 }" width="56" height="24px !important" class="v-size--xx-small">조회</v-btn>
                    </v-col>
                </v-row>
            </v-col> -->
        </v-row>
        <!-- <div class="pt-12px d-md-none">
            <v-btn v-bind="{ ...btn_secondary2 }" x-small block>적용대상 조회</v-btn>
        </div> -->
    </div>
</template>

<script>
import { btn_secondary2 } from "@/assets/variables/attrs";
import { initUserCoupon } from "@/assets/variables/inits";

import SpecPrice from "./spec/spec-price.vue";
import SpecRow from "./spec/spec-row.vue";
import SpecPeriod from "./spec/spec-period.vue";
import SpecRemain from "./spec/spec-remain.vue";
import SpecState from "./spec/spec-state.vue";

export default {
    components: {
        SpecPrice,
        SpecRow,
        SpecPeriod,
        SpecRemain,
        SpecState,
    },
    props: {
        coupon: { type: Object, default: initUserCoupon },
    },
    setup: () => ({
        btn_secondary2,
    }),
};
</script>

<style>
</style>