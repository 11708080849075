<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="주문/배송">
            <list-search-default v-bind="{ loading }" />
            <purchase-list v-bind="{ code, loading }" @update:loading="(bool) => (loading = bool)" />
        </mypage-layout>
    </client-page>
</template>

<script>
import { PURCHASE_QUERY_CODES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";

import PurchaseList from "@/components/client/mypage/purchase/purchase-list.vue";
import ListSearchDefault from "@/components/client/mypage/purchase/list/list-search-default.vue";

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,

        PurchaseList,
        ListSearchDefault,
    },
    data: () => ({
        code: PURCHASE_QUERY_CODES.purchaseOnProcess.value,

        loading: false,
    }),
    computed: {
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                // { text: "주문/배송", to: { path: "/mypage/orders" } },
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "주문/배송" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
    methods: {
        sync() {
            this.code = this.$route.query.code || PURCHASE_QUERY_CODES.purchaseOnProcess.value;
        },
    },
    watch: {
        active() {
            this.sync();
        },
        "$route.query.code"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>
