<template>
    <client-page class="contents-bottom-md-none">
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="나의 상품후기" v-if="form.product">
            <div>
                <vertical-form-table v-bind="{ items }" topBorderMdNone class="vertical-form-table--border">
                    <template #작성자>
                        <v-text-field v-model="username" disabled v-bind="{ ...attrs_input, loading }" dense class="w-md-358px" />
                    </template>
                    <template #상품>
                        <v-row align="center" class="ma-n6px">
                            <v-col cols="auto" class="pa-6px">
                                <v-card tile outlined class="overflow-hidden">
                                    <router-link :to="`/shop/products/${form.product._id}`">
                                        <v-img :src="form.product?.thumb?.url" :aspect-ratio="70 / 54" width="70">
                                            <template #placeholder>
                                                <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                            </template>
                                        </v-img>
                                    </router-link>
                                </v-card>
                            </v-col>
                            <v-col class="pa-6px">
                                <div class="txt txt--sm txt--dark pb-2px">{{ form.product.name }}</div>
                                <div class="txt txt--sm txt--dark">{{ form.product.salePrice }}원</div>
                            </v-col>
                        </v-row>
                    </template>
                    <template #평점>
                        <v-row align="center" class="ma-n4px">
                            <!-- <v-col cols="auto" class="pa-4px">
                                <v-btn icon outlined color="primary" rounded="sm" width="18px !important" height="18px !important"><v-icon size="small">mdi-minus</v-icon></v-btn>
                            </v-col> -->
                            <v-col cols="auto" class="pa-4px">
                                <v-rating v-model="form.star" v-bind="{ ...attrs_rating }" half-increments size="22" empty-icon="mdi-star" background-color="grey lighten-3" />
                            </v-col>
                            <!-- <v-col cols="auto" class="pa-4px">
                                <v-btn icon outlined color="primary" rounded="sm" width="18px !important" height="18px !important"><v-icon size="small">mdi-plus</v-icon></v-btn>
                            </v-col> -->
                        </v-row>
                    </template>
                </vertical-form-table>
                <div class="py-12px py-md-20px">
                    <div class="txt txt--dark font-weight-medium pb-8px d-md-none">내용</div>
                    <v-textarea v-model="form.content" v-bind="{ ...attrs_input, loading }" rows="14" placeholder="내용을 입력해주세요." />
                </div>
                <v-divider class="border-dark d-none d-md-block" style="border-width: 2px 0 0" />
                <!-- S: 첨부파일 추가(모바일) -->
                <div class="py-12px d-md-none">
                    <div class="txt txt--dark font-weight-medium pb-4px">첨부파일 추가</div>
                    <div class="overflow-x-auto overflow-y-hidden mr-n12px pt-4px">
                        <div class="d-flex flex-nowrap pr-8px">
                            <div class="pr-4px">
                                <v-btn tile width="72" height="72px !important" color="grey lighten-5" @click="upload">
                                    <i class="icon icon-image" />
                                </v-btn>
                                <input type="file" id="fileUpload" hidden multiple @change="handleFiles" accept="image/*">
                            </div>
                            <!-- 반복 -->
                            <div v-for="(preivew, index) in previews" :key="index" class="pr-4px">
                                <v-card tile class="attachment-img">
                                    <v-btn fab color="primary" class="attachment-img__close"><v-icon x-small color="white" @click="close(index)">mdi-close</v-icon></v-btn>
                                    <v-img :src="preivew" :aspect-ratio="1 / 1" width="72">
                                        <template #placeholder>
                                            <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                        </template>
                                    </v-img>
                                </v-card>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- E: 첨부파일 추가(모바일) -->

                <div class="btn-wrap mx-n12px mx-md-0px">
                    <v-row justify="space-between" class="ma-0px ma-md-n4px">
                        <!-- S: 첨부파일 추가(PC) -->
                        <v-col cols="6" md="" class="d-none d-md-block">
                            <v-btn v-bind="{ ...btn_secondary, loading }" class="v-size--xx-large w-100 w-md-140px" @click="upload">이미지 추가</v-btn>
                            <input type="file" id="fileUpload" hidden multiple @change="handleFiles" accept="image/*">
                            <div class="pt-16px">
                                <v-row class="row--xxs">
                                    <v-col cols="auto">
                                        <v-row align="center" class="row--xxs">
                                            <!-- 대표이미지 class="thumb" -->
                                            <v-col v-for="(preivew, index) in previews" :key="index" cols="auto">
                                                <v-card tile class="attachment-img">
                                                    <v-btn fab color="primary" class="attachment-img__close"><v-icon x-small color="white" @click="close(index)">mdi-close</v-icon></v-btn>
                                                    <v-img :src="preivew" :aspect-ratio="1 / 1" width="72">
                                                        <template #placeholder>
                                                            <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                        </template>
                                                    </v-img>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <!-- E: 첨부파일 추가(PC) -->
                        <v-col cols="12" md="auto" class="pa-0px pa-md-4px">
                            <v-row class="ma-0px ma-md-n4px">
                                <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                                    <v-btn v-bind="{ ...btn_primary, loading }" class="v-size--xx-large w-100 min-w-md-140px" @click="save">저장</v-btn>
                                </v-col>
                                <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                                    <v-btn v-bind="{ ...btn_secondary, loading }" class="v-size--xx-large min-w-md-140px d-none d-md-block" @click="$router.go(-1)">취소</v-btn>
                                    <v-btn v-bind="{ ...btn_primary3, loading }" class="v-size--xx-large w-100 d-md-none" @click="$router.go(-1)">취소</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { checkForBannedKeywords } from '@/plugins/vue-filter-contents';
import { btn_primary, btn_primary3, btn_secondary, attrs_input, attrs_rating, initShopReview } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import { mapActions } from 'vuex';

const items = [
    {
        term: "작성자",
        type: "text",
        class: "w-md-358px",
    },
    {
        term: "상품",
    },
    {
        term: "평점",
    },
].map((item) => ({ ...item, dense: true, outlined: true }));

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        PageSection,
        TitWrapLine,
        VerticalFormTable,
    },
    data: () => ({
        btn_primary,
        btn_primary3,
        btn_secondary,
        attrs_input,
        attrs_rating,

        items,
        loading: false,

        review: null,
        username: null,

        form: initShopReview(),
        previews: []
    }),
    computed: {
        isCreate() {
            return !this.form?._id;
        },
        breadcrumbsItems() {
            const items = [
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "나의 상품후기" },
            ];
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            await this.getUser();
            this.username = this.$store.state.user.username;
    
            this.form = initShopReview({
                _product: this.$route.query._product,
                _purchase: this.$route.query._purchase,
            });
            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if(this.$route.params._review){
                    let { review } = await api.v1.me.reviews.get({ _id: this.$route.params._review });

                    this.review = review;
                    this.form = initShopReview(review);
                    this.previews = this.form.images.map((file)=> file.src);
                } else{
                    let { product } = await api.v1.shop.products.get({_id: this.$route.query._product });
    
                    this.form.product= product;
                    this.form._seller= product._seller;
                }
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async save(){
            if (this.loading) return;
            else this.loading = true;

            try {
                if(!this.validate()) return;

                let { post, put } = api.v1.me.reviews;
                let { _images = [], images= [], ...form } = this.form;
                
                let { review } = await (this.isCreate ? post : put)(form);

                let imgArr= [];
                
                // 포인트 적립을 위해 하나씩 post
                for(let i of images){
                    if(i instanceof File) i = (await api.v1.me.reviews.images.post({ _review: review._id }, i))?.image;
                    imgArr.push(i?._id);
                };

                // await Promise.all(
                //     images.map(async(image, index)=>{
                //         if(image instanceof File) image = (await api.v1.me.reviews.images.post({ _review: review._id, index }, image))?.image;

                //         imgArr.push(image?._id);
                //     })
                // );

                _images = _images.filter((_image)=> !imgArr.includes(_image));
                
                if(_images.length){
                    await Promise.all(_images.map(async (_id) => await api.v1.me.reviews.images.delete({ _id, _review: review._id })));
                };

                images = (await put({ _id: review._id, _images: imgArr })).images;

                alert("리뷰가 등록되었습니다.");
                this.$router.go(-1);
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validate(){
            if(!this.form.star) throw new Error("별점을 선택해주세요.");
            if(!this.form.content) throw new Error("리뷰를 작성해주세요.");

            checkForBannedKeywords(this.form.content, this.$store.state.setting.bannedKeywords);
            return true;
        },

        //File
        upload() {
            document.getElementById("fileUpload").click();
        },
        createURL(files){
            this.previews = files.map((file) => URL.createObjectURL(file));
        },
        handleFiles(e){
            let files = [...e.target.files];
            if(!this.validateFile(files)) return;

            this.form.images = [...e.target.files];
            this.createURL(this.form.images);
        },
        validateFile(files){
            files.forEach((file)=> {
                if(!file.type.includes("image")) alert("이미지 파일만 업로드 가능합니다.");
                return false;
            });

            if(files.length > 10) {
                alert("이미지는 최대 10장까지 업로드 가능합니다.");
                return false;
            }
            return true;
        },
        close(index){
            this.form.images.splice(index, 1);
            this.previews.splice(index, 1);
        }
    }
};
</script>

<style lang="scss" scoped></style>
