<template>
    <u-dialog v-model="shows" persistent width="1200" max-width="100%" title="주문상세">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-row class="row--sm">
            <v-col cols="12">
                <tit-wrap-line noDivider>
                    <v-row align="center" class="ma-n2px ma-sm-n6px">
                        <v-col cols="12" sm="auto" class="pa-2px pa-sm-6px">
                            <span class="tit tit--xxs">{{ value?.createdAt?.toDate?.() }}</span>
                        </v-col>
                        <v-col cols="12" sm="auto" class="pa-2px pa-sm-6px">
                            <span class="txt txt--sm"> 주문번호 : {{ value?.orderNo }} </span>
                        </v-col>
                    </v-row>
                </tit-wrap-line>

                <order-view-purchases v-bind="form" />
            </v-col>
            <!-- S: 배송지정보 -->
            <v-col cols="12" sm="6">
                <page-section class="page-section--xs py-0">
                    <template v-slot:containerImmersive>
                        <order-view-receiver v-model="form" @input="init" />
                    </template>
                </page-section>
            </v-col>
            <!-- E: 배송지정보 -->

            <!-- S: 결제정보 -->
            <v-col cols="12" sm="6">
                <page-section class="page-section--xs py-0">
                    <template v-slot:containerImmersive>
                        <order-view-payment v-model="form" />
                    </template>
                </page-section>
            </v-col>
            <!-- E: 결제정보 -->
        </v-row>
    </u-dialog>
</template>

<script>
import api from "@/api";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import OrderViewPayment from "./order-view-payment.vue";
import OrderViewReceiver from "./order-view-receiver.vue";
import OrderViewPurchases from "./order-view-purchases.vue";

export default {
    components: {
        PageSection,
        UDialog,
        TitWrapLine,
        OrderViewPayment,
        OrderViewReceiver,
        OrderViewPurchases,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // order
    },
    data: () => ({
        form: undefined,

        // ui
        shows: false,
        loading: false,
    }),
    watch: {
        shows(shows) {
            if (shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            this.loading = true;

            try {
                this.form = (await api.v1.me.orders.get(this.value))?.order || {};
                // this.emit();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        emit() {
            this.$emit("input", this.form);
        },

        close() {
            this.shows = false;
        },
    },
};
</script>

<style></style>
