var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('mypage-layout', {
    attrs: {
      "title": "취소/환불 상세"
    }
  }, [_c('tit-wrap-line', {
    staticClass: "d-xl-none",
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "ma-n2px ma-sm-n6px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2px pa-sm-6px",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 상품정보 ")])])], 1)], 1), _c('v-divider', {
    staticClass: "border-dark d-none d-xl-block",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "pt-xl-16px mb-xl-n4px"
  }, [_c('tit-wrap-line', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col'), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-60px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("수량")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("상품금액")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-60px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("취소수량")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("주문상태")])])])], 1)], 1)], 1), _c('v-divider', {
    class: _vm.$vuetify.breakpoint.xl ? '' : 'border-dark'
  }), _c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "py-20px py-xl-10px"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align-xl": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', [_vm._v("상품주문번호 : 202401020001_001")])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "12",
      "xl": ""
    }
  }, [_c('div', {
    staticClass: "d-xl-none"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-xl-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-xl-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": "104"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-xl-12px"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v("볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)")])]), _c('div', {
    staticClass: "txt txt--sm txt--dark mt-4px"
  }, [_vm._v("1,650,000원")])], 1)], 1)], 1), _c('div', {
    staticClass: "d-none d-xl-block"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": "130"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px"
  }, [_c('div', {
    staticClass: "txt txt--sm mb-4px mb-md-8px mb-lg-14px"
  }, [_c('span', {
    staticClass: "pr-6px"
  }, [_vm._v("결제완료")]), _c('span', [_vm._v("상품주문번호 : 202401020001_001")])]), _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v("볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)")])]), _c('div', {
    staticClass: "txt txt--sm mt-4px mt-md-8px"
  }, [_vm._v("색상 : 블랙 / 수량 : 1개")])], 1)], 1)], 1)]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-60px text-xl-center d-none d-xl-block"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("수량 : ")]), _c('span', [_vm._v("1")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "6",
      "md": "4",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("상품금액 : ")]), _c('span', [_vm._v("1,650,000원")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-60px text-xl-center d-none d-xl-block"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("취소수량 : ")]), _c('span', [_vm._v("1")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center d-none d-xl-block"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("주문상태 : ")]), _c('span', [_vm._v("상품준비중")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "text-center grey lighten-5"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', [_vm._v("색상 : 블랙")]), _c('span', {
    staticClass: "px-4px"
  }, [_vm._v("/")]), _c('span', [_vm._v("수량 : 1")])])]), _c('v-col', {
    staticClass: "ml-n1px",
    attrs: {
      "cols": "auto",
      "align-self": "stretch"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', [_vm._v("취소수량 : ")]), _c('span', [_vm._v("1")])])]), _c('v-col', {
    staticClass: "ml-n1px",
    attrs: {
      "cols": "auto",
      "align-self": "stretch"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', [_vm._v("주문상태 : ")]), _c('span', [_vm._v("상품준비중")])])])], 1)], 1)])], 1)], 1), _c('v-divider')], 1), _c('v-row', {
    class: _vm.$vuetify.breakpoint.xl ? 'row--lg' : 'no-gutters'
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("환불처리")])]), _c('v-data-table', {
          staticClass: "v-data-table--default v-data-table--no-hover v-data-table--md v-data-table--min-w-500px d-none d-lg-block",
          attrs: {
            "hide-default-footer": "",
            "disable-filtering": "",
            "disable-pagination": "",
            "disable-sort": "",
            "mobile-breakpoint": "0"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "width": "16%"
                }
              }, [_vm._v("환불상태")]), _c('td', {
                staticStyle: {
                  "width": "84%"
                }
              }, [_vm._v("환불신청")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("환불번호")]), _c('td', [_vm._v("C23234222")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("환불종류")]), _c('td', [_vm._v("결제취소 환불")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("환불 접수일")]), _c('td', [_vm._v("2023년 12월14일")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("환불 완료일")]), _c('td')])])];
            },
            proxy: true
          }])
        }), _c('u-vertical-items', {
          staticClass: "d-lg-none"
        }, [_c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 환불상태 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 환불신청 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 환불번호 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" C23234222 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 환불종류 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 결제취소 환불 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 환불 접수일 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 2023년 12월14일 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 환불 완료일 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return undefined;
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("환불정보")])]), _c('v-data-table', {
          staticClass: "v-data-table--default v-data-table--no-hover v-data-table--md v-data-table--min-w-500px d-none d-lg-block",
          attrs: {
            "hide-default-footer": "",
            "disable-filtering": "",
            "disable-pagination": "",
            "disable-sort": "",
            "mobile-breakpoint": "0"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "width": "10%"
                }
              }, [_vm._v("은행")]), _c('td', {
                staticStyle: {
                  "width": "90%"
                }
              }, [_c('v-select', _vm._b({
                staticClass: "w-100 w-md-300px",
                attrs: {
                  "dense": "",
                  "label": "선택"
                }
              }, 'v-select', Object.assign({}, _vm.attrs_input), false))], 1)]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("예금주")]), _c('td', [_c('v-text-field', _vm._b({
                staticClass: "w-100 w-md-300px",
                attrs: {
                  "dense": ""
                }
              }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1)]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("계좌번호")]), _c('td', [_c('v-text-field', _vm._b({
                attrs: {
                  "dense": ""
                }
              }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1)]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("상세사유")]), _c('td', [_c('v-textarea', _vm._b({
                attrs: {
                  "dense": "",
                  "no-resize": ""
                }
              }, 'v-textarea', Object.assign({}, _vm.attrs_input), false))], 1)])])];
            },
            proxy: true
          }])
        }), _c('u-vertical-items', {
          staticClass: "d-lg-none"
        }, [_c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-12px py-6px py-md-8px",
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 은행")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-select', _vm._b({
                staticClass: "w-100 w-md-300px",
                attrs: {
                  "dense": "",
                  "label": "선택"
                }
              }, 'v-select', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-12px py-6px py-md-8px",
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 예금주 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-text-field', _vm._b({
                staticClass: "w-100 w-md-300px",
                attrs: {
                  "dense": ""
                }
              }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-12px py-6px py-md-8px",
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 계좌번호 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-text-field', _vm._b({
                attrs: {
                  "dense": ""
                }
              }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-12px py-6px py-md-8px",
            "txtContentClass": "px-12px py-6px py-md-8px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 상세사유 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-textarea', _vm._b({
                attrs: {
                  "dense": "",
                  "no-resize": ""
                }
              }, 'v-textarea', Object.assign({}, _vm.attrs_input), false))];
            },
            proxy: true
          }])
        })], 1), _c('div', {
          staticClass: "btn-wrap"
        }, [_c('v-row', {
          staticClass: "ma-0px ma-md-n4px",
          attrs: {
            "justify": "end"
          }
        }, [_c('v-col', {
          staticClass: "pa-0px pa-md-4px",
          attrs: {
            "cols": "12",
            "md": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 min-w-md-140px",
          on: {
            "click": _vm.save
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("수정하기")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap tit-wrap--sm"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("환불안내")])]), _c('v-data-table', {
          staticClass: "v-data-table--default v-data-table--no-hover v-data-table--md v-data-table--min-w-500px d-none d-lg-block",
          attrs: {
            "hide-default-footer": "",
            "disable-filtering": "",
            "disable-pagination": "",
            "disable-sort": "",
            "mobile-breakpoint": "0"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "width": "8%"
                }
              }, [_vm._v("상품금액")]), _c('td', {
                staticClass: "text-right",
                staticStyle: {
                  "width": "42%"
                }
              }, [_vm._v("1,650,000원")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "width": "8%"
                }
              }, [_vm._v("환불수단")]), _c('td', {
                staticClass: "text-right",
                staticStyle: {
                  "width": "42%"
                }
              }, [_vm._v("국민카드/일시불 1,650,000원")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("배송비")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("0원")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("환불완료")]), _c('td', {
                staticClass: "text-right"
              }, [_c('span', {
                staticClass: "red--text"
              }, [_vm._v("1,650,000원")])])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("반품비")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("0원")]), _c('th', {
                staticClass: "text-center"
              }), _c('td', {
                staticClass: "text-right"
              })])])];
            },
            proxy: true
          }])
        }), _c('u-vertical-items', {
          staticClass: "d-lg-none"
        }, [_c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 상품금액 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 1,650,000원 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 배송비 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 0원 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 반품비 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 0원 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 환불수단 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 국민카드/일시불 1,650,000원 ")];
            },
            proxy: true
          }])
        }), _c('u-vertical-item', {
          attrs: {
            "rowProps": {
              align: 'center'
            },
            "titContentWidth": "w-90px",
            "titContentClass": "text-center px-8px py-16px",
            "txtContentClass": "px-8px py-16px",
            "borderNone": ""
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 환불완료 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('span', {
                staticClass: "red--text"
              }, [_vm._v("1,650,000원")])];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }