<template>
    <div class="tabs-wrap tabs-wrap--lg">
        <v-tabs centered grow>
            <tab-primary exact to="/mypage/coupons">보유한 쿠폰 ({{ (summary?.totalCount || 0).format() }})</tab-primary>
            <tab-primary exact to="/mypage/coupons/download">다운로드 가능한 쿠폰</tab-primary>
        </v-tabs>
    </div>

    <!-- <div class="tabs-wrap">
        <v-btn-toggle mandatory group class="grey darken-4">
            <v-btn class="v-size--xx-large w-50" to="/mypage/coupons" exact-path> 보유 쿠폰 </v-btn>
            <v-btn class="v-size--xx-large w-50" to="/mypage/coupons/download" exact-path> 쿠폰 받기 </v-btn>
        </v-btn-toggle>
    </div> -->
</template>

<script>
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";

export default {
    props: {
        summary: { type: Object, default: () => ({ totalCount: 0 }) },
    },
    components: {
        TabPrimary,
    },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
    width: 100%;
    border-radius: var(--btn-border-radius-xxl);
    overflow: hidden;
    > .v-btn.v-btn {
        margin: 0;
        border-radius: 0;
        opacity: 1;
        border-width: var(--btn-outline-border-width);
        background-color: #fff !important;
        border-color: inherit;
        &--active {
            color: #fff !important;
            background-color: inherit !important;
            &:before {
                opacity: 0;
            }
        }
        &.v-size--xx-large {
            &:first-child {
                border-top-left-radius: var(--btn-border-radius-xxl);
                border-bottom-left-radius: var(--btn-border-radius-xxl);
            }
            &:last-child {
                border-top-right-radius: var(--btn-border-radius-xxl);
                border-bottom-right-radius: var(--btn-border-radius-xxl);
            }
        }
    }
}
</style>
