<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="취소/환불">
            <list-search-default
                v-bind="{
                    code: PURCHASE_QUERY_CODES.claimedWithCancel.value,
                    loading,
                }"
            />

            <purchase-list v-bind="{ code, loading }" @update:loading="(bool) => (loading = bool)" />

            <!-- <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit tit--xxs">취소/환불 안내</div>
                    </div>
                    <div class="txt txt--sm">안내문구가 출력됩니다.</div>
                </template>
            </page-section> -->
        </mypage-layout>
    </client-page>
</template>

<script>
import { PURCHASE_QUERY_CODES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import PurchaseList from "@/components/client/mypage/purchase/purchase-list.vue";
import ListSearchDefault from "@/components/client/mypage/purchase/list/list-search-default.vue";

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        PageSection,

        PurchaseList,
        ListSearchDefault,
    },
    data: () => ({
        code: PURCHASE_QUERY_CODES.claimedWithCancel.value,

        loading: false,

        PURCHASE_QUERY_CODES,
    }),
    computed: {
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                // { text: "취소/환불", to: { path: "/mypage/orders/exchange" } },
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "취소/환불" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
    methods: {
        sync() {
            this.code = this.$route.query.code || PURCHASE_QUERY_CODES.claimedWithRtrChg.value;
        },
    },
    watch: {
        active() {
            this.sync();
        },
        "$route.query.code"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>
