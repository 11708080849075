<template>
    <u-dialog v-model="shows" :title="title + ' 신청'" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <div v-bind="{ loading }">
            <!-- 상품주문일자 -->
            <div class="tit tit--xs pb-12px">{{ form?.createdAt?.toDate?.() }}</div>
            <v-divider style="border-width: 2px 0 0" class="grey darken-4" />

            <purchase-item :value="form" mode="view" withQuantity />
            <v-divider />

            <!-- S:유형 -->
            <template v-if="!isCancel">
                <page-section class="page-section--xs pb-0">
                    <tit-wrap-x-small :title="title + '유형'"></tit-wrap-x-small>
                    <v-radio-group v-model="claimStatus" row hide-details>
                        <v-radio v-bind="{ ...radio_primary }" label="교환" :value="PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value" />
                        <v-radio v-bind="{ ...radio_primary }" label="반품 후 환불" :value="PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value" />
                    </v-radio-group>
                </page-section>
            </template>
            <!-- E:유형 -->

            <!-- S:사유 -->
            <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small :title="typeText + '사유'"></tit-wrap-x-small>
                <v-select v-model="form.claimReason" :placeholder="`${title} 사유 선택해주세요`" :items="claimReasonItems" v-bind="{ ...attrs_input, loading }" />
            </page-section>
            <!-- E:사유 -->

            <!-- S:상세 -->
            <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small :title="typeText + '상세'">
                    <span v-if="typeText=='취소'" class="font-size-12 red--text">*카드결제 하신 고객께서는 바로 "취소 신청" 버튼을 눌러 주세요.</span>
                </tit-wrap-x-small>
                <v-textarea v-model="form.claimReasonDetails" :placeholder="`${title} 상세를 입력해주세요`" v-bind="{ ...attrs_input, loading }" />
            </page-section>
            <!-- E:상세 -->

            <!-- 개발확인 -->
            <!-- S:계좌 -->
            <!-- <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small title="환불계좌 정보">
                    <span class="font-size-12 red--text">*본인 명의의 통장으로만 입금 받으실 수 있습니다.</span>
                </tit-wrap-x-small>
                <v-row class="row--xs">
                    <v-col cols="12">
                        <autocomplete-bank v-bind="{ ...attrs_input, loading }" placeholder="은행사 선택" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-bind="{ ...attrs_input, loading }" placeholder="계좌번호를 입력하세요." />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-bind="{ ...attrs_input, loading }" placeholder="예금주명을 입력하세요." />
                    </v-col>
                </v-row>
            </page-section> -->
            <!-- E:계좌 -->
        </div>

        <template #actions>
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-bind="{ ...btn_tertiary }" block class="v-size--xx-large" @click="close">취소</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-bind="{ ...btn_primary }" block class="v-size--xx-large" @click="save">
                         {{ isClaimCompleted ? `${title} 신청` : `${title} 요청` }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, initPurchase, PURCHASE_CLAIM_STATUSES } from "@/assets/variables";
import { btn_primary, btn_tertiary, radio_primary } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";
import AutocompleteBank from "@/components/dumb/autocomplete-bank.vue";
import PurchaseItem from "./purchase-item.vue";

export default {
    components: {
        UDialog,
        PageSection,
        TitWrapXSmall,
        AutocompleteBank,
        PurchaseItem,
    },
    props: {
        value: { type: Object, default: initPurchase }, // purchase
        type: { type: String, default: PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value },
    },
    data: () => ({
        btn_primary,
        btn_tertiary,
        radio_primary,

        form: initPurchase(),

        // ui
        shows: false,
        loading: false,
        claimStatus: null,

        attrs_input,
        PURCHASE_CLAIM_STATUSES,
    }),
    computed: {
        typeText() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value]: "반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value]: "교환",
            }[this.claimStatus];
        },
        title() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value]: "교환·반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value]: "교환·반품",
            }[this.type];
        },
        isCancel(){
            return [PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value, PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value].includes(this.type);
        },
        claimReasonItems() {
            let items = [];

            if (!this.isCancel) {
                items.push("단순변심");
                items.push("상품불량");
            }
            items.push("기타");

            return items;
        },
        isVbank() {
            return this.value.order.paymentMethod === "vbank";
        },
        isClaimCompleted() {
            return [PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value].includes(this.claimStatus);
        }
    },
    mounted() {
        this.sync();
    },
    watch: {
        shows(shows) {
            if (shows) this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initPurchase({ ...this.value });
            this.claimStatus = this.isVbank ? PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value : this.type;
        },

        emit() {
            this.$emit("input", this.form);
        },

        close() {
            this.shows = false;
        },

        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { _id, claimReason, claimReasonDetails, orderStatus } = this.form;
                let { claimStatus } = this;
                let form = {
                    _id,
                    claimStatus,
                    claimReason,
                    claimReasonDetails,
                    orderStatus,
                };
                this.form = (await api.v1.me.purchases.put(form))?.purchase;

                if(this.isClaimCompleted) await api.v1.me.orders.cancel({ _id: this.value._order });

                this.emit();

                switch (claimStatus) {
                    case PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value: {
                        alert(`해당 제품주문에 대한 "${this.typeText}" 가 처리되었습니다.`);
                        break;
                    }

                    default: {
                        alert(`해당 제품주문에 대한 "${this.typeText}" 요청이 진행되었습니다.`);
                        break;
                    }
                };

                this.close();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
