var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "대량구매요청"
    }
  }, [_c('page-section', {
    staticClass: "py-0"
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("모아고")]), _c('p', {
    staticClass: "txt txt--sm txt--dark mt-8px"
  }, [_vm._v("아래의 신청 게시판에 글을 남겨주시면 빠른 시간 내에 답변 드리겠습니다.")]), _vm.isCompany ? [_c('p', {
    staticClass: "txt txt--xs txt--light mt-16px mt-md-20px"
  }, [_vm._v("* 설치비용은 제품 및 지역에 따라 다르게 책정될 수 있습니다.")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px",
    attrs: {
      "to": `${_vm.$route.path}/inquire`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("요청하기")])], 1)] : [_c('p', {
    staticClass: "txt txt--xs txt--light mt-16px mt-md-20px"
  }, [_vm._v("* 기업회원만 사용할 수 있는 기능입니다.")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px mr-2",
    attrs: {
      "to": `/login`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("로그인하기")]), _c('v-btn', _vm._b({
    staticClass: "min-w-120px",
    attrs: {
      "to": `/join`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("가입하기")])], 1)]], 2)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }