<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="반품/교환 상세">
            <!-- S: 상단(모바일) -->
            <tit-wrap-line noDivider class="d-xl-none">
                <v-row align="center" class="ma-n2px ma-sm-n6px">
                    <v-col cols="12" sm="auto" class="pa-2px pa-sm-6px">
                        <span class="txt txt--sm"> 상품정보 </span>
                    </v-col>
                </v-row>
            </tit-wrap-line>
            <!-- E: 상단(모바일) -->
            <!-- S: 상단(PC) -->
            <v-divider style="border-width: 2px 0 0" class="border-dark d-none d-xl-block" />
            <div class="pt-xl-16px mb-xl-n4px">
                <tit-wrap-line noDivider class="d-none d-xl-block">
                    <v-row align="center" class="row--xs">
                        <v-col> </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="w-md-60px text-center">
                                <div class="txt txt--sm txt--dark font-weight-medium">수량</div>
                            </div>
                        </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="w-md-120px text-center">
                                <div class="txt txt--sm txt--dark font-weight-medium">상품금액</div>
                            </div>
                        </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="w-md-60px text-center">
                                <div class="txt txt--sm txt--dark font-weight-medium">취소수량</div>
                            </div>
                        </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="w-md-120px text-center">
                                <div class="txt txt--sm txt--dark font-weight-medium">주문상태</div>
                            </div>
                        </v-col>
                    </v-row>
                </tit-wrap-line>
            </div>
            <!-- E: 상단(PC) -->
            <v-divider :class="$vuetify.breakpoint.xl ? '' : 'border-dark'" />

            <v-card tile>
                <div class="py-20px py-xl-10px">
                    <v-row align-xl="center" class="ma-n6px ma-lg-n12px">
                        <!-- S: 정보(모바일) -->
                        <v-col cols="12" class="pa-6px pa-lg-12px d-xl-none">
                            <div class="txt txt--sm">
                                <span>상품주문번호 : 202401020001_001</span>
                            </div>
                        </v-col>
                        <!-- E: 정보(모바일) -->
                        <v-col cols="12" xl="" class="pa-6px pa-lg-12px">
                            <!-- S: 이미지, 정보(모바일) -->
                            <div class="d-xl-none">
                                <v-row align="center" class="ma-n6px ma-xl-n12px">
                                    <v-col cols="auto" class="pa-6px pa-xl-12px">
                                        <v-card tile outlined class="overflow-hidden">
                                            <router-link to="/">
                                                <v-img :aspect-ratio="130 / 100" width="104">
                                                    <template #placeholder>
                                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                    </template>
                                                </v-img>
                                            </router-link>
                                        </v-card>
                                    </v-col>
                                    <v-col class="pa-6px pa-xl-12px">
                                        <router-link to="/">
                                            <div class="txt txt--dark">볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)</div>
                                        </router-link>
                                        <div class="txt txt--sm txt--dark mt-4px">1,650,000원</div>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- E: 이미지, 정보(모바일) -->

                            <!-- S: 이미지, 정보(PC) -->
                            <div class="d-none d-xl-block">
                                <v-row align="center" class="ma-n6px ma-lg-n12px">
                                    <v-col cols="auto" class="pa-6px pa-lg-12px">
                                        <v-card tile outlined class="overflow-hidden">
                                            <router-link to="/">
                                                <v-img :aspect-ratio="130 / 100" width="130">
                                                    <template #placeholder>
                                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                    </template>
                                                </v-img>
                                            </router-link>
                                        </v-card>
                                    </v-col>
                                    <v-col class="pa-6px pa-lg-12px">
                                        <div class="txt txt--sm mb-4px mb-md-8px mb-lg-14px">
                                            <span class="pr-6px">결제완료</span>
                                            <span>상품주문번호 : 202401020001_001</span>
                                        </div>
                                        <router-link to="/">
                                            <div class="txt txt--dark">볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)</div>
                                        </router-link>
                                        <div class="txt txt--sm mt-4px mt-md-8px">색상 : 블랙 / 수량 : 1개</div>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- E: 이미지, 정보(PC) -->
                        </v-col>
                        <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                            <div class="w-xl-60px text-xl-center d-none d-xl-block">
                                <div class="txt txt--sm">
                                    <span class="d-xl-none">수량 : </span>
                                    <span>1</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="6" md="4" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                            <div class="w-xl-120px text-xl-center">
                                <div class="txt txt--sm">
                                    <span class="d-xl-none">상품금액 : </span>
                                    <span>1,650,000원</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                            <div class="w-xl-60px text-xl-center d-none d-xl-block">
                                <div class="txt txt--sm">
                                    <span class="d-xl-none">취소수량 : </span>
                                    <span>1</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                            <div class="w-xl-120px text-xl-center d-none d-xl-block">
                                <div class="txt txt--sm">
                                    <span class="d-xl-none">주문상태 : </span>
                                    <span>상품준비중</span>
                                </div>
                            </div>
                        </v-col>
                        <!-- S: 수량, 취소수량, 주문상태(모바일) -->
                        <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-xl-none">
                            <div class="text-center grey lighten-5">
                                <v-row no-gutters align="center">
                                    <v-col>
                                        <div class="txt txt--xs pa-12px">
                                            <span>색상 : 블랙</span>
                                            <span class="px-4px">/</span>
                                            <span>수량 : 1</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto" align-self="stretch" class="ml-n1px"><v-divider vertical /></v-col>
                                    <v-col>
                                        <div class="txt txt--xs pa-12px">
                                            <span>취소수량 : </span>
                                            <span>1</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto" align-self="stretch" class="ml-n1px"><v-divider vertical /></v-col>
                                    <v-col>
                                        <div class="txt txt--xs pa-12px">
                                            <span>주문상태 : </span>
                                            <span>상품준비중</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <!-- E: 수량, 취소수량, 주문상태(모바일) -->
                    </v-row>
                </div>
                <v-divider />
            </v-card>

            <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit tit--xxs">교환상품 배송정보</div>
                    </div>

                    <!-- S: PC -->
                    <v-data-table hide-default-footer disable-filtering disable-pagination disable-sort mobile-breakpoint="0" class="v-data-table--default v-data-table--no-hover v-data-table--md v-data-table--min-w-500px d-none d-lg-block">
                        <template v-slot:body>
                            <tbody>
                                <tr>
                                    <th style="width: 10%" class="text-center">교환상태</th>
                                    <td style="width: 40%">배송완료</td>
                                    <th style="width: 10%" class="text-center">회수인</th>
                                    <td style="width: 40%">홍길동</td>
                                </tr>
                                <tr>
                                    <th class="text-center">교환접수번호</th>
                                    <td>C23234222</td>
                                    <th class="text-center">휴대폰</th>
                                    <td>010-1234-5678</td>
                                </tr>
                                <tr>
                                    <th class="text-center">교환 접수일</th>
                                    <td>2023년12월12일</td>
                                    <th class="text-center">연락처</th>
                                    <td>010-1234-5678</td>
                                </tr>
                                <tr>
                                    <th class="text-center">환불 완료일</th>
                                    <td>2023년 12월14일</td>
                                    <th class="text-center">주소</th>
                                    <td>[ 14547 ] 경기도 부천시 길주로 202 (부천시청역) 12</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <!-- E: PC -->
                    <!-- S: 모바일 -->
                    <u-vertical-items class="d-lg-none">
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 교환상태 </template>
                            <template #txt> 배송완료 </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> <span class="letter-spacing-n04">교환접수번호</span> </template>
                            <template #txt> C23234222 </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 교환 접수일 </template>
                            <template #txt> 2023년12월12일 </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 환불 완료일 </template>
                            <template #txt> 2023년 12월14일 </template>
                        </u-vertical-item>

                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 회수인 </template>
                            <template #txt> 홍길동 </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 휴대폰 </template>
                            <template #txt> 010-1234-5678 </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 연락처 </template>
                            <template #txt> 010-1234-5678 </template>
                        </u-vertical-item>
                        <u-vertical-item titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 주소 </template>
                            <template #txt> [ 14547 ] 경기도 부천시 길주로 202 (부천시청역) 12 </template>
                        </u-vertical-item>
                    </u-vertical-items>
                    <!-- E: 모바일 -->
                </template>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit tit--xxs">배송운송장 정보</div>
                    </div>

                    <!-- S: PC -->
                    <v-data-table hide-default-footer disable-filtering disable-pagination disable-sort mobile-breakpoint="0" class="v-data-table--default v-data-table--no-hover v-data-table--md v-data-table--min-w-500px d-none d-lg-block">
                        <template v-slot:body>
                            <tbody>
                                <tr>
                                    <th style="width: 10%" class="text-center">택배</th>
                                    <td style="width: 90%">CJ대한통운</td>
                                </tr>
                                <tr>
                                    <th class="text-center">송장번호</th>
                                    <td>12345456465464</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <!-- E: PC -->
                    <!-- S: 모바일 -->
                    <u-vertical-items class="d-lg-none">
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 택배 </template>
                            <template #txt> CJ대한통운 </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 송장번호 </template>
                            <template #txt> 12345456465464 </template>
                        </u-vertical-item>
                    </u-vertical-items>
                    <!-- E: 모바일 -->
                </template>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit tit--xxs">상품 회수정보</div>
                    </div>

                    <!-- S: PC -->
                    <v-data-table hide-default-footer disable-filtering disable-pagination disable-sort mobile-breakpoint="0" class="v-data-table--default v-data-table--no-hover v-data-table--md v-data-table--min-w-500px d-none d-lg-block">
                        <template v-slot:body>
                            <tbody>
                                <tr>
                                    <th style="width: 10%" class="text-center"><span class="letter-spacing-n04">상품회수 진행여부</span></th>
                                    <td style="width: 40%">판매자 전달완료</td>
                                    <th style="width: 10%" class="text-center">반송장정보</th>
                                    <td style="width: 40%"></td>
                                </tr>
                                <tr>
                                    <th class="text-center">회수인</th>
                                    <td>홍길동</td>
                                    <th class="text-center">택배</th>
                                    <td>CJ대한통운</td>
                                </tr>
                                <tr>
                                    <th class="text-center">휴대폰</th>
                                    <td>010-1234-5456</td>
                                    <th class="text-center">송장번호</th>
                                    <td>12345456465464</td>
                                </tr>
                                <tr>
                                    <th class="text-center">연락처</th>
                                    <td>010-4546-45465</td>
                                    <th class="text-center"></th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th class="text-center">주소</th>
                                    <td>[ 14547 ] 경기도 부천시 길주로 202 (부천시청역) 12</td>
                                    <th class="text-center"></th>
                                    <td></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <!-- E: PC -->
                    <!-- S: 모바일 -->
                    <u-vertical-items class="d-lg-none">
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 진행여부 </template>
                            <template #txt> 판매자 전달완료 </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 회수인 </template>
                            <template #txt> 홍길동 </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 휴대폰 </template>
                            <template #txt> 010-1234-5456 </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 연락처 </template>
                            <template #txt> 010-4546-45465 </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 주소 </template>
                            <template #txt> [ 14547 ] 경기도 부천시 길주로 202 (부천시청역) 12 </template>
                        </u-vertical-item>
                    </u-vertical-items>
                    <u-vertical-items class="d-lg-none mt-20px">
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 반송장정보 </template>
                            <template #txt> </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 택배 </template>
                            <template #txt> CJ대한통운 </template>
                        </u-vertical-item>
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 송장번호 </template>
                            <template #txt> 12345456465464 </template>
                        </u-vertical-item>
                    </u-vertical-items>
                    <!-- E: 모바일 -->
                </template>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <template v-slot:containerImmersive>
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit tit--xxs">교환사유</div>
                    </div>

                    <!-- S: PC -->
                    <v-data-table hide-default-footer disable-filtering disable-pagination disable-sort mobile-breakpoint="0" class="v-data-table--default v-data-table--no-hover v-data-table--md v-data-table--min-w-500px d-none d-lg-block">
                        <template v-slot:body>
                            <tbody>
                                <tr>
                                    <th style="width: 10%" class="text-center">교환사유</th>
                                    <td style="width: 90%">색상/ 사이즈가 기대와 다름</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <!-- E: PC -->
                    <!-- S: 모바일 -->
                    <u-vertical-items class="d-lg-none">
                        <u-vertical-item :rowProps="{ align: 'center' }" titContentWidth="w-90px" titContentClass="text-center px-8px py-16px" txtContentClass="px-8px py-16px" borderNone>
                            <template #tit> 교환사유 </template>
                            <template #txt> 색상/ 사이즈가 기대와 다름 </template>
                        </u-vertical-item>
                    </u-vertical-items>
                    <!-- E: 모바일 -->
                </template>
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_primary, btn_primary3, btn_secondary, btn_secondary2, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import UTxtItems from "@/components/client/dumb/u-txt-items.vue";
import UTxtItem from "@/components/client/dumb/u-txt-item.vue";
import UVerticalItems from "@/components/client/dumb/u-vertical-items.vue";
import UVerticalItem from "@/components/client/dumb/u-vertical-item.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        PageSection,
        TitWrapLine,
        UTxtItems,
        UTxtItem,
        UVerticalItems,
        UVerticalItem,
        UDialog,
        VerticalFormTable,
    },
    props: {},
    data: function () {
        return {
            btn_primary,
            btn_primary3,
            btn_secondary,
            btn_secondary2,
            attrs_input,

            loading: false,

            dialog: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading) return;
            this.loading = true;
        },
    },
    computed: {
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                // { text: "반품/교환", to: { path: "/mypage/orders/exchange" } },
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "반품/교환" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
};
</script>
