<template>
    <v-data-table v-bind="{ headers, items, loading }" hide-default-footer disable-filtering disable-pagination disable-sort mobile-breakpoint="0" class="v-data-table--default v-data-table--table-fixed v-data-table--min-w-500px v-data-table--md d-none d-xl-block" no-data-text="마일리지 내역이 없습니다">
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

        <template #[`item.amount`]="{ value }">
            <span
                :class="{
                    'red--text': (value || 0) < 0,
                    'secondary--text': 0 < (value || 0),
                }"
            >
                {{ (value || 0) > 0 ? "+" : "" }}{{ value?.format?.() || 0 }}원
            </span>
        </template>
    </v-data-table>
</template>

<script>
import dayjs from "@/plugins/vue-dayjs";

const headers = [
    { width: "6%", align: "center", text: "번호", value: "code" },
    {
        width: "12%",
        align: "center",
        text: "날짜",
        value: "createdAt",
        formatter: (value) =>
            value
                ? (() => {
                      try {
                          return dayjs(value).format("YYYY.MM.DD");
                      } catch {
                          return value || "-";
                      }
                  })()
                : "-",
    },
    { width: "12%", align: "center", text: "지급/차감 ", value: "amount" },
    { width: "54%", align: "center", text: "사유", value: "name" },
    {
        width: "16%",
        align: "center",
        text: "유효기간",
        value: "usage",
        formatter(usage) {
            if (!usage) return "-";
            let { endsAt, startsAt } = usage;
            let text = "";
            if (endsAt) text += dayjs(endsAt).format("YYYY.MM.DD");
            text += " ~ ";
            if (startsAt) text += dayjs(endsAt).format("YYYY.MM.DD");

            text = text.trim();
            if (text == "~") return "무제한";
            else return text;
        },
    },
    // { width: "25%", align: "center", text: "남은 금액", value: "remain", formatter: (value) => `${value?.format?.() || value || 0}원` },
].map((item) => ({ ...item, formatter: item?.formatter ?? ((value) => value ?? "-") }));

export default {
    props: {
        items: { type: Array, default: () => [] }, // points
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        headers,
    }),
};
</script>

<style>
</style>