<template>
    <v-col cols="12" class="pa-2px">
        <v-row no-gutters>
            <v-col cols="auto">
                <div class="w-70px">
                    <template v-if="!!term"> {{ term }} </template>
                    <slot name="term" />
                </div>
            </v-col>
            <v-col> <span v-if="!hideColon"> : </span> <slot name="desc" /> </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    props: {
        term: { type: String, default: null },
        hideColon: { type: Boolean, default: false },
    },
};
</script>

<style>
</style>