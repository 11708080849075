<template>
    <v-col cols="12">
        <div :class="innerClass">
            <v-row v-bind="$attrs?.rowProps" :class="rowSize">
                <v-col v-if="this.$slots['tit']" cols="12" xl="auto" :class="titClass">
                    <div class="txt font-weight-bold" :class="[titSize, titColor, titContentClass]" :style="titContentStyle">
                        <slot name="tit" />
                    </div>
                </v-col>
                <v-col v-if="this.$slots['txt']" cols="12" xl="" :class="txtClass">
                    <div class="txt" :class="[txtSize, txtColor, txtContentClass]">
                        <slot name="txt" />
                    </div>
                </v-col>
                <v-col v-else :class="contentClass">
                    <slot />
                </v-col>
            </v-row>
        </div>
    </v-col>
</template>

<script>
export default {
    props: {
        innerClass: { type: String, default: "" },
        rowSize: { type: String, default: "row--xxs" },
        titClass: { type: String },
        titSize: { type: String, default: "txt--sm" },
        titColor: { type: String, default: "txt--dark" },
        titContentClass: { type: String, default: "w-100 w-xl-100px" },
        titContentStyle: { type: String },
        txtClass: { type: String },
        txtSize: { type: String, default: "txt--sm" },
        txtColor: { type: String, default: "txt--dark" },
        txtContentClass: { type: String },
        contentClass: { type: String },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
