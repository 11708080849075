var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('mypage-layout', {
    attrs: {
      "title": "마일리지"
    }
  }, [_c('div', {
    staticClass: "txt pb-20px pb-md-30px"
  }, [_vm._v("김다영(kdy5770)님이 보유한 마일리지는 "), _c('b', {
    staticClass: "txt--dark"
  }, [_vm._v("3,000M")]), _vm._v(" 입니다.")]), _c('page-section', {
    staticClass: "page-section--sm py-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('v-data-table', _vm._b({
          staticClass: "v-data-table--default v-data-table--table-fixed v-data-table--min-w-500px v-data-table--md d-none d-xl-block",
          attrs: {
            "headers": _vm.headers,
            "items": _vm.personalPaymentItems,
            "hide-default-footer": "",
            "disable-sort": "",
            "disable-filtering": "",
            "disable-pagination": "",
            "mobile-breakpoint": "0"
          },
          scopedSlots: _vm._u([{
            key: `item.paymentDeduction`,
            fn: function (_ref) {
              var item = _ref.item;
              return [_c('div', {
                staticClass: "red--text"
              }, [_vm._v(_vm._s(item.paymentDeduction))])];
            }
          }, {
            key: `item.reason`,
            fn: function (_ref2) {
              var item = _ref2.item;
              return [_c('div', {
                staticClass: "text-left"
              }, [_vm._v(_vm._s(item.reason))])];
            }
          }], null, true)
        }, 'v-data-table', {
          personalPaymentItems: _vm.personalPaymentItems,
          headers: _vm.headers,
          loading: _vm.loading
        }, false)), _c('div', {
          staticClass: "d-xl-none"
        }, [_c('v-divider', {
          staticClass: "border-dark",
          staticStyle: {
            "border-width": "2px 0 0"
          }
        }), _vm._l(_vm.personalPaymentItems, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "border-b"
          }, [_c('div', {
            staticClass: "grey lighten-5"
          }, [_c('div', {
            staticClass: "d-flex align-center px-4px py-12px border-b",
            staticStyle: {
              "min-height": "54px"
            }
          }, [_c('v-row', {
            staticClass: "mx-n6px my-n2px",
            attrs: {
              "align": "center"
            }
          }, [_c('v-col', {
            staticClass: "px-6px py-2px",
            attrs: {
              "cols": "auto",
              "sm": ""
            }
          }, [_c('v-row', {
            staticClass: "ma-n4px",
            attrs: {
              "align": "center"
            }
          }, [_c('v-col', {
            staticClass: "pa-4px",
            attrs: {
              "cols": "auto"
            }
          }, [_c('span', {
            staticClass: "txt txt--xs txt--dark font-weight-medium"
          }, [_vm._v("날짜")])]), _c('v-col', {
            staticClass: "pa-4px",
            attrs: {
              "cols": "auto"
            }
          }, [_c('span', {
            staticClass: "txt txt--xs"
          }, [_vm._v(_vm._s(item.date))])])], 1)], 1), _c('v-col', {
            staticClass: "px-6px py-2px",
            attrs: {
              "cols": "auto",
              "sm": ""
            }
          }, [_c('v-row', {
            staticClass: "ma-n4px",
            attrs: {
              "align": "center"
            }
          }, [_c('v-col', {
            staticClass: "pa-4px",
            attrs: {
              "cols": "auto"
            }
          }, [_c('span', {
            staticClass: "txt txt--xs txt--dark font-weight-medium"
          }, [_vm._v("유효기간")])]), _c('v-col', {
            staticClass: "pa-4px",
            attrs: {
              "cols": "auto"
            }
          }, [_c('span', {
            staticClass: "txt txt--xs"
          }, [_vm._v(_vm._s(item.expirationPeriod))])])], 1)], 1)], 1)], 1)]), _c('div', {
            staticClass: "py-20px"
          }, [_c('v-row', {
            staticClass: "row--xs"
          }, [_c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('div', {
            staticClass: "txt txt--sm"
          }, [_c('div', {
            staticClass: "red--text"
          }, [_vm._v(_vm._s(item.paymentDeduction))])])]), _c('v-col', [_c('div', {
            staticClass: "txt txt--sm txt--dark"
          }, [_vm._v(_vm._s(item.reason))])])], 1)], 1)]);
        })], 2)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }