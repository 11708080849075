<template>
    <client-page class="contents-bottom-md-none">
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="배송 주소록">
            <!-- <div class="tabs-wrap tabs-wrap--lg">
                <v-tabs v-model="destinationsTab" centered grow>
                    <tab-primary>자주쓰는 배송지</tab-primary>
                    <tab-primary>최근 배송지</tab-primary>
                </v-tabs>
            </div> -->

            <template v-if="items.length">
                <!-- <v-tabs-items v-model="destinationsTab"> -->
                    <!-- S: 자주쓰는배송지 -->
                    <!-- <v-tab-item> -->
                        <!-- S: PC -->
                        <v-data-table v-bind="{ items, headers, loading }" :headers="headers" :items="items" hide-default-footer disable-sort disable-filtering disable-pagination no-data-text="등록된 배송지가 없습니다" mobile-breakpoint="0" class="v-data-table--default v-data-table--table-fixed v-data-table--min-w-500px v-data-table--md d-none d-xl-block">
                            <template v-slot:[`item.address`]="{ item }">
                                <div class="txt--dark text-left">
                                    {{ item.address }}
                                    <v-chip v-if="item.isDefault" x-small outlined color="secondary" class="rounded-0 ml-12px">기본배송지</v-chip>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-row class="ma-n4px" justify="center">
                                    <v-col cols="auto" class="pa-4px">
                                        <destination-form :value="item" @input="search">
                                            <template #activator="{ attrs, on }">
                                                <v-row justify="center" class="ma-n4px">
                                                    <v-col cols="12" md="auto" class="pa-4px">
                                                        <v-btn v-bind="{ ...btn_secondary2, ...attrs }" x-small v-on="on">수정</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </destination-form>
                                    </v-col>
                                    <v-col cols="auto" class="pa-4px">
                                        <v-btn v-bind="{ ...btn_secondary2 }" x-small @click="remove(item)">삭제</v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-data-table>
                        <!-- E: PC -->
                        <!-- S: 모바일 -->
                        <div class="d-xl-none">
                            <v-divider class="border-dark" style="border-width: 2px 0 0" />
                            <!-- 반복 -->
                            <div v-for="(item, index) in items" :key="index" class="border-b">
                                <div class="grey lighten-5">
                                    <div class="d-flex align-center px-4px py-12px border-b" style="min-height: 54px">
                                        <v-row align="center" class="mx-n6px my-n2px">
                                            <v-col cols="auto" class="px-6px py-2px">
                                                <v-row align="center" class="ma-n4px">
                                                    <v-col cols="auto" class="pa-4px">
                                                        <span class="txt txt--xs txt--dark font-weight-medium">받는분</span>
                                                    </v-col>
                                                    <v-col cols="auto" class="pa-4px">
                                                        <span class="txt txt--xs">{{ item.name }}</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="auto" class="px-6px py-2px">
                                                <span class="txt txt--xs">{{ item.phone }}</span>
                                            </v-col>
                                            <v-spacer />
                                            <v-col cols="auto" class="px-6px py-2px">
                                                <span class="txt txt--xs">{{ item.contry }}</span>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                                <div class="pt-20px pb-12px">
                                    <div class="txt txt--sm txt--dark">{{ item.address }}</div>
                                    <div v-if="item.isDefault" class="mt-8px">
                                        <v-chip x-small outlined color="secondary" class="rounded-0">기본배송지</v-chip>
                                    </div>
                                    <div class="txt txt--xs mt-8px">배송지명 : {{ item.title }}</div>
                                    <div class="btn-wrap">
                                        <v-row no-gutters>
                                            <v-col>
                                                <destination-form @input="search">
                                                    <template #activator="{ attrs, on }">
                                                        <v-row justify="center" class="ma-n4px">
                                                            <v-col cols="12" md="auto" class="pa-4px">
                                                                <v-btn v-bind="{ ...btn_secondary2, ...attrs }" x-small v-on="on">수정</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </destination-form>
                                            </v-col>
                                            <v-col class="ml-n1px">
                                                <v-btn v-bind="{ ...btn_secondary2 }" x-small block @click="remove(item)">삭제</v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </div>
                            <!-- //반복 -->
                        </div>
                        <!-- E: 모바일 -->
                        <div class="btn-wrap mx-n12px mx-md-0px">
                            <v-row justify="end" class="ma-0px ma-md-n4px">
                                <v-col cols="12" md="auto" class="pa-0px pa-md-4px">
                                    <!-- S: dialog -->
                                    <destination-form @input="search">
                                        <template #activator="{ attrs, on }">
                                            <v-row justify="center" class="ma-n4px">
                                                <v-col cols="12" md="auto" class="pa-4px">
                                                    <v-btn v-bind="{ ...btn_primary, ...attrs }" v-on="on" class="v-size--xx-large min-w-md-140px">배송지 추가</v-btn>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </destination-form>
                                    <!-- E: dialog -->
                                </v-col>
                            </v-row>
                        </div>
                    <!-- </v-tab-item> -->
                    <!-- E: 자주쓰는배송지 -->
                    <!-- S: 최근배송지 -->
                    <!-- <v-tab-item> </v-tab-item> -->
                    <!-- E: 최근배송지 -->
                <!-- </v-tabs-items> -->
            </template>

            <template v-else>
                <div class="py-20px text-center">
                    <div class="mb-18px mb-md-24px">
                        <v-icon size="60px" color="primary">mdi-truck</v-icon>
                    </div>
                    <div class="tit tit--sm">등록된 배송지가 없습니다.</div>
                    <div class="txt txt--sm mt-8px">배송지를 등록해주세요.</div>

                    <div class="btn-wrap">
                        <destination-form @input="search">
                            <template #activator="{ attrs, on }">
                                <v-row justify="center" class="ma-n4px">
                                    <v-col cols="12" md="auto" class="pa-4px">
                                        <v-btn v-bind="{ ...btn_primary, ...attrs }" v-on="on" class="v-size--xx-large min-w-md-140px">배송지 추가</v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </destination-form>
                    </div>
                </div>
            </template>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_primary, btn_secondary, btn_secondary2, btn_tertiary, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";

import DestinationForm from "@/components/client/shop/destinations/destination-form.vue";

const headers = [
    // { width: "140", align: "center", text: "배송지", value: "title" },
    // { width: "auto", align: "center", text: "주소", value: "address" },
    // { width: "20%", align: "center", text: "연락처", value: "phone" },
    // { width: "14%", align: "center", text: "관리", value: "actions" },
    // { width: "6%", align: "center", text: "국내/해외", value: "country" },
    { width: "10%", align: "center", text: "배송지명", value: "title" },
    { width: "10%", align: "center", text: "받는분", value: "name" },
    { width: "35%", align: "center", text: "주소", value: "address" },
    { width: "15%", align: "center", text: "연락처", value: "phone" },
    { width: "14%", align: "center", text: "관리", value: "actions" },
];

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        TabPrimary,
        UDialog,

        DestinationForm,
    },
    data: () => ({
        btn_primary,
        btn_secondary,
        btn_secondary2,
        btn_tertiary,
        attrs_input,

        headers,
        destinations: [],

        loading: false,

        destinationsTab: null,
    }),
    computed: {
        items() {
            return [...this.destinations];
        },
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                // { text: "배송 주소록", to: { path: "/mypage/destinations" } },
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "배송 주소록" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
    mounted() {
        this.init();
        this.search();
    },
    methods: {
        init() {
            this.destinations = [];
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.destinations = (await api.v1.me.destinations.gets())?.destinations;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async remove(item) {
            if (!confirm(`"${item?.title}" 배송지를 삭제하시겠습니까?`)) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await api.v1.me.destinations.delete(item);
                alert("삭제되었습니다");
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                this.search();
            }
        },
    },
};
</script>
