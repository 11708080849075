<template>
    <spec-row v-bind="{ term }">
        <template #desc>
            <template v-if="!!coupon.usage.startsAt || !!coupon.usage.endsAt">
                <span>{{ coupon.usage.startsAt?.toDate?.()?.replaceAll("-", ".") || "" }}</span>
                <span> ~ </span>
                <span>{{ coupon.usage.endsAt?.toDate?.()?.replaceAll("-", ".") || "" }}</span>
            </template>
            <template v-else>
                <span> 유효기간 없음 </span>
            </template>
        </template>
    </spec-row>
</template>

<script>
import { initShopCoupon } from "@/assets/variables";

import SpecRow from "./spec-row.vue";

export default {
    components: {
        SpecRow,
    },
    props: {
        term: { type: String, default: "유효기간" },
        coupon: { type: Object, default: initShopCoupon },
    },
};
</script>

<style></style>
