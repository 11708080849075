var render = function render(){
  var _vm$value, _vm$value2, _vm$value2$product, _vm$value2$product$fl, _vm$value3, _vm$value4, _vm$value4$product, _vm$value5, _vm$value6, _vm$value7, _vm$value8, _vm$value8$product, _vm$value8$product$fl, _vm$value9, _vm$value10, _vm$value10$product, _vm$value11, _vm$value12, _vm$value13, _vm$value13$seller, _vm$value14, _vm$value15, _vm$value16, _vm$value17, _vm$value17$product, _vm$value17$product$f, _vm$value18, _vm$value19, _vm$value19$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    class: _vm.mode == 'list' ? 'py-20px py-md-22px' : ''
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align-xl": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "pr-6px"
  }, [_vm._v(_vm._s(_vm.statusText))]), _c('span', [_vm._v("상품주문번호 : " + _vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.purchaseNo))])])]), _vm.mode == 'list' ? [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "12",
      "xl": ""
    }
  }, [_c('div', {
    staticClass: "pb-16px pb-md-8px d-xl-none"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-xl-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-xl-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [_c('v-img', _vm._b({
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": "104"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }], null, false, 2495918059)
  }, 'v-img', {
    src: _vm.src
  }, false))], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-xl-12px"
  }, [(_vm$value2 = _vm.value) !== null && _vm$value2 !== void 0 && (_vm$value2$product = _vm$value2.product) !== null && _vm$value2$product !== void 0 && (_vm$value2$product$fl = _vm$value2$product.flags) !== null && _vm$value2$product$fl !== void 0 && _vm$value2$product$fl.length ? _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
  }, [_vm._v(_vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.product.flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")))]) : _vm._e(), _c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v(_vm._s((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : (_vm$value4$product = _vm$value4.product) === null || _vm$value4$product === void 0 ? void 0 : _vm$value4$product.name))])]), _c('div', {
    staticClass: "txt txt--sm txt--dark mt-4px"
  }, [_vm._v(_vm._s((((_vm$value5 = _vm.value) === null || _vm$value5 === void 0 ? void 0 : _vm$value5.salePrice) * ((_vm$value6 = _vm.value) === null || _vm$value6 === void 0 ? void 0 : _vm$value6.amount)).format()) + "원")])], 1)], 1)], 1), _c('div', {
    staticClass: "pb-16px pb-md-8px pb-xl-0 d-none d-xl-block"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [_c('v-img', _vm._b({
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": "130"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }], null, false, 2495918059)
  }, 'v-img', {
    src: _vm.src
  }, false))], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px"
  }, [_c('div', {
    staticClass: "txt txt--sm mb-4px mb-md-8px mb-lg-14px"
  }, [_c('span', {
    staticClass: "pr-6px"
  }, [_vm._v(_vm._s(_vm.statusText))]), _c('span', [_vm._v("상품주문번호 : " + _vm._s((_vm$value7 = _vm.value) === null || _vm$value7 === void 0 ? void 0 : _vm$value7.purchaseNo))])]), (_vm$value8 = _vm.value) !== null && _vm$value8 !== void 0 && (_vm$value8$product = _vm$value8.product) !== null && _vm$value8$product !== void 0 && (_vm$value8$product$fl = _vm$value8$product.flags) !== null && _vm$value8$product$fl !== void 0 && _vm$value8$product$fl.length ? _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
  }, [_vm._v(_vm._s((_vm$value9 = _vm.value) === null || _vm$value9 === void 0 ? void 0 : _vm$value9.product.flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")))]) : _vm._e(), _c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v(_vm._s((_vm$value10 = _vm.value) === null || _vm$value10 === void 0 ? void 0 : (_vm$value10$product = _vm$value10.product) === null || _vm$value10$product === void 0 ? void 0 : _vm$value10$product.name))])]), _c('div', {
    staticClass: "txt txt--sm mt-4px mt-md-8px"
  }, [_vm._v(" " + _vm._s(_vm.$decode__productOptionName(_vm.option)) + " "), !_vm.withQuantity ? [_vm._v(" / 수량 : " + _vm._s((_vm$value11 = _vm.value) === null || _vm$value11 === void 0 ? void 0 : _vm$value11.amount) + "개 ")] : _vm._e()], 2)], 1)], 1)], 1)]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "6",
      "md": "4",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("수량 : " + _vm._s((_vm$value12 = _vm.value) === null || _vm$value12 === void 0 ? void 0 : _vm$value12.amount) + "개")])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "6",
      "md": "4",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("판매사 : ")]), _c('span', {
    class: _vm.$vuetify.breakpoint.xl ? 'txt--dark' : ''
  }, [_vm._v(_vm._s((_vm$value13 = _vm.value) === null || _vm$value13 === void 0 ? void 0 : (_vm$value13$seller = _vm$value13.seller) === null || _vm$value13$seller === void 0 ? void 0 : _vm$value13$seller.nickname))])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "6",
      "md": "4",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("구매금액 : ")]), _c('span', {
    class: _vm.$vuetify.breakpoint.xl ? 'txt--dark' : ''
  }, [_vm._v(_vm._s((((_vm$value14 = _vm.value) === null || _vm$value14 === void 0 ? void 0 : _vm$value14.salePrice) * ((_vm$value15 = _vm.value) === null || _vm$value15 === void 0 ? void 0 : _vm$value15.amount)).format()) + "원")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("주문상태 : ")]), _c('span', {
    staticClass: "accent2--text"
  }, [_vm._v(_vm._s(_vm.status))])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "text-center grey lighten-5"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("주문상태 : ")]), _c('span', {
    staticClass: "accent2--text"
  }, [_vm._v(_vm._s(_vm.status))])])])], 1)], 1)])] : _vm._e(), _vm.mode == 'view' ? _c('v-col', {
    staticClass: "pa-6px pa-lg-12px"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [_c('v-img', _vm._b({
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 130 : 104
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }], null, false, 2495918059)
  }, 'v-img', {
    src: _vm.src
  }, false))], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px"
  }, [_c('div', {
    staticClass: "txt txt--sm mb-4px mb-md-8px"
  }, [_vm._v("상품주문번호 : " + _vm._s((_vm$value16 = _vm.value) === null || _vm$value16 === void 0 ? void 0 : _vm$value16.purchaseNo))]), (_vm$value17 = _vm.value) !== null && _vm$value17 !== void 0 && (_vm$value17$product = _vm$value17.product) !== null && _vm$value17$product !== void 0 && (_vm$value17$product$f = _vm$value17$product.flags) !== null && _vm$value17$product$f !== void 0 && _vm$value17$product$f.length ? _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
  }, [_vm._v(_vm._s((_vm$value18 = _vm.value) === null || _vm$value18 === void 0 ? void 0 : _vm$value18.product.flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")))]) : _vm._e(), _c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [_c('div', {
    staticClass: "txt txt--sm txt--dark mb-12px"
  }, [_vm._v(_vm._s((_vm$value19 = _vm.value) === null || _vm$value19 === void 0 ? void 0 : (_vm$value19$product = _vm$value19.product) === null || _vm$value19$product === void 0 ? void 0 : _vm$value19$product.name))])]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_vm.value._option || _vm.value._supply || _vm.value._related ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_vm.value._option ? _c('v-chip', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("옵션")]) : _vm._e(), _vm.value._supply ? _c('v-chip', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("추가")]) : _vm._e(), _vm.value._related ? _c('v-chip', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("추가")]) : _vm._e()], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('span', {
    staticClass: "txt txt--sm d-sm-inline-block pt-sm-4px"
  }, [_vm._v(_vm._s(_vm.$decode__productOptionName(_vm.option)))])])], 1)], 1)], 1)], 1) : _vm._e(), _vm.$slots['actions'] ? _c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px"
  }, [_vm._t("actions")], 2)]) : _vm._e()], 2)], 1), _vm.mode == 'list' ? _c('v-divider') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }