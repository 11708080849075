<template>
    <client-page>
        <mypage-layout title="장바구니">
            <cart-view />
        </mypage-layout>
    </client-page>
</template>

<script>
import CartView from "@/components/client/shop/cart/cart-view.vue";
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";

export default {
    components: {
        CartView,
        ClientPage,
        MypageLayout,
    },
};
</script>
