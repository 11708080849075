<template>
    <div v-bind="{ loading }">
        <list-search-summary v-bind="{ loading }" />

        <purchase-list v-bind="{ code, loading }" @update:loading="(bool) => (loading = bool)" />

        <!-- <div>
            <v-card :min-height="$vuetify.breakpoint.mdAndUp ? 360 : 260" class="d-flex align-center justify-center">
                <div class="txt">최근 3개월내 내역이 없습니다.</div>
            </v-card>
            <v-divider class="border-dark" style="border-width: 2px 0 0" />
        </div> -->
    </div>
</template>

<script>
import { PURCHASE_QUERY_CODES } from "@/assets/variables";

import PurchaseList from "@/components/client/mypage/purchase/purchase-list.vue";
import ListSearchSummary from "@/components/client/mypage/purchase/list/list-search-summary.vue";

export default {
    components: {
        PurchaseList,
        ListSearchSummary,
    },
    props: {
        active: { type: Boolean, default: false },
    },
    data: () => ({
        code: PURCHASE_QUERY_CODES.purchaseOnProcess.value,

        loading: false,
    }),
    methods: {
        sync() {
            if (this.active) {
                this.code = this.$route.query.code || PURCHASE_QUERY_CODES.purchaseOnProcess.value;
            }
        },
    },
    watch: {
        active() {
            this.sync();
        },
        "$route.query.code"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>
