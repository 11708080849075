<template>
    <client-page class="contents-bottom-md-none">
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout>
            <board-inquiry-input />
        </mypage-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import BoardInquiryInput from "@/components/client/board/skin/board-inquiry/input.vue";

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        PageSection,

        BoardInquiryInput,
    },
    data: () => ({
    }),
    computed: {
        breadcrumbsItems() {
            const items = [
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "나의 상품문의" },
            ];
            return items.map((item) => ({ ...item, disabled: false }));
        },
        _inquiry(){
            return this.$route.params._question || undefined;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (!this.accessToken) {
                this.$router.replace("/login");
            }
        },
    },
};
</script>