<template>
    <div class="d-flex align-center">
        <div class="w-md-270px">
            <v-row align="center" :class="{ 'ma-n4px': true, hasCertification }">
                <v-col class="pa-4px">
                    <v-text-field v-model="phone1" v-bind="{ ...$attrs, label, readonly, dense }" hide-details="auto" type="number" v-on="{ input, click }" />
                </v-col>
                <v-col cols="auto" class="pa-4px">-</v-col>
                <v-col class="pa-4px">
                    <v-text-field v-model="phone2" v-bind="{ ...$attrs, readonly, dense }" hide-details="auto" type="number" v-on="{ input, click }" />
                </v-col>
                <v-col cols="auto" class="pa-4px">-</v-col>
                <v-col class="pa-4px">
                    <v-text-field v-model="phone3" v-bind="{ ...$attrs, readonly, dense }" hide-details="auto" type="number" v-on="{ input, click }" />
                </v-col>
                <template v-if="rules">
                    <v-col cols="12" class="pa-4px">
                        <v-text-field :value="phone" v-bind="{ rules }" hide-details="auto" class="v-phone-field-rules pa-0 ma-0" />
                    </v-col>
                </template>
            </v-row>
        </div>
        <div v-if="hasCertification" class="pl-8px">
            <v-row align="center" :class="{ 'ma-n4px': true, hasCertification }">
                <v-col cols="12" sm="auto" class="pa-4px">
                    <v-btn v-bind="{ ...btn_secondary2, ...btnAttrs, ...$attrs, dense }" :small="dense == true ? true : false" :x-large="dense == false ? true : false" class="w-100 w-sm-80px" @click="certify">본인인증</v-btn>
                </v-col>
                <kcp-cert ref="kcp-cert" @input="processCertifiedData" />
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { btn_secondary2, USER_TYPES } from "@/assets/variables";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import cryptoAes from '@/plugins/crypto-aes';

export default {
    components: {
        KcpCert,
    },
    props: {
        value: { type: String }, // phone
        label: { type: String },
        btnAttrs: { type: Object, default: () => ({}) }, // user
        dense: { type: Boolean },
        rules: { type: Array, default: null },
        hasCertification: { type: Boolean, default: false },
        type: { type: String, default: USER_TYPES.PERSON.value } //PERSON, COMPANY
    },
    data: () => ({
        btn_secondary2,

        phone1: "010",
        phone2: null,
        phone3: null,
    }),
    computed: {
        readonly() {
            return this.hasCertification;
        },
        phone() {
            const { phone1, phone2, phone3 } = this;
            if (!!phone1 && !!phone2 && !!phone3) return `${phone1}-${phone2}-${phone3}`;
            else return null;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            if (this.phone != this.value) this.sync();
        },
    },
    methods: {
        sync() {
            [this.phone1, this.phone2, this.phone3] = this.value?.split?.("-") || ["010", null, null];
        },
        emit(value) {
            this.$emit("input", value);
        },
        input() {
            this.$nextTick(() => {
                this.phone1 = this.phone1?.slice?.(0, 4) || null;
                this.phone2 = this.phone2?.slice?.(0, 4) || null;
                this.phone3 = this.phone3?.slice?.(0, 4) || null;
            });
        },
        click() {
            if (this.hasCertification) this.certify();
        },
        certify() {
            if (this.type === USER_TYPES.PERSON.value) {
                if (confirm("번호를 변경하시면 연동된 SNS 계정이 모두 해지됩니다.\r변경을 진행하시겠습니까?")) {
                    this.$refs["kcp-cert"]?.auth?.();
                }
            } else {
                this.$refs["kcp-cert"]?.auth?.();
            }
        },

        async processCertifiedData(payload) {
            try {
                let { phone, birthday, name, ci } = payload;

                if(this.type === USER_TYPES.PERSON.value) await this.validateSameUser(ci, this.type);

                [this.phone1, this.phone2, this.phone3] = phone?.phoneNumberFormat()?.split?.("-");

                birthday= `${birthday.slice(0, 4)}-${birthday.slice(4, 6)}-${birthday.slice(6, 8)}`;

                this.emit({ phone: this.phone, birthday, name, ci });

            } catch(error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async validateSameUser(ci, type) {
            try {
                ci = cryptoAes.encrypt(ci.replace(/[ ]/g, "+"));
                const { _user } = await api.v1.users.existence({ ci, type });
                
                if(!_user) {
                    throw new Error("동일한 회원이 아닙니다.");
                }
                return true;
            } catch(error) {
                throw error;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    input {
        text-align: center;
    }

    .v-phone-field-rules {
        .v-input__slot {
            display: none;
        }
    }
}
.hasCertification ::v-deep input {
    cursor: pointer !important;
}
</style>
