var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('item-head', _vm._g({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }, {
    init: _vm.init
  })), _c('div', {
    staticClass: "border-t border-2 border-dark"
  }, [_vm._l(_vm.order.purchases, function (purchase) {
    return [_c('purchase-item', {
      key: purchase._id,
      attrs: {
        "value": purchase
      },
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function () {
          return [_c('v-row', {
            staticClass: "row--xs"
          }, [_c('purchase-item-claim', _vm._g({
            attrs: {
              "value": purchase
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref) {
                var attrs = _ref.attrs,
                  on = _ref.on;
                return [_c('v-col', {
                  staticClass: "mw-100 flex-grow-1 flex-shrink-0",
                  attrs: {
                    "cols": "4",
                    "md": "12"
                  }
                }, [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "block": "",
                    "x-small": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                    }
                  }
                }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("취소 신청")])], 1)];
              }
            }], null, true)
          }, {
            init: _vm.init
          })), _c('purchase-item-claim', _vm._g({
            attrs: {
              "value": purchase,
              "type": _vm.PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref2) {
                var attrs = _ref2.attrs,
                  on = _ref2.on;
                return [_c('v-col', {
                  staticClass: "mw-100 flex-grow-1 flex-shrink-0",
                  attrs: {
                    "cols": "4",
                    "md": "12"
                  }
                }, [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "block": "",
                    "x-small": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                    }
                  }
                }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("취소 요청")])], 1)];
              }
            }], null, true)
          }, {
            init: _vm.init
          })), _c('purchase-item-claim-withdraw', _vm._g({
            attrs: {
              "value": purchase
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref3) {
                var attrs = _ref3.attrs,
                  on = _ref3.on;
                return [_c('v-col', {
                  staticClass: "mw-100 flex-grow-1 flex-shrink-0",
                  attrs: {
                    "cols": "4",
                    "md": "12"
                  }
                }, [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "block": "",
                    "x-small": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                    }
                  }
                }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("취소 철회")])], 1)];
              }
            }], null, true)
          }, {
            init: _vm.init
          })), _c('purchase-item-delivery', {
            attrs: {
              "value": purchase
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref4) {
                var attrs = _ref4.attrs,
                  on = _ref4.on;
                return [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "block": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.stopPropagation();
                    }
                  }
                }, 'v-btn', Object.assign({}, _vm.btn_secondary, attrs), false), on), [_vm._v("배송 조회")])];
              }
            }], null, true)
          }), _c('purchase-item-claim', _vm._g({
            attrs: {
              "value": purchase,
              "type": _vm.PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref5) {
                var attrs = _ref5.attrs,
                  on = _ref5.on;
                return [_c('v-col', {
                  staticClass: "mw-100 flex-grow-1 flex-shrink-0",
                  attrs: {
                    "cols": "4",
                    "md": "12"
                  }
                }, [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "block": "",
                    "x-small": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                    }
                  }
                }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("교환·반품 신청")])], 1)];
              }
            }], null, true)
          }, {
            init: _vm.init
          })), _c('purchase-item-claim-withdraw', _vm._g({
            attrs: {
              "value": purchase,
              "type": _vm.PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref6) {
                var attrs = _ref6.attrs,
                  on = _ref6.on;
                return [_c('v-col', {
                  staticClass: "mw-100 flex-grow-1 flex-shrink-0",
                  attrs: {
                    "cols": "4",
                    "md": "12"
                  }
                }, [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "block": "",
                    "x-small": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                    }
                  }
                }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("교환·반품 철회")])], 1)];
              }
            }], null, true)
          }, {
            init: _vm.init
          })), _c('purchase-item-claim-withdraw', _vm._g({
            attrs: {
              "value": purchase,
              "type": _vm.PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref7) {
                var attrs = _ref7.attrs,
                  on = _ref7.on;
                return [_c('v-col', {
                  staticClass: "mw-100 flex-grow-1 flex-shrink-0",
                  attrs: {
                    "cols": "4",
                    "md": "12"
                  }
                }, [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "block": "",
                    "x-small": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                    }
                  }
                }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("교환·반품 철회")])], 1)];
              }
            }], null, true)
          }, {
            init: _vm.init
          })), _c('purchase-item-claim-reject', {
            attrs: {
              "value": purchase
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref8) {
                var attrs = _ref8.attrs,
                  on = _ref8.on,
                  title = _ref8.title;
                return [_c('v-col', {
                  staticClass: "mw-100 flex-grow-1 flex-shrink-0",
                  attrs: {
                    "cols": "4",
                    "md": "12"
                  }
                }, [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "block": "",
                    "x-small": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                    }
                  }
                }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v(_vm._s(title) + " 거절 사유")])], 1)];
              }
            }], null, true)
          }), _c('purchase-item-review', _vm._g(_vm._b({
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref9) {
                var attrs = _ref9.attrs,
                  on = _ref9.on;
                return [_c('v-col', {
                  staticClass: "mw-100 flex-grow-1 flex-shrink-0",
                  attrs: {
                    "cols": "4",
                    "md": "12"
                  }
                }, [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "block": "",
                    "x-small": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                    }
                  }
                }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("리뷰 작성")])], 1)];
              }
            }], null, true)
          }, 'purchase-item-review', {
            value: purchase,
            points: _vm.points
          }, false), {
            init: _vm.init
          })), _c('purchase-item-inquire', {
            attrs: {
              "value": purchase
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref10) {
                var attrs = _ref10.attrs,
                  on = _ref10.on;
                return [_c('v-col', {
                  staticClass: "mw-100 flex-grow-1 flex-shrink-0",
                  attrs: {
                    "cols": "4",
                    "md": "12"
                  }
                }, [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "block": "",
                    "x-small": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                    }
                  }
                }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("문의하기")])], 1)];
              }
            }], null, true)
          }), _c('purchase-item-cart', {
            attrs: {
              "value": purchase
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref11) {
                var attrs = _ref11.attrs,
                  on = _ref11.on;
                return [_c('v-col', {
                  staticClass: "mw-100 flex-grow-1 flex-shrink-0",
                  attrs: {
                    "cols": "4",
                    "md": "12"
                  }
                }, [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "block": "",
                    "x-small": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                    }
                  }
                }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("장바구니")])], 1)];
              }
            }], null, true)
          })], 1)];
        },
        proxy: true
      }], null, true)
    })];
  })], 2)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }