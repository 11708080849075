<template>
    <v-sheet>
        <!-- S: 검색영역(모바일) -->
        <v-row align="center" class="row--xxs text-center d-xl-none">
            <v-col cols="12">
                <v-row align="center" class="ma-n4px">
                    <v-col class="pa-4px">
                        <v-date-field v-model="query['createdAt'][0]" v-bind="{ ...attrs_input, loading }" dense />
                    </v-col>
                    <v-col cols="auto" class="pa-4px">
                        <div class="txt txt--sm txt--dark">~</div>
                    </v-col>
                    <v-col class="pa-4px">
                        <v-date-field v-model="query['createdAt'][1]" v-bind="{ ...attrs_input, loading }" dense />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row align="center" class="ma-n4px">
                    <v-col class="pa-4px">
                        <v-text-field v-model="query['product.name']" v-bind="{ ...attrs_input }" dense class="w-100 w-xl-300px" placeholder="상품명을 입력해 주세요." clearable @keydown.enter="emit"/>
                    </v-col>
                    <v-col cols="auto" class="pa-4px">
                        <v-btn v-bind="{ ...btn_primary, loading }" small class="w-70px" @click="emit">조회</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- E: 검색영역(모바일) -->
        <!-- S: 검색영역(PC) -->
        <div class="d-none d-xl-flex" style="max-width: 942px">
            <v-row class="row--xxs">
                <v-col>
                    <v-row align="center" class="row--xxs">
                        <v-col cols="12">
                            <v-row align="center" class="row--xxs">
                                <v-col cols="auto">
                                    <v-row align="center" class="ma-n4px">
                                        <v-col cols="auto" class="pa-4px">
                                            <div class="txt txt--sm txt--dark w-60px">주문상태</div>
                                        </v-col>
                                        <v-col cols="auto" class="pa-4px">
                                            <v-select v-model="query['code']" v-bind="{ ...attrs_input, items: codes }" dense label="선택" clearable @change="emit" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <v-row align="center" class="ma-n4px">
                                        <v-col cols="auto" class="pa-4px">
                                            <div class="txt txt--sm txt--dark">상품</div>
                                        </v-col>
                                        <!-- <v-col cols="auto" class="pa-4px">
                                            <v-select v-bind="{ ...attrs_input }" dense label="선택" />
                                        </v-col> -->
                                        <v-col class="pa-4px">
                                            <v-text-field v-model="query['product.name']" v-bind="{ ...attrs_input }" dense class="w-100 w-lg-300px" placeholder="상품명을 입력해 주세요." clearable @keydown.enter="emit"/>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" class="row--xxs">
                                <v-col cols="auto">
                                    <v-row align="center" class="ma-n4px">
                                        <v-col cols="auto" class="pa-4px">
                                            <div class="txt txt--sm txt--dark w-60px">기간</div>
                                        </v-col>
                                        <v-col cols="auto" class="pa-4px">
                                            <v-dates-toggle v-model="query['createdAt']" tile @input="emit" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <v-row align="center" class="ma-n4px">
                                        <v-col lg="" class="pa-4px">
                                            <v-date-field v-model="query['createdAt'][0]" v-bind="{ ...attrs_input, loading }" dense />
                                        </v-col>
                                        <v-col cols="auto" class="pa-4px">
                                            <div class="txt txt--sm txt--dark">~</div>
                                        </v-col>
                                        <v-col lg="" class="pa-4px">
                                            <v-date-field v-model="query['createdAt'][1]" v-bind="{ ...attrs_input, loading }" dense />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary, loading }" small height="100% !important" class="w-70px" @click="emit">조회</v-btn>
                </v-col>
            </v-row>
        </div>
        <!-- E: 검색영역(PC) -->
    </v-sheet>
</template>

<script>
import { PURCHASE_QUERY_CODES } from "@/assets/variables";
import { attrs_input, btn_primary } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";

import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import VDatesToggle from "@/components/plugins/vuetify/v-dates-toggle.vue";

const initQuery = (query = {}) => ({
    ["code"]: query["code"] || null,
    ["product.name"]: query["product.name"] || null,
    ["createdAt"]: JSON.parse(JSON.stringify(query["createdAt"] || [null, null])),
});

export default {
    components: {
        PageSection,
        TitWrapLine,

        VDateField,
        VDatesToggle,
    },
    props: {
        code: { type: String, default: PURCHASE_QUERY_CODES.purchaseOnProcess.value },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        attrs_input,
        btn_primary,
    }),
    computed: {
        codes() {
            return (PURCHASE_QUERY_CODES[this.code]?.children || []).map((key) => ({
                ...PURCHASE_QUERY_CODES[key],
            }));
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            let { ...query } = this.query;

            if (!query["code"]) delete query["code"];
            if (!query["product.name"]) delete query["product.name"];
            if (!query["createdAt"].some((item) => !!item)) delete query["createdAt"];
            delete query.page;

            this.$router.push({ query });
        },
    },
    watch: {
        $route() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>