<template>
    <div class="px-12px py-30px px-md-20px py-lg-80px" :style="$vuetify.breakpoint.xs ? 'width:272px; margin:auto;' : ''">
        <v-row align-md="center" justify-sm="center" class="mx-n2px my-n12px mx-md-n14px">
            <template v-for="({ text, value, code }, index) in items">
                <v-col v-if="index != 0" :key="`divider-${index}`" cols="auto" class="px-2px py-12px px-md-14px">
                    <template v-if="$props.code == PURCHASE_QUERY_CODES.claimProcessGoing.value">
                        <i class="icon icon-slash" :style="$vuetify.breakpoint.smAndDown ? 'width:7px; height:12px; margin-top: 2px;' : ''" />
                    </template>
                    <template v-if="$props.code == PURCHASE_QUERY_CODES.purchaseOnProcess.value">
                        <i class="icon icon-chevron-right" :style="$vuetify.breakpoint.smAndDown ? 'width:16px; height:16px; margin-top: 2px;' : ''" />
                    </template>
                </v-col>
                <v-col :key="text" cols="auto" class="px-2px py-12px px-md-14px">
                    <v-card v-bind="{ loading }" tile color="transparent" :to="{ query: { code } }" loader-height="100%" class="text-center grey--text" :style="$vuetify.breakpoint.smAndDown ? 'width:60px;' : 'width:64px;'">
                        <div class="font-size-18 font-size-md-40 font-weight-bold">{{ value || 0 }}</div>
                        <div class="font-size-12 font-size-md-14 mt-8px mt-md-6px" :style="$vuetify.breakpoint.lgAndUp ? 'font-weight:700;' : ''">{{ text }}</div>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </div>
</template>

<script>
import api from "@/api";
import { PURCHASE_QUERY_CODES } from "@/assets/variables";
import IconReceipt from "@/components/client/icons/icon-receipt.vue";
import IconGift from "@/components/client/icons/icon-gift.vue";
import IconTruck from "@/components/client/icons/icon-truck.vue";
import IconDelivery from "@/components/client/icons/icon-delivery.vue";
import IconDoneAll from "@/components/client/icons/icon-done-all.vue";
import IconExchange from "@/components/client/icons/icon-exchange.vue";

export default {
    components: {
        IconReceipt,
        IconGift,
        IconTruck,
        IconDelivery,
        IconDoneAll,
        IconExchange,
    },
    props: {
        code: { type: String, default: PURCHASE_QUERY_CODES.purchaseOnProcess.value },

        loading: { type: Boolean, default: false },
    },
    data: () => ({
        summary: {},

        PURCHASE_QUERY_CODES,
    }),
    computed: {
        parameters() {
            return (PURCHASE_QUERY_CODES[this.code]?.children || []).map((key) => ({
                ...PURCHASE_QUERY_CODES[key],
                params: {
                    ...(PURCHASE_QUERY_CODES[key].params || {}),
                    responseType: "count",
                },
            }));
        },
        items() {
            let { code } = this.$route.query;
            let items = this.parameters.map(({ text, icon, value }) => {
                let item = {
                    value: this.summary[value],
                    text,
                    icon,
                    code: value,
                    active: undefined,
                };
                item.active = item.code == code;
                return item;
            });

            return items;
        },
    },
    methods: {
        async init() {
            const summary = {};

            for (const { value, params } of this.parameters) {
                summary[value] = (await api.v1.me.purchases.gets({ params }))?.count || 0;
            }

            this.summary = summary;
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style></style>
