var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._b({}, 'div', {
    loading: _vm.loading
  }, false), [_c('list-search-summary', _vm._b({}, 'list-search-summary', {
    loading: _vm.loading
  }, false)), _c('purchase-list', _vm._b({
    on: {
      "update:loading": function (bool) {
        return _vm.loading = bool;
      }
    }
  }, 'purchase-list', {
    code: _vm.code,
    loading: _vm.loading
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }