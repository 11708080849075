<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="쿠폰 내역">
            <!-- <div class="border-y border-dark py-20px pt-md-24px mb-50px mb-md-60px">
                <v-row align="center" class="ma-n6px px-md-n22px">
                    <v-col cols="12" md="auto" class="pa-6px px-md-22px">
                        <div class="txt txt--dark font-weight-medium">쿠폰코드 입력</div>
                    </v-col>
                    <v-col cols="12" md="" class="pa-6px px-md-22px">
                        <v-row align="center" class="ma-n4px">
                            <v-col class="pa-4px">
                                <v-text-field v-bind="{ ...attrs_input }" dense placeholder="공란없이 대/소문자 구분하여 입력하세요." />
                            </v-col>
                            <v-col cols="auto" class="pa-4px">
                                <v-btn v-bind="{ ...btn_primary }" small class="w-70px">등록</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div> -->

            <mypage-coupons-nav v-bind="{ summary }" />

            <page-section class="page-section--sm py-0">
                <template #containerImmersive>
                    <v-tabs-items :value="tab">
                        <v-tab-item :value="null">
                            <div class="mx-auto" style="max-width: 940px">
                                <mypage-coupons-list v-on="{ setSummary }" />
                            </div>
                        </v-tab-item>
                        <v-tab-item value="download">
                            <div class="mx-auto" style="max-width: 940px">
                                <mypage-coupons-down />
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </template>
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import { btn_primary, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import MypageCouponsNav from "@/components/client/mypage/coupons/mypage-coupons-nav.vue";
import MypageCouponsList from "@/components/client/mypage/coupons/mypage-coupons-list.vue";
import MypageCouponsDown from "@/components/client/mypage/coupons/mypage-coupons-down.vue";

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        PageSection,

        MypageCouponsNav,
        MypageCouponsList,
        MypageCouponsDown,
    },
    props: {
        tab: { type: String, default: null },
    },
    data: () => ({
        btn_primary,
        attrs_input,

        summary: { totalCount: 0 },
    }),
    computed: {
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                // { text: "쿠폰", to: { path: "/mypage/coupons" } },
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "쿠폰" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
    methods: {
        setSummary(summary) {
            this.summary = summary;
        },
    },
};
</script>
