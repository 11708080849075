<template>
    <v-card tile class="overflow-hidden">
        <summary-status v-bind="{ loading, code }" />
        <template v-if="!hideFilters">
            <v-divider />
            <summary-filter v-bind="{ loading }" />
        </template>
    </v-card>
</template>

<script>
import { PURCHASE_QUERY_CODES } from "@/assets/variables";

import SummaryFilter from "./search-summary/summary-filter.vue";
import SummaryStatus from "./search-summary/summary-status.vue";

export default {
    components: {
        SummaryFilter,
        SummaryStatus,
    },
    props: {
        code: { type: String, default: PURCHASE_QUERY_CODES.purchaseOnProcess.value },
        loading: { type: Boolean, default: false },
        hideFilters: { type: Boolean, default: false },
    },
};
</script>

<style></style>
