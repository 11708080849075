<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="세금계산서">
            <!-- S: 검색영역(모바일) -->
            <v-row align="center" class="row--xxs text-center d-xl-none">
                <v-col cols="12">
                    <v-row align="center" class="ma-n4px">
                        <v-col class="pa-4px">
                            <v-date-field v-bind="{ ...$attrs, loading }" dense outlined hideDetails="auto" />
                        </v-col>
                        <v-col cols="auto" class="pa-4px">
                            <div class="txt txt--sm txt--dark">~</div>
                        </v-col>
                        <v-col class="pa-4px">
                            <v-date-field v-bind="{ ...$attrs, loading }" dense outlined hideDetails="auto" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row align="center" class="ma-n4px">
                        <v-col class="pa-4px">
                            <v-text-field v-bind="{ ...attrs_input }" dense class="w-100 w-xl-300px" placeholder="상품명을 입력해 주세요." />
                        </v-col>
                        <v-col cols="auto" class="pa-4px">
                            <v-btn v-bind="{ ...btn_primary }" small class="w-70px">조회</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-- E: 검색영역(모바일) -->
            <!-- S: 검색영역(PC) -->
            <div class="d-none d-xl-flex" style="max-width: 942px">
                <v-row class="row--xxs">
                    <v-col>
                        <v-row align="center" class="row--xxs">
                            <v-col cols="12">
                                <v-row align="center" class="row--xxs">
                                    <v-col cols="auto">
                                        <v-row align="center" class="ma-n4px">
                                            <v-col cols="auto" class="pa-4px">
                                                <div class="txt txt--sm txt--dark w-60px">배송방법</div>
                                            </v-col>
                                            <v-col cols="auto" class="pa-4px">
                                                <v-select v-bind="{ ...attrs_input }" dense label="선택" />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-spacer />
                                    <v-col cols="auto">
                                        <v-row align="center" class="ma-n4px">
                                            <v-col cols="auto" class="pa-4px">
                                                <div class="txt txt--sm txt--dark">상품</div>
                                            </v-col>
                                            <v-col cols="auto" class="pa-4px">
                                                <v-select v-bind="{ ...attrs_input }" dense label="선택" />
                                            </v-col>
                                            <v-col class="pa-4px">
                                                <v-text-field v-bind="{ ...attrs_input }" dense class="w-100 w-lg-300px" placeholder="상품명을 입력해 주세요." />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row align="center" class="row--xxs">
                                    <v-col cols="auto">
                                        <v-row align="center" class="ma-n4px">
                                            <v-col cols="auto" class="pa-4px">
                                                <div class="txt txt--sm txt--dark w-60px">기간</div>
                                            </v-col>
                                            <v-col cols="auto" class="pa-4px">
                                                <v-btn-toggle>
                                                    <v-btn small outlined tile color="#ccc"> 3개월 </v-btn>
                                                    <v-btn small outlined tile color="#ccc"> 6개월 </v-btn>
                                                    <v-btn small outlined tile color="#ccc"> 9개월 </v-btn>
                                                    <v-btn small outlined tile color="#ccc"> 1년 </v-btn>
                                                </v-btn-toggle>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-spacer />
                                    <v-col cols="auto">
                                        <v-row align="center" class="ma-n4px">
                                            <v-col lg="" class="pa-4px">
                                                <v-date-field v-bind="{ ...attrs, loading }" dense outlined hideDetails="auto" />
                                            </v-col>
                                            <v-col cols="auto" class="pa-4px">
                                                <div class="txt txt--sm txt--dark">~</div>
                                            </v-col>
                                            <v-col lg="" class="pa-4px">
                                                <v-date-field v-bind="{ ...attrs, loading }" dense outlined hideDetails="auto" />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_primary }" small height="100% !important" class="w-70px">조회</v-btn>
                    </v-col>
                </v-row>
            </div>
            <!-- E: 검색영역(PC) -->

            <page-section class="page-section--sm pb-0">
                <template #containerImmersive>
                    <!-- S: PC -->
                    <v-data-table v-bind="{ taxBillItems, headers, loading }" :headers="headers" :items="taxBillItems" hide-default-footer disable-sort disable-filtering disable-pagination mobile-breakpoint="0" class="v-data-table--default v-data-table--table-fixed v-data-table--min-w-500px v-data-table--md d-none d-xl-block">
                        <template v-slot:[`item.product`]="{ item }">
                            <div class="txt--dark text-left">{{ item.product }}</div>
                        </template>
                        <template v-slot:[`item.orderAmount`]="{ item }">
                            <div class="red--text">{{ item.orderAmount }}</div>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-row class="ma-n4px" justify="center">
                                <v-col cols="auto" class="pa-4px">
                                    <!-- S: dialog -->
                                    <u-dialog>
                                        <template #activator="{ attrs, on }">
                                            <v-btn v-bind="{ ...btn_primary, ...attrs }" v-on="on" x-small>신청</v-btn>
                                        </template>

                                        <div>
                                            <div class="tit-wrap tit-wrap--sm">
                                                <div class="tit tit--xxs">세금계산서 신청</div>
                                            </div>
                                            <div class="txt txt--sm mb-8px">정보를 입력해 주세요.</div>

                                            <vertical-form-table v-bind="{ items }" class="vertical-form-table--border"></vertical-form-table>
                                        </div>
                                        <div class="btn-wrap btn-wrap--md">
                                            <v-row no-gutters justify="center">
                                                <v-col cols="12" md="auto">
                                                    <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large w-100 min-w-sm-140px">신청하기</v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>

                                        <page-section class="page-section--sm pb-0">
                                            <template v-slot:containerImmersive>
                                                <div class="tit-wrap tit-wrap--sm">
                                                    <div class="tit tit--xxs">주문/결제 정보</div>
                                                </div>

                                                <!-- S: PC -->
                                                <div class="d-none d-xl-block">
                                                    <v-data-table hide-default-footer disable-sort disable-filtering disable-pagination mobile-breakpoint="0" class="v-data-table--default v-data-table--table-fixed v-data-table--no-hover v-data-table--min-w-500px v-data-table--md">
                                                        <template v-slot:header>
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center" style="width: 74%">주문상품</th>
                                                                    <th class="text-center" style="width: 10%">수량</th>
                                                                    <th class="text-center" style="width: 16%">상품금액</th>
                                                                </tr>
                                                            </thead>
                                                        </template>
                                                        <template v-slot:body>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <v-row align="center" class="ma-n6px">
                                                                            <v-col cols="auto" class="pa-6px">
                                                                                <v-card tile outlined class="overflow-hidden">
                                                                                    <router-link to="/">
                                                                                        <v-img :aspect-ratio="70 / 54" width="70">
                                                                                            <template #placeholder>
                                                                                                <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                                                            </template>
                                                                                        </v-img>
                                                                                    </router-link>
                                                                                </v-card>
                                                                            </v-col>
                                                                            <v-col class="pa-6px">
                                                                                <router-link to="/">
                                                                                    <div class="txt txt--sm txt--dark">상품 : 볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)</div>
                                                                                </router-link>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </td>
                                                                    <td class="text-center">1</td>
                                                                    <td class="text-center">1,650,000원</td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-data-table>
                                                </div>
                                                <!-- E: PC -->
                                                <!-- S: 모바일 -->
                                                <div class="d-xl-none">
                                                    <div class="border-t border-2 border-dark py-20px">
                                                        <v-row class="ma-n6px">
                                                            <v-col cols="auto" class="pa-6px">
                                                                <v-card tile outlined class="overflow-hidden">
                                                                    <router-link to="/">
                                                                        <v-img :aspect-ratio="130 / 100" width="120">
                                                                            <template #placeholder>
                                                                                <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                                            </template>
                                                                        </v-img>
                                                                    </router-link>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col class="pa-6px">
                                                                <router-link to="/">
                                                                    <div class="txt txt--dark">볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)</div>
                                                                </router-link>
                                                                <div class="txt txt--sm txt--dark mt-4px">1,650,000원</div>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                    <v-divider />
                                                </div>
                                                <!-- E: 모바일 -->
                                                <v-card tile color="grey lighten-5">
                                                    <div class="px-12px py-20px py-md-32px text-center">
                                                        <span class="font-size-14 font-size-md-16 line-height-16">주문상품금액 1,650,000원 + 총 배송비 0원 - 총 할인금액 0원 = 총 주문금액 <span class="red--text">1,650,000</span>원</span>
                                                    </div>
                                                </v-card>
                                                <v-data-table hide-default-header hide-default-footer disable-sort disable-filtering disable-pagination mobile-breakpoint="0" class="v-data-table--default v-data-table--table-fixed v-data-table--no-hover">
                                                    <template v-slot:body>
                                                        <tbody>
                                                            <tr>
                                                                <th class="text-center font-size-14 w-120px w-md-140px" style="background-color: var(--v-grey-lighten5) !important">배송비 쿠폰할인</th>
                                                                <td class="font-size-14">0원</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="text-center font-size-14" style="background-color: var(--v-grey-lighten5) !important">에누리 할인</th>
                                                                <td class="font-size-14">0원</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="text-center font-size-14" style="background-color: var(--v-grey-lighten5) !important">결제방식</th>
                                                                <td class="font-size-14">무통장</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="text-center font-size-14" style="background-color: var(--v-grey-lighten5) !important">결제금액</th>
                                                                <td class="font-size-14">1.650.000원</td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-data-table>
                                            </template>
                                        </page-section>
                                    </u-dialog>
                                    <!-- E: dialog -->
                                </v-col>
                                <!-- <v-col cols="auto" class="pa-4px">
                                <v-btn v-bind="{ ...btn_secondary2 }" x-small>완료</v-btn>
                            </v-col> -->
                            </v-row>
                        </template>
                    </v-data-table>
                    <!-- E: PC -->
                    <!-- S: 모바일 -->
                    <div class="d-xl-none">
                        <v-divider class="border-dark" style="border-width: 2px 0 0" />
                        <!-- 반복 -->
                        <div v-for="(item, index) in taxBillItems" :key="index" class="border-b">
                            <div class="grey lighten-5">
                                <div class="d-flex align-center px-4px py-12px border-b" style="min-height: 54px">
                                    <v-row align="center" class="mx-n6px my-n2px">
                                        <v-col class="px-6px py-2px">
                                            <v-row align="center" class="ma-n4px">
                                                <v-col cols="auto" class="pa-4px">
                                                    <span class="txt txt--xs txt--dark font-weight-medium">주문번호</span>
                                                </v-col>
                                                <v-col cols="auto" class="pa-4px">
                                                    <span class="txt txt--xs text-decoration-underline">{{ item.orderNumber }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col class="px-6px py-2px">
                                            <v-row align="center" class="ma-n4px">
                                                <v-col cols="auto" class="pa-4px">
                                                    <span class="txt txt--xs txt--dark font-weight-medium">주문일자</span>
                                                </v-col>
                                                <v-col cols="auto" class="pa-4px">
                                                    <span class="txt txt--xs">{{ item.date }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <div class="pt-20px pb-12px">
                                <div class="txt txt--sm txt--dark">{{ item.product }}</div>
                                <div class="txt txt--sm red--text mt-8px">{{ item.orderAmount }}</div>
                                <div class="btn-wrap">
                                    <v-row no-gutters>
                                        <v-col>
                                            <v-btn v-bind="{ ...btn_primary, ...attrs }" v-on="on" x-small block>신청</v-btn>
                                        </v-col>
                                        <!-- <v-col class="ml-n1px">
                                        <v-btn v-bind="{ ...btn_secondary2 }" x-small block>완료</v-btn>
                                    </v-col> -->
                                    </v-row>
                                </div>
                            </div>
                        </div>
                        <!-- //반복 -->
                    </div>
                    <!-- E: 모바일 -->
                </template>
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_primary, btn_secondary, btn_secondary2, btn_tertiary, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import PageSection from "@/components/client/templates/page-section.vue";

const headers = [
    { width: "5%", align: "center", text: "번호", value: "number" },
    { width: "14%", align: "center", text: "날짜", value: "date" },
    { width: "14%", align: "center", text: "주문번호", value: "orderNumber" },
    { width: "43%", align: "center", text: "상품", value: "product" },
    { width: "14%", align: "center", text: "주문금액", value: "orderAmount" },
    { width: "10%", align: "center", text: "상태", value: "actions" },
];

const taxBillItems = [
    {
        number: "3",
        date: "2023.12.08 11:24:09",
        orderNumber: "20231208112409",
        product: "볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)",
        orderAmount: "10,000원",
        actions: "",
    },
    {
        number: "2",
        date: "2023.12.08 11:24:09",
        orderNumber: "20231208112409",
        product: "볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)",
        orderAmount: "10,000원",
        actions: "",
    },
    {
        number: "1",
        date: "2023.12.08 11:24:09",
        orderNumber: "20231208112409",
        product: "볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)",
        orderAmount: "10,000원",
        actions: "",
    },
];

const items = [
    {
        term: "상호명",
        type: "text",
        required: true,
        class: "w-md-358px",
    },
    {
        term: "사업자번호",
        type: "text",
        required: true,
        class: "w-md-358px",
    },
    {
        term: "대표자",
        type: "text",
        required: true,
        class: "w-md-358px",
    },
    {
        term: "업태",
        type: "text",
        required: true,
        class: "w-md-358px",
    },
    {
        term: "업종",
        type: "text",
        required: true,
        class: "w-md-358px",
    },
    {
        term: "주소",
        type: "address",
        required: true,
    },
    {
        term: "담당자",
        type: "text",
        required: false,
        class: "w-md-358px",
    },
    {
        term: "이메일",
        type: "email",
        required: false,
    },
    {
        term: "연락처",
        type: "phone",
        required: false,
    },
].map((item) => ({ ...item, dense: true, outlined: true }));

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        VerticalFormTable,
        VAddressField,
        VPhoneField,
        VDateField,
        TabPrimary,
        UDialog,
        PageSection,
    },
    data: () => ({
        btn_primary,
        btn_secondary,
        btn_secondary2,
        btn_tertiary,
        attrs_input,

        headers,
        taxBillItems,

        items,

        loading: false,
    }),
    mounted() {
        this.init();
    },
    methods: {
        init() {},
    },
    computed: {
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                // { text: "세금계산서", to: { path: "/mypage/tax-bill" } },
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "세금계산서" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
};
</script>
