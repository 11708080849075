var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    staticClass: "pa-2px",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-70px"
  }, [!!_vm.term ? [_vm._v(" " + _vm._s(_vm.term) + " ")] : _vm._e(), _vm._t("term")], 2)]), _c('v-col', [!_vm.hideColon ? _c('span', [_vm._v(" : ")]) : _vm._e(), _vm._v(" "), _vm._t("desc")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }