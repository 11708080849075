var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "마이페이지",
            "bg": "/images/mypage/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('v-breadcrumbs', {
                attrs: {
                  "items": _vm.breadcrumbsItems
                },
                scopedSlots: _vm._u([{
                  key: "divider",
                  fn: function () {
                    return [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("mdi-chevron-right")])];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('mypage-layout', {
    attrs: {
      "title": "주문상세"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('tit-wrap-line', {
    staticClass: "d-xl-none",
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "ma-n2px ma-sm-n6px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2px pa-sm-6px",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 상품정보 ")])])], 1)], 1), _c('v-divider', {
    staticClass: "border-dark d-none d-xl-block",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "pt-xl-16px mb-xl-n4px"
  }, [_c('tit-wrap-line', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col'), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("판매사")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("구매금액")])])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("주문상태")])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px text-center"
  })])], 1)], 1)], 1), _c('v-divider', {
    class: _vm.$vuetify.breakpoint.xl ? '' : 'border-dark'
  }), _c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "py-20px py-xl-10px"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align-xl": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "pr-6px"
  }, [_vm._v("결제완료")]), _c('span', [_vm._v("상품주문번호 : 202401020001_001")])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "12",
      "xl": ""
    }
  }, [_c('div', {
    staticClass: "pb-16px pb-md-8px d-xl-none"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-xl-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-xl-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": "104"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-xl-12px"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v("볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)")])]), _c('div', {
    staticClass: "txt txt--sm txt--dark mt-4px"
  }, [_vm._v("1,650,000원")])], 1)], 1)], 1), _c('div', {
    staticClass: "pb-16px pb-md-8px pb-xl-0 d-none d-xl-block"
  }, [_c('v-row', {
    staticClass: "ma-n6px ma-lg-n12px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 130 / 100,
      "width": "130"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px"
  }, [_c('div', {
    staticClass: "txt txt--sm mb-4px mb-md-8px mb-lg-14px"
  }, [_c('span', {
    staticClass: "pr-6px"
  }, [_vm._v("결제완료")]), _c('span', [_vm._v("상품주문번호 : 202401020001_001")])]), _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v("볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)")])]), _c('div', {
    staticClass: "txt txt--sm mt-4px mt-md-8px"
  }, [_vm._v("색상 : 블랙 / 수량 : 1개")])], 1)], 1)], 1)]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "6",
      "md": "4",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("색상 : 블랙 / 수량 : 1개")])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "6",
      "md": "4",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("판매사 : ")]), _c('span', {
    class: _vm.$vuetify.breakpoint.xl ? 'txt--dark' : ''
  }, [_vm._v("109mobility")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "6",
      "md": "4",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("구매금액 : ")]), _c('span', {
    class: _vm.$vuetify.breakpoint.xl ? 'txt--dark' : ''
  }, [_vm._v("1,650,000원")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-none d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px text-xl-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("주문상태 : ")]), _c('span', {
    staticClass: "txt--dark"
  }, [_vm._v("상품준비중")])])])]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px d-xl-none",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "text-center grey lighten-5"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "txt txt--xs pa-12px"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("주문상태 : ")]), _c('span', {
    staticClass: "txt--dark"
  }, [_vm._v("상품준비중")])])])], 1)], 1)]), _c('v-col', {
    staticClass: "pa-6px pa-lg-12px",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-120px"
  }, [_c('v-row', {
    staticClass: "ma-0px ma-xl-n2px"
  }, [_c('v-col', {
    staticClass: "mw-100 flex-grow-1 flex-shrink-0 pa-0px pa-xl-2px",
    attrs: {
      "xl": "12"
    }
  }, [_c('u-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "x-small": "",
            "block": ""
          }
        }, 'v-btn', Object.assign({}, _vm.btn_secondary2, attrs), false), on), [_vm._v("배송추적")])];
      }
    }, {
      key: "actions",
      fn: function () {
        return [_c('div', {
          staticClass: "w-100 mx-auto"
        }, [_c('v-row', {
          attrs: {
            "no-gutters": "",
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 min-w-sm-140px",
          on: {
            "click": _vm.save
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("확인")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('div', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("주문번호 조회")])]), _c('u-vertical-items', [_c('u-vertical-item', {
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 송장번호 ")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 1234564 ")];
      },
      proxy: true
    }])
  }), _c('u-vertical-item', {
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 배송상태 ")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 배송중 ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "pt-30px pt-md-46px"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("배송추적")])]), _c('v-data-table', {
    staticClass: "v-data-table--default v-data-table--min-w-500px",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.shippingItems,
      "dense": "",
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "mobile-breakpoint": "0"
    }
  })], 1)])], 1), _c('v-col', {
    staticClass: "mw-100 flex-grow-1 flex-shrink-0 pa-0px pa-xl-2px ml-n1px ml-xl-0px",
    attrs: {
      "xl": "12"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-small": "",
      "block": "",
      "to": "/mypage/orders/return-application"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary2), false), [_vm._v("반품신청")])], 1), _c('v-col', {
    staticClass: "mw-100 flex-grow-1 flex-shrink-0 pa-0px pa-xl-2px ml-n1px ml-xl-0px",
    attrs: {
      "xl": "12"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-small": "",
      "block": "",
      "to": "/mypage/orders/exchange-application"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary2), false), [_vm._v("교환신청")])], 1)], 1)], 1)])], 1)], 1), _c('v-divider')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": ""
    }
  }, [_c('page-section', {
    staticClass: "page-section--sm py-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap"
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("주문서")]), _c('v-divider', {
          staticClass: "border-dark mt-14px"
        })], 1), _c('div', {
          staticClass: "tit-wrap"
        }, [_c('div', {
          staticClass: "txt txt--dark font-weight-bold"
        }, [_vm._v("주문고객 / 배송지 정보")])]), _c('u-txt-items', [_c('u-txt-item', {
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 주문고객 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('v-row', {
                staticClass: "row--xxs",
                attrs: {
                  "align": "center"
                }
              }, [_c('v-col', {
                attrs: {
                  "cols": "auto"
                }
              }, [_vm._v("김다영")]), _c('v-col', {
                staticClass: "d-flex",
                attrs: {
                  "cols": "auto"
                }
              }, [_c('v-divider', {
                staticClass: "h-10px",
                attrs: {
                  "vertical": ""
                }
              })], 1), _c('v-col', {
                attrs: {
                  "cols": "auto"
                }
              }, [_vm._v("01012345678")]), _c('v-col', {
                staticClass: "d-flex",
                attrs: {
                  "cols": "auto"
                }
              }, [_c('v-divider', {
                staticClass: "h-10px",
                attrs: {
                  "vertical": ""
                }
              })], 1), _c('v-col', {
                attrs: {
                  "cols": "auto"
                }
              }, [_vm._v("choi789@gmail.com")])], 1), _c('div', {
                staticClass: "txt txt--sm mt-8px mt-md-12px"
              }, [_vm._v("주문자 정보로 주문 관련 정보가 문자와 이메일로 발송됩니다.")])];
            },
            proxy: true
          }])
        }), _c('u-txt-item', {
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 받는분 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 김다영 ")];
            },
            proxy: true
          }])
        }), _c('u-txt-item', {
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 우편번호 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_c('div', {
                staticClass: "mb-6px"
              }, [_vm._v("배송지 이름 (대한민국)")]), _c('div', [_vm._v("[ 14547 ] 경기도 부천시 길주로 202 (부천시청역) 12")]), _c('div', {
                staticClass: "txt txt--sm mt-4px"
              }, [_vm._v("( 경기도 부천시 중동 1243 부천시청역 12 )")])];
            },
            proxy: true
          }])
        }), _c('u-txt-item', {
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 휴대폰 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 01012345678 ")];
            },
            proxy: true
          }])
        }), _c('u-txt-item', {
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 전화번호 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 0212345678 ")];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap"
        }, [_c('v-row', {
          staticClass: "ma-n4px ma-md-n24px",
          attrs: {
            "align": "center",
            "justify": "space-between",
            "justify-md": "start"
          }
        }, [_c('v-col', {
          staticClass: "pa-4px pa-md-24px",
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "tit tit--xxs"
        }, [_vm._v("주문결제 정보")])]), _c('v-col', {
          staticClass: "pa-4px pa-md-24px",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "w-150px",
          attrs: {
            "x-small": "",
            "block": ""
          }
        }, 'v-btn', Object.assign({}, _vm.btn_secondary2), false), [_vm._v("거래명세서")])], 1)], 1), _c('v-divider', {
          staticClass: "border-dark mt-10px"
        })], 1), _c('u-txt-items', [_c('u-txt-item', {
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 주문번호 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 2023121411030717532 (2023-12-14 11:03) ")];
            },
            proxy: true
          }])
        }), _c('u-txt-item', {
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 주문상태 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 결제확인 ")];
            },
            proxy: true
          }])
        }), _c('u-txt-item', {
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 결제일시 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 2023-12-14 11:03:07 ")];
            },
            proxy: true
          }])
        }), _c('u-txt-item', {
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 결제방식 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 무통장 (입금자명:전액할인) ")];
            },
            proxy: true
          }])
        }), _c('u-txt-item', {
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 입금계좌 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 기업은행 049-091255-04-018 예금주:모아고(오명섭) ")];
            },
            proxy: true
          }])
        }), _c('u-txt-item', {
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 결제금액 ")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 1,650,000원 ")];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('v-card', {
    staticClass: "mt-10px mt-xl-0px",
    style: _vm.$vuetify.breakpoint.xl ? 'width:328px' : '',
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "px-24px py-20px px-md-40px py-md-30px"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "font-size-20 font-size-md-32 font-weight-light"
  }, [_vm._v("결제정보")])]), _c('div', {
    staticClass: "pb-16px pb-md-36px"
  }, [_c('v-row', {
    staticClass: "row--xxs txt"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("총 주문금액")]), _c('v-col', {
    staticClass: "text-right"
  }, [_vm._v("1,658,000원")])], 1)], 1), _c('div', [_c('v-row', {
    staticClass: "row--xxs txt"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("+ 총 배송비")]), _c('v-col', {
    staticClass: "text-right"
  }, [_vm._v("0원")])], 1)], 1), _c('div', {
    staticClass: "pt-12px"
  }, [_c('v-row', {
    staticClass: "row--xxs txt"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("- 총 할인금액")]), _c('v-col', {
    staticClass: "text-right"
  }, [_vm._v("0원")])], 1)], 1), _c('div', {
    staticClass: "pt-12px"
  }, [_c('v-row', {
    staticClass: "row--xxs txt"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("마일리지 사용")]), _c('v-col', {
    staticClass: "text-right"
  }, [_vm._v("0원")])], 1)], 1), _c('v-divider', {
    staticClass: "my-18px my-md-30px"
  }), _c('div', [_c('v-row', {
    staticClass: "ma-n4px"
  }, [_c('v-col', {
    staticClass: "pa-4px",
    attrs: {
      "cols": "auto",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "txt text-right"
  }, [_vm._v("총 결제 금액")])]), _c('v-col', {
    staticClass: "pa-4px",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "font-size-20 font-size-md-32 font-weight-light text-right"
  }, [_c('span', {
    staticClass: "red--text"
  }, [_vm._v("1,650,000")]), _vm._v("원")])])], 1)], 1)], 1)])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }