var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-divider', {
    staticClass: "border-dark d-none d-md-block"
  }), _vm._l(_vm.items, function (item, index) {
    var _item$product, _item$product$thumb;
    return _c('div', {
      key: index,
      staticClass: "py-10px",
      class: _vm.$vuetify.breakpoint.mdAndUp ? 'border-b' : ''
    }, [_c('v-row', {
      staticClass: "ma-n10px",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "pa-10px",
      attrs: {
        "cols": "12",
        "md": ""
      }
    }, [_c('v-row', {
      staticClass: "ma-n6px",
      attrs: {
        "align-md": "center"
      }
    }, [_c('v-col', {
      staticClass: "pa-6px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', {
      staticClass: "overflow-hidden",
      attrs: {
        "tile": "",
        "outlined": ""
      }
    }, [_c('router-link', {
      attrs: {
        "to": "/"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$thumb = _item$product.thumb) === null || _item$product$thumb === void 0 ? void 0 : _item$product$thumb.url,
        "aspect-ratio": 70 / 54,
        "width": "70"
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-overlay', {
            attrs: {
              "absolute": "",
              "opacity": "0.1"
            }
          }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
        },
        proxy: true
      }], null, true)
    })], 1)], 1)], 1), _c('v-col', {
      staticClass: "pa-6px"
    }, [_c('div', {
      staticClass: "txt txt--xs pb-2px"
    }, [_vm._v(_vm._s(item.deliveredAt ? item.deliveredAt.toDate() : "") + " 배송완료")]), _c('div', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v("상품 : " + _vm._s(item.product.name))])])], 1)], 1), _c('v-col', {
      staticClass: "pa-10px",
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('v-btn', _vm._b({
      attrs: {
        "small": "",
        "block": "",
        "to": {
          path: './reviews-input',
          query: {
            _product: item._product,
            _purchase: item._id
          }
        }
      }
    }, 'v-btn', Object.assign({}, _vm.btn_secondary2), false), [_vm._v("후기작성")])], 1)], 1)], 1);
  }), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }