<template>
    <spec-row v-bind="{ term }" v-if="!!coupon.usage.endsAt">
        <template #desc>
            <span> {{ $dayjs(coupon.usage.endsAt).endOf("day").toISOString().toTil() }} </span>
        </template>
    </spec-row>
</template>

<script>
import { initShopCoupon } from "@/assets/variables";

import SpecRow from "./spec-row.vue";

export default {
    components: {
        SpecRow,
    },
    props: {
        term: { type: String, default: "남은일자" },
        coupon: { type: Object, default: initShopCoupon },
    },
};
</script>

<style></style>
