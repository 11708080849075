<template>
    <product-form-modal-cart v-if="isCompleted" ref="modal-cart">
        <template #activator="{ attrs, on }">
            <span v-bind="attrs" v-on="on"></span>
            <slot name="activator" v-bind="{ attrs: { disabled, loading }, on: { click } }" />
        </template>
    </product-form-modal-cart>
</template>

<script>
import api from "@/api";
import { initPurchase } from "@/assets/variables/inits";
import { PURCHASE_PURCHASE_STATUSES } from "@/assets/variables/constants";

import ProductFormModalCart from "@/components/client/shop/products/form/product-form-modal-cart.vue";

export default {
    components: {
        ProductFormModalCart,
    },
    props: {
        value: { type: Object, default: initPurchase }, // purchase
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        cart() {
            return Object.assignDefined(
                {},
                {
                    _product: this.value._product,
                    _option: this.value._option,
                    _supply: this.value._supply,
                    _related: this.value._related,
                    amount: 1,
                }
            );
        },
        disabled() {
            return this.value.product.stock < 1;
        },
        isCompleted() {
            return this.value.purchaseStatus == PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value;
        },
    },
    methods: {
        async click() {
            if (this.loading) return;
            else this.loading = true;
            try {
                const { carts } = await api.v1.me.carts.post({
                    carts: [this.cart],
                    shippingOption: this.value.shippingOption,
                });
                this.$store.dispatch("addToCart", carts);
                this.$refs["modal-cart"].open();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
