<template>
    <u-dialog v-model="shows" persistent width="350" max-width="100%" title="가상계좌">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-row class="row--xxs">
            <v-col cols=12 class="text-start">
                <span class="font-size-md-16 grey--text text--darken-4">입금계좌 : {{ value?.vbank?.vbankNumber }}</span>
            </v-col>
            <v-col cols=12 class="text-start">
                <span class="font-size-md-16 grey--text text--darken-4">예금주 : {{ value?.vbank?.vbankHolder }}</span>
            </v-col>
            <v-col cols=12 class="text-start">
                <span class="font-size-md-16 grey--text text--darken-4">은행명 : {{ value?.vbank?.vbankName }}</span>
            </v-col>
            <v-col cols=12 class="text-start">
                <span class="font-size-md-16 grey--text text--darken-4">금액 : {{ value?.totalPrice }}원</span>
            </v-col>
            <v-col cols=12 class="text-start">
                <span class="font-size-md-16 grey--text text--darken-4">입금 만료일 : {{ value?.vbank?.vbankExpDate.toDate() }}</span>
            </v-col>
        </v-row>
    </u-dialog>
</template>

<script>
import api from "@/api";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
export default {
    components:{
        PageSection,
        UDialog,
        TitWrapLine
    },
    props: {
        value: { type: Object, default: () => ({}) }, // order
    },
    data: () => ({
        shows: false
    })
}
</script>

<style>

</style>