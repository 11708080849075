<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="마일리지">
            <div class="txt pb-20px pb-md-30px">김다영(kdy5770)님이 보유한 마일리지는 <b class="txt--dark">3,000M</b> 입니다.</div>

            <page-section class="page-section--sm py-0">
                <template #containerImmersive>
                    <!-- S: PC -->
                    <v-data-table v-bind="{ personalPaymentItems, headers, loading }" :headers="headers" :items="personalPaymentItems" hide-default-footer disable-sort disable-filtering disable-pagination mobile-breakpoint="0" class="v-data-table--default v-data-table--table-fixed v-data-table--min-w-500px v-data-table--md d-none d-xl-block">
                        <template v-slot:[`item.paymentDeduction`]="{ item }">
                            <div class="red--text">{{ item.paymentDeduction }}</div>
                            <!-- <div class="secondary--text">{{ item.paymentDeduction }}</div> -->
                        </template>
                        <template v-slot:[`item.reason`]="{ item }">
                            <div class="text-left">{{ item.reason }}</div>
                        </template>
                    </v-data-table>
                    <!-- E: PC -->
                    <!-- S: 모바일 -->
                    <div class="d-xl-none">
                        <v-divider class="border-dark" style="border-width: 2px 0 0" />
                        <!-- 반복 -->
                        <div v-for="(item, index) in personalPaymentItems" :key="index" class="border-b">
                            <div class="grey lighten-5">
                                <div class="d-flex align-center px-4px py-12px border-b" style="min-height: 54px">
                                    <v-row align="center" class="mx-n6px my-n2px">
                                        <v-col cols="auto" sm="" class="px-6px py-2px">
                                            <v-row align="center" class="ma-n4px">
                                                <v-col cols="auto" class="pa-4px">
                                                    <span class="txt txt--xs txt--dark font-weight-medium">날짜</span>
                                                </v-col>
                                                <v-col cols="auto" class="pa-4px">
                                                    <span class="txt txt--xs">{{ item.date }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="auto" sm="" class="px-6px py-2px">
                                            <v-row align="center" class="ma-n4px">
                                                <v-col cols="auto" class="pa-4px">
                                                    <span class="txt txt--xs txt--dark font-weight-medium">유효기간</span>
                                                </v-col>
                                                <v-col cols="auto" class="pa-4px">
                                                    <span class="txt txt--xs">{{ item.expirationPeriod }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <div class="py-20px">
                                <v-row class="row--xs">
                                    <v-col cols="auto">
                                        <div class="txt txt--sm">
                                            <div class="red--text">{{ item.paymentDeduction }}</div>
                                            <!-- <div class="secondary--text">{{ item.paymentDeduction }}</div> -->
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div class="txt txt--sm txt--dark">{{ item.reason }}</div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <!-- //반복 -->
                    </div>
                    <!-- E: 모바일 -->
                </template>
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_primary, btn_secondary, btn_secondary2, btn_tertiary, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import PageSection from "@/components/client/templates/page-section.vue";

const headers = [
    { width: "6%", align: "center", text: "번호", value: "numder" },
    { width: "12%", align: "center", text: "날짜", value: "date" },
    { width: "12%", align: "center", text: "지급/차감", value: "paymentDeduction" },
    { width: "54%", align: "center", text: "사유", value: "reason" },
    { width: "16%", align: "center", text: "유효기간", value: "expirationPeriod" },
];

const personalPaymentItems = [
    {
        numder: "3",
        date: "2023.12.08",
        paymentDeduction: "- 5000",
        reason: "상품 구매 사용 차감",
        expirationPeriod: "2023.12.08 ~ 2024.01.09",
    },
    {
        numder: "2",
        date: "2023.11.06",
        paymentDeduction: "+ 3000",
        reason: "광고 마일리지 적립",
        expirationPeriod: "2023.12.08 ~ 2024.01.09",
    },
    {
        numder: "1",
        date: "2023.11.06",
        paymentDeduction: "+ 5000",
        reason: "신규 회원가입 지급",
        expirationPeriod: "2023.12.08 ~ 2024.01.09",
    },
];

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        VerticalFormTable,
        VAddressField,
        VPhoneField,
        VDateField,
        TabPrimary,
        UDialog,
        PageSection,
    },
    data: () => ({
        btn_primary,
        btn_secondary,
        btn_secondary2,
        btn_tertiary,
        attrs_input,

        headers,
        personalPaymentItems,

        loading: false,
    }),
    mounted() {
        this.init();
    },
    methods: {
        init() {},
    },
    computed: {
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                // { text: "마일리지", to: { path: "/mypage/mileage" } },
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "마일리지" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
};
</script>
