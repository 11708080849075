<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="마이페이지" bg="/images/mypage/visual.jpg">
                <template #path>
                    <v-breadcrumbs :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <mypage-layout title="주문상세">
            <v-row>
                <v-col cols="12">
                    <!-- S: 상단(모바일) -->
                    <tit-wrap-line noDivider class="d-xl-none">
                        <v-row align="center" class="ma-n2px ma-sm-n6px">
                            <v-col cols="12" sm="auto" class="pa-2px pa-sm-6px">
                                <span class="txt txt--sm"> 상품정보 </span>
                            </v-col>
                        </v-row>
                    </tit-wrap-line>
                    <!-- E: 상단(모바일) -->
                    <!-- S: 상단(PC) -->
                    <v-divider style="border-width: 2px 0 0" class="border-dark d-none d-xl-block" />
                    <div class="pt-xl-16px mb-xl-n4px">
                        <tit-wrap-line noDivider class="d-none d-xl-block">
                            <v-row align="center" class="row--xs">
                                <v-col> </v-col>
                                <v-col cols="auto" class="d-none d-xl-block">
                                    <div class="w-md-120px text-center">
                                        <div class="txt txt--sm txt--dark font-weight-medium">판매사</div>
                                    </div>
                                </v-col>
                                <v-col cols="auto" class="d-none d-xl-block">
                                    <div class="w-md-120px text-center">
                                        <div class="txt txt--sm txt--dark font-weight-medium">구매금액</div>
                                    </div>
                                </v-col>
                                <v-col cols="auto" class="d-none d-xl-block">
                                    <div class="w-md-120px text-center">
                                        <div class="txt txt--sm txt--dark font-weight-medium">주문상태</div>
                                    </div>
                                </v-col>
                                <v-col cols="auto">
                                    <div class="w-md-120px text-center"></div>
                                </v-col>
                            </v-row>
                        </tit-wrap-line>
                    </div>
                    <!-- E: 상단(PC) -->
                    <v-divider :class="$vuetify.breakpoint.xl ? '' : 'border-dark'" />

                    <!-- 반복 -->
                    <v-card tile>
                        <div class="py-20px py-xl-10px">
                            <v-row align-xl="center" class="ma-n6px ma-lg-n12px">
                                <!-- S: 정보(모바일) -->
                                <v-col cols="12" class="pa-6px pa-lg-12px d-xl-none">
                                    <div class="txt txt--sm">
                                        <span class="pr-6px">결제완료</span>
                                        <span>상품주문번호 : 202401020001_001</span>
                                    </div>
                                </v-col>
                                <!-- E: 정보(모바일) -->
                                <v-col cols="12" xl="" class="pa-6px pa-lg-12px">
                                    <!-- S: 이미지, 정보(모바일) -->
                                    <div class="pb-16px pb-md-8px d-xl-none">
                                        <v-row align="center" class="ma-n6px ma-xl-n12px">
                                            <v-col cols="auto" class="pa-6px pa-xl-12px">
                                                <v-card tile outlined class="overflow-hidden">
                                                    <router-link to="/">
                                                        <v-img :aspect-ratio="130 / 100" width="104">
                                                            <template #placeholder>
                                                                <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                            </template>
                                                        </v-img>
                                                    </router-link>
                                                </v-card>
                                            </v-col>
                                            <v-col class="pa-6px pa-xl-12px">
                                                <router-link to="/">
                                                    <div class="txt txt--dark">볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)</div>
                                                </router-link>
                                                <div class="txt txt--sm txt--dark mt-4px">1,650,000원</div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <!-- E: 이미지, 정보(모바일) -->

                                    <!-- S: 이미지, 정보(PC) -->
                                    <div class="pb-16px pb-md-8px pb-xl-0 d-none d-xl-block">
                                        <v-row align="center" class="ma-n6px ma-lg-n12px">
                                            <v-col cols="auto" class="pa-6px pa-lg-12px">
                                                <v-card tile outlined class="overflow-hidden">
                                                    <router-link to="/">
                                                        <v-img :aspect-ratio="130 / 100" width="130">
                                                            <template #placeholder>
                                                                <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                                            </template>
                                                        </v-img>
                                                    </router-link>
                                                </v-card>
                                            </v-col>
                                            <v-col class="pa-6px pa-lg-12px">
                                                <div class="txt txt--sm mb-4px mb-md-8px mb-lg-14px">
                                                    <span class="pr-6px">결제완료</span>
                                                    <span>상품주문번호 : 202401020001_001</span>
                                                </div>
                                                <router-link to="/">
                                                    <div class="txt txt--dark">볼트 48V 15Ah 20Ah 500W 전기자전거(자전거도로 가능)</div>
                                                </router-link>
                                                <div class="txt txt--sm mt-4px mt-md-8px">색상 : 블랙 / 수량 : 1개</div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <!-- E: 이미지, 정보(PC) -->
                                </v-col>
                                <v-col cols="6" md="4" xl="auto" class="pa-6px pa-lg-12px d-xl-none">
                                    <div class="w-xl-120px text-xl-center">
                                        <div class="txt txt--sm">색상 : 블랙 / 수량 : 1개</div>
                                    </div>
                                </v-col>
                                <v-col cols="6" md="4" xl="auto" class="pa-6px pa-lg-12px">
                                    <div class="w-xl-120px text-xl-center">
                                        <div class="txt txt--sm">
                                            <span class="d-xl-none">판매사 : </span>
                                            <span :class="$vuetify.breakpoint.xl ? 'txt--dark' : ''">109mobility</span>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="6" md="4" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                                    <div class="w-xl-120px text-xl-center">
                                        <div class="txt txt--sm">
                                            <span class="d-xl-none">구매금액 : </span>
                                            <span :class="$vuetify.breakpoint.xl ? 'txt--dark' : ''">1,650,000원</span>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-none d-xl-block">
                                    <div class="w-xl-120px text-xl-center">
                                        <div class="txt txt--sm">
                                            <span class="d-xl-none">주문상태 : </span>
                                            <span class="txt--dark">상품준비중</span>
                                        </div>
                                    </div>
                                </v-col>
                                <!-- S: 주문상태, 반품/교환/취소/환불(모바일) -->
                                <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px d-xl-none">
                                    <div class="text-center grey lighten-5">
                                        <v-row no-gutters align="center">
                                            <v-col>
                                                <div class="txt txt--xs pa-12px">
                                                    <span class="d-xl-none">주문상태 : </span>
                                                    <span class="txt--dark">상품준비중</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                                <!-- E: 주문상태, 반품/교환/취소/환불(모바일) -->

                                <v-col cols="12" xl="auto" class="pa-6px pa-lg-12px">
                                    <div class="w-xl-120px">
                                        <v-row class="ma-0px ma-xl-n2px">
                                            <v-col xl="12" class="mw-100 flex-grow-1 flex-shrink-0 pa-0px pa-xl-2px">
                                                <!-- S: dialog -->
                                                <u-dialog>
                                                    <template #activator="{ attrs, on }">
                                                        <v-btn v-bind="{ ...btn_secondary2, ...attrs }" v-on="on" x-small block>배송추적</v-btn>
                                                    </template>

                                                    <div>
                                                        <div class="tit-wrap tit-wrap--sm">
                                                            <div class="tit tit--xxs">주문번호 조회</div>
                                                        </div>

                                                        <u-vertical-items>
                                                            <u-vertical-item>
                                                                <template #tit> 송장번호 </template>
                                                                <template #txt> 1234564 </template>
                                                            </u-vertical-item>
                                                            <u-vertical-item>
                                                                <template #tit> 배송상태 </template>
                                                                <template #txt> 배송중 </template>
                                                            </u-vertical-item>
                                                        </u-vertical-items>
                                                    </div>

                                                    <div class="pt-30px pt-md-46px">
                                                        <div class="tit-wrap tit-wrap--sm">
                                                            <div class="tit tit--xxs">배송추적</div>
                                                        </div>
                                                        <v-data-table :headers="headers" :items="shippingItems" dense hide-default-footer disable-filtering disable-pagination disable-sort mobile-breakpoint="0" class="v-data-table--default v-data-table--min-w-500px" />
                                                    </div>

                                                    <template v-slot:actions>
                                                        <div class="w-100 mx-auto">
                                                            <v-row no-gutters justify="center">
                                                                <v-col cols="12" md="auto">
                                                                    <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large w-100 min-w-sm-140px" @click="save">확인</v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </template>
                                                </u-dialog>
                                                <!-- E: dialog -->
                                            </v-col>
                                            <v-col xl="12" class="mw-100 flex-grow-1 flex-shrink-0 pa-0px pa-xl-2px ml-n1px ml-xl-0px">
                                                <v-btn v-bind="{ ...btn_secondary2 }" x-small block to="/mypage/orders/return-application">반품신청</v-btn>
                                            </v-col>
                                            <v-col xl="12" class="mw-100 flex-grow-1 flex-shrink-0 pa-0px pa-xl-2px ml-n1px ml-xl-0px">
                                                <v-btn v-bind="{ ...btn_secondary2 }" x-small block to="/mypage/orders/exchange-application">교환신청</v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <v-divider />
                    </v-card>
                    <!-- // 반복 -->
                </v-col>
                <v-col cols="12">
                    <v-row class="row--sm">
                        <v-col cols="12" xl="">
                            <page-section class="page-section--sm py-0">
                                <template v-slot:containerImmersive>
                                    <div class="tit-wrap">
                                        <div class="tit tit--xxs">주문서</div>
                                        <v-divider class="border-dark mt-14px" />
                                    </div>
                                    <div class="tit-wrap">
                                        <div class="txt txt--dark font-weight-bold">주문고객 / 배송지 정보</div>
                                    </div>

                                    <u-txt-items>
                                        <u-txt-item>
                                            <template #tit> 주문고객 </template>
                                            <template #txt>
                                                <v-row align="center" class="row--xxs">
                                                    <v-col cols="auto">김다영</v-col>
                                                    <v-col cols="auto" class="d-flex"><v-divider vertical class="h-10px" /></v-col>
                                                    <v-col cols="auto">01012345678</v-col>
                                                    <v-col cols="auto" class="d-flex"><v-divider vertical class="h-10px" /></v-col>
                                                    <v-col cols="auto">choi789@gmail.com</v-col>
                                                </v-row>
                                                <div class="txt txt--sm mt-8px mt-md-12px">주문자 정보로 주문 관련 정보가 문자와 이메일로 발송됩니다.</div>
                                            </template>
                                        </u-txt-item>
                                        <u-txt-item>
                                            <template #tit> 받는분 </template>
                                            <template #txt> 김다영 </template>
                                        </u-txt-item>
                                        <u-txt-item>
                                            <template #tit> 우편번호 </template>
                                            <template #txt>
                                                <div class="mb-6px">배송지 이름 (대한민국)</div>
                                                <div>[ 14547 ] 경기도 부천시 길주로 202 (부천시청역) 12</div>
                                                <div class="txt txt--sm mt-4px">( 경기도 부천시 중동 1243 부천시청역 12 )</div>
                                            </template>
                                        </u-txt-item>
                                        <u-txt-item>
                                            <template #tit> 휴대폰 </template>
                                            <template #txt> 01012345678 </template>
                                        </u-txt-item>
                                        <u-txt-item>
                                            <template #tit> 전화번호 </template>
                                            <template #txt> 0212345678 </template>
                                        </u-txt-item>
                                    </u-txt-items>
                                </template>
                            </page-section>
                            <page-section class="page-section--sm pb-0">
                                <template v-slot:containerImmersive>
                                    <div class="tit-wrap">
                                        <v-row align="center" justify="space-between" justify-md="start" class="ma-n4px ma-md-n24px">
                                            <v-col cols="auto" class="pa-4px pa-md-24px">
                                                <div class="tit tit--xxs">주문결제 정보</div>
                                            </v-col>
                                            <v-col cols="auto" class="pa-4px pa-md-24px">
                                                <v-btn v-bind="{ ...btn_secondary2 }" x-small block class="w-150px">거래명세서</v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="border-dark mt-10px" />
                                    </div>

                                    <u-txt-items>
                                        <u-txt-item>
                                            <template #tit> 주문번호 </template>
                                            <template #txt> 2023121411030717532 (2023-12-14 11:03) </template>
                                        </u-txt-item>
                                        <u-txt-item>
                                            <template #tit> 주문상태 </template>
                                            <template #txt> 결제확인 </template>
                                        </u-txt-item>
                                        <u-txt-item>
                                            <template #tit> 결제일시 </template>
                                            <template #txt> 2023-12-14 11:03:07 </template>
                                        </u-txt-item>
                                        <u-txt-item>
                                            <template #tit> 결제방식 </template>
                                            <template #txt> 무통장 (입금자명:전액할인) </template>
                                        </u-txt-item>
                                        <u-txt-item>
                                            <template #tit> 입금계좌 </template>
                                            <template #txt> 기업은행 049-091255-04-018 예금주:모아고(오명섭) </template>
                                        </u-txt-item>
                                        <u-txt-item>
                                            <template #tit> 결제금액 </template>
                                            <template #txt> 1,650,000원 </template>
                                        </u-txt-item>
                                    </u-txt-items>
                                </template>
                            </page-section>
                        </v-col>
                        <v-col cols="12" xl="auto">
                            <v-card tile outlined class="mt-10px mt-xl-0px" :style="$vuetify.breakpoint.xl ? 'width:328px' : ''">
                                <div class="px-24px py-20px px-md-40px py-md-30px">
                                    <div class="tit-wrap tit-wrap--sm">
                                        <div class="font-size-20 font-size-md-32 font-weight-light">결제정보</div>
                                    </div>
                                    <div class="pb-16px pb-md-36px">
                                        <v-row class="row--xxs txt">
                                            <v-col cols="auto">총 주문금액</v-col>
                                            <v-col class="text-right">1,658,000원</v-col>
                                        </v-row>
                                    </div>
                                    <div>
                                        <v-row class="row--xxs txt">
                                            <v-col cols="auto">+ 총 배송비</v-col>
                                            <v-col class="text-right">0원</v-col>
                                        </v-row>
                                    </div>
                                    <div class="pt-12px">
                                        <v-row class="row--xxs txt">
                                            <v-col cols="auto">- 총 할인금액</v-col>
                                            <v-col class="text-right">0원</v-col>
                                        </v-row>
                                    </div>
                                    <div class="pt-12px">
                                        <v-row class="row--xxs txt">
                                            <v-col cols="auto">마일리지 사용</v-col>
                                            <v-col class="text-right">0원</v-col>
                                        </v-row>
                                    </div>
                                    <v-divider class="my-18px my-md-30px" />
                                    <div>
                                        <v-row class="ma-n4px">
                                            <v-col cols="auto" md="12" class="pa-4px">
                                                <div class="txt text-right">총 결제 금액</div>
                                            </v-col>
                                            <v-col md="12" class="pa-4px">
                                                <div class="font-size-20 font-size-md-32 font-weight-light text-right"><span class="red--text">1,650,000</span>원</div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </mypage-layout>

        <!--
        배송지정보
        <order-view-receiver />
        결제정보
        <order-view-payment />
        -->
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_primary, btn_secondary, btn_secondary2, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import UTxtItems from "@/components/client/dumb/u-txt-items.vue";
import UTxtItem from "@/components/client/dumb/u-txt-item.vue";
import UVerticalItems from "@/components/client/dumb/u-vertical-items.vue";
import UVerticalItem from "@/components/client/dumb/u-vertical-item.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

import OrderView from "@/components/client/mypage/order/order-view.vue";
import OrderViewPayment from "@/components/client/mypage/order/order-view-payment.vue";
import OrderViewReceiver from "@/components/client/mypage/order/order-view-receiver.vue";
import OrderViewPurchases from "@/components/client/mypage/order/order-view-purchases.vue";

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        PageSection,
        TitWrapLine,
        UTxtItems,
        UTxtItem,
        UVerticalItems,
        UVerticalItem,
        UDialog,
        VerticalFormTable,

        OrderView,
        OrderViewPayment,
        OrderViewReceiver,
        OrderViewPurchases,
    },
    props: {},
    data: function () {
        return {
            btn_primary,
            btn_secondary,
            btn_secondary2,
            attrs_input,

            loading: false,

            headers: [
                { width: "16%", text: "배송상태", value: "status", align: "center" },
                { width: "10%", text: "위치", value: "location", align: "center" },
                { width: "24%", text: "일시", value: "date", align: "center" },
                { width: "50%", text: "배송정보", value: "info", align: "center" },
            ],
            shippingItems: [
                { status: "이동중", location: "서김포", date: "2023.11.11 17:54:00", info: "집하완료 (담당자 연락처 : 010-1234-5655)" },
                { status: "상품인수", location: "부천센터", date: "2023.11.11 17:54:00", info: "집하완료 (담당자 연락처 : 010-1234-5655)" },
                { status: "이동중", location: "서김포", date: "2023.11.11 17:54:00", info: "집하완료 (담당자 연락처 : 010-1234-5655)" },
                { status: "이동중", location: "서김포", date: "2023.11.11 17:54:00", info: "집하완료 (담당자 연락처 : 010-1234-5655)" },
                { status: "이동중", location: "서김포", date: "2023.11.11 17:54:00", info: "집하완료 (담당자 연락처 : 010-1234-5655)" },
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading) return;
            this.loading = true;
        },
    },
    computed: {
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "마이페이지", to: { path: "/mypage/dashboard" } },
                // { text: "주문/배송", to: { path: "/mypage/orders" } },
                { text: "HOME" },
                { text: "마이페이지" },
                { text: "주문/배송" },
            ];
            // if (this.activeItem) {
            //     items.push({
            //         text: this.activeItem?.title,
            //         to: this.activeItem?.to,
            //     });
            // }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
};
</script>
