<template>
    <spec-row v-bind="{ term }">
        <template #desc>
            <span>{{ coupon.value.format() }}{{ unitText }} 할인</span>
            <span v-if="coupon.limit">, 최대 {{ coupon.limit.format() }}원 </span>
        </template>
    </spec-row>
</template>

<script>
import { initShopCoupon, SHOP_COUPON_UNITS } from "@/assets/variables";

import SpecRow from "./spec-row.vue";

export default {
    components: {
        SpecRow,
    },
    props: {
        term: { type: String, default: "쿠폰혜택" },
        coupon: { type: Object, default: initShopCoupon },
    },
    computed: {
        unitText() {
            return SHOP_COUPON_UNITS[this.coupon.unit].suffix;
        },
    },
};
</script>

<style></style>
