var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }, {
      key: "actions",
      fn: function () {
        return [_c('div', {
          staticClass: "w-100 mx-auto"
        }, [_c('v-row', {
          attrs: {
            "no-gutters": "",
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 min-w-sm-140px",
          on: {
            "click": _vm.save
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("확인")])], 1)], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("배송지 등록/수정")])]), _c('div', {
    staticClass: "txt txt--sm mb-8px"
  }, [_vm._v("자주쓰는 배송지는 최대 30개까지 등록할 수 있습니다.")]), _c('vertical-form-table', _vm._b({
    staticClass: "vertical-form-table--border",
    scopedSlots: _vm._u([{
      key: "주소",
      fn: function () {
        return [_c('v-address-field', _vm._b({
          attrs: {
            "basicDestination": ""
          },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v;
            },
            expression: "form"
          }
        }, 'v-address-field', Object.assign({}, _vm.attrs_input), false))];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false))], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }