var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": ""
    }
  }, [_c('summary-status', _vm._b({}, 'summary-status', {
    loading: _vm.loading,
    code: _vm.code
  }, false)), !_vm.hideFilters ? [_c('v-divider'), _c('summary-filter', _vm._b({}, 'summary-filter', {
    loading: _vm.loading
  }, false))] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }