var render = function render(){
  var _vm$user, _vm$user2, _vm$user3, _vm$user3$point, _vm$user3$point$forma;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt pb-20px pb-md-30px"
  }, [_vm._v(" " + _vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name) + "(" + _vm._s((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.username) + ")님이 보유한 마일리지는 "), _c('b', {
    staticClass: "txt--dark"
  }, [_vm._v(_vm._s(((_vm$user3 = _vm.user) === null || _vm$user3 === void 0 ? void 0 : (_vm$user3$point = _vm$user3.point) === null || _vm$user3$point === void 0 ? void 0 : (_vm$user3$point$forma = _vm$user3$point.format) === null || _vm$user3$point$forma === void 0 ? void 0 : _vm$user3$point$forma.call(_vm$user3$point)) || 0) + "M")]), _vm._v(" 입니다. ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }