<template>
    <product-item v-bind="{ product, isSoldOut }" isLink>
        <template v-if="!hideActions" #select>
            <v-simple-checkbox v-bind="{ ...checkbox_primary }" :value="isSelected" :disabled="isSoldOut" class="d-inline-block" @click.prevent.stop.capture="toggle" />
        </template>
        <template #actions>
            <!-- S: PC -->
            <div class="d-none d-md-flex flex-column align-end h-100">
                <v-btn v-if="!hideActions" text icon color="grey lighten-3" class="v-btn--text-fit mt-n6px mb-auto" @click.prevent.stop.capture="$emit('remove', item)">
                    <v-icon size="20">mdi-close</v-icon>
                </v-btn>
                <like-btn :params="{ _product: item?.product?._id }" class="mt-md-8px">
                    <template #activator="{ active }">
                        <v-btn icon rounded outlined color="transparent" class="cursor-default">
                            <span v-if="active" class="material-icons-outlined primary--text">favorite</span>
                            <span v-else class="material-icons-outlined grey--text text--darken-4">favorite_border</span>
                        </v-btn>
                    </template>
                </like-btn>

                <cart-form :value="[]" v-bind="{ _product: item?._product, title: '장바구니 추가', saveText: '추가' }">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="{ ...btn_tertiary, ...attrs }" v-on="on" small>장바구니에 추가</v-btn>
                    </template>
                </cart-form>
                <!-- <div class="txt txt--xs txt--light mt-8px mt-md-12px">{{ item?.createdAt?.toDate?.() }}</div> -->
            </div>
            <!-- E: PC -->

            <!-- S: Moblie -->
            <div class="d-md-none">
                <!-- <div class="txt txt--xs txt--light mb-8px mb-md-12px">{{ item?.createdAt?.toDate?.() }}</div> -->
                <v-row align="center" class="row--xs">
                    <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                        <like-btn :params="{ _product: item?.product?._id }" class="mt-md-8px">
                            <template #activator="{ active }">
                                <v-btn v-bind="{ ...btn_tertiary }" block class="cursor-default">
                                    <span v-if="active" class="material-icons-outlined primary--text">favorite</span>
                                    <span v-else class="material-icons-outlined grey--text text--darken-4">favorite_border</span>
                                </v-btn>
                            </template>
                        </like-btn>
                    </v-col>
                    <v-col v-if="!hideActions" cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                        <v-btn v-bind="{ ...btn_tertiary }" block @click.prevent.stop.capture="$emit('remove', item)">삭제</v-btn>
                    </v-col>
                </v-row>
            </div>
            <!-- E: Moblie -->
        </template>
    </product-item>
</template>

<script>
import { btn_tertiary, checkbox_primary } from "@/assets/variables";

import CartForm from "@/components/client/shop/cart/cart-form.vue";
import LikeBtn from "../../like/like-btn.vue";
import ProductItem from "../product/product-item.vue";

export default {
    components: {
        CartForm,
        LikeBtn,
        ProductItem,
    },
    props: {
        value: { type: Array, default: () => [] }, // selected
        item: { type: Object, default: () => ({}) }, // like
        hideActions: { type: Boolean, default: false },
    },
    data() {
        return {
            btn_tertiary,
            checkbox_primary,

            likes: [],

            limit: 20,
            summary: { totalCount: 0 },

            selected: [],
            loading: false,
        };
    },
    computed: {
        product() {
            return this.item?.product;
        },
        isSoldOut() {
            return !this.product?.sells;
        },
        isSelected() {
            return this.value.some(({ _id }) => _id == this.item?._id);
        },
    },
    methods: {
        toggle() {
            let selected = [...this.value];
            let index = selected.findIndex(({ _id }) => _id == this.item?._id);
            if (index < 0) selected.push(this.item);
            else selected.splice(index, 1);
            this.$emit("input", selected);
        },
    },
};
</script>

<style></style>
