var render = function render(){
  var _vm$items;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_vm._l(_vm.items, function (order, index) {
    return [_c('list-item', _vm._g(_vm._b({
      key: order._id,
      on: {
        "input": function (o) {
          return _vm.orders[index] = o;
        }
      }
    }, 'list-item', {
      value: order,
      points: _vm.points
    }, false), {
      init: _vm.init
    }))];
  }), _c('v-fade-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading && !((_vm$items = _vm.items) !== null && _vm$items !== void 0 && _vm$items.length),
      expression: "!loading && !items?.length"
    }],
    staticClass: "text-center pt-80px"
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_c('v-icon', {
    attrs: {
      "size": "60px",
      "color": "primary"
    }
  }, [_vm._v("mdi-view-list")])], 1), _c('div', {
    staticClass: "tit tit--sm"
  }, [_vm._v("주문하신 상품이 없습니다.")]), _c('div', {
    staticClass: "txt txt--sm mt-8px"
  }, [_vm._v("원하시는 상품을 주문해주세요.")]), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "to": "/shop"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("쇼핑 계속하기")])], 1)], 1)], 1)])]), _vm.hasMoreItems ? _c('div', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect.quiet",
      value: _vm.loadInfinite,
      expression: "loadInfinite",
      modifiers: {
        "quiet": true
      }
    }],
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "block": ""
    },
    on: {
      "click": _vm.loadMore
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
    loading: _vm.loading
  }), false), [_vm._v(" 더 보기 ")])], 1) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }