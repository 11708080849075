<template>
    <u-dialog v-model="shows">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <div>
            <div class="tit-wrap tit-wrap--sm">
                <div class="tit tit--xxs">배송지 등록/수정</div>
            </div>
            <div class="txt txt--sm mb-8px">자주쓰는 배송지는 최대 30개까지 등록할 수 있습니다.</div>

            <vertical-form-table v-model="form" v-bind="{ items }" class="vertical-form-table--border">
                <template #주소>
                    <v-address-field v-model="form" v-bind="{ ...attrs_input }" basicDestination />
                </template>
            </vertical-form-table>
        </div>

        <template v-slot:actions>
            <div class="w-100 mx-auto">
                <v-row no-gutters justify="center">
                    <v-col cols="12" md="auto">
                        <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large w-100 min-w-sm-140px" @click="save">확인</v-btn>
                    </v-col>
                </v-row>
            </div>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, initDestination } from "@/assets/variables";
import { btn_primary } from "@/assets/variables";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

import UDialog from "@/components/client/dumb/u-dialog.vue";


const items = [
    {
        key: "title",
        term: "배송지명",
        type: "text",
        required: true,
        class: "w-md-358px",
    },
    {
        key: "name",
        term: "받는분",
        type: "text",
        required: true,
        class: "w-md-358px",
    },
    {
        term: "주소",
        // type: "address",
        required: true,
    },
    {
        key: "phone",
        term: "휴대폰",
        type: "phone",
        required: true,
    },
    {
        key: "tel",
        term: "연락처2",
        type: "phone",
        required: false,
    },
].map((item) => ({ ...item, dense: true, outlined: true }));

export default {
    components: {
        UDialog,
        VAddressField,
        VerticalFormTable,
        VAddressField
    },
    props: {
        value: { type: Object, default: initDestination },
    },
    data: () => ({
        btn_primary,

        form: initDestination(),

        shows: false,
        items,
        attrs_input,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
    },
    watch: {
        shows() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.isCreate) {
                this.form = initDestination();
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                this.form = (await api.v1.me.destinations.get({ _id: this.value?._id }))?.destination;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if(!this.validates()) return;
                const { post, put } = api.v1.me.destinations;

                let { ...form } = this.form;

                form.geolocation = await this.$getGeolocationWithKakaoMaps(form.address1);
                const { destination } = await (this.isCreate ? post : put)(form);
                this.form = destination;

                alert("저장되었습니다");
                this.$emit("input", this.form);
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validates(){
            const { form } = this;
            if(!form.title) throw new Error("배송지명을 입력해주세요");
            if(!form.name) throw new Error("받는분을 입력해주세요");
            if(!form.postcode) throw new Error("주소를 입력해주세요");
            if(!form.phone) throw new Error("휴대폰 번호를 입력해주세요");
            return true;
        }
    },
};
</script>

<style></style>
