<template>
    <div class="d-xl-none">
        <v-divider class="border-dark" style="border-width: 2px 0 0" />
        <!-- 반복 -->
        <div v-for="(item, index) in items" :key="index" class="border-b">
            <div class="grey lighten-5">
                <div class="d-flex align-center px-4px py-12px border-b" style="min-height: 54px">
                    <v-row align="center" class="mx-n6px my-n2px">
                        <v-col cols="auto" sm="" class="px-6px py-2px">
                            <v-row align="center" class="ma-n4px">
                                <v-col cols="auto" class="pa-4px">
                                    <span class="txt txt--xs txt--dark font-weight-medium">날짜</span>
                                </v-col>
                                <v-col cols="auto" class="pa-4px">
                                    <span class="txt txt--xs">{{ $dayjs(item.createdAt).format("YYYY.MM.DD") }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="auto" sm="" class="px-6px py-2px">
                            <v-row align="center" class="ma-n4px">
                                <v-col cols="auto" class="pa-4px">
                                    <span class="txt txt--xs txt--dark font-weight-medium">유효기간</span>
                                </v-col>
                                <v-col cols="auto" class="pa-4px">
                                    <span class="txt txt--xs">{{ formatExpiration(item.usage) }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="py-20px">
                <v-row class="row--xs">
                    <v-col cols="auto">
                        <div class="txt txt--sm">
                            <div
                                :class="{
                                    'red--text': (item.amount || 0) < 0,
                                    'secondary--text': 0 < (item.amount || 0),
                                }"
                            >
                                {{ (item.amount || 0) > 0 ? "+" : "" }}{{ item.amount?.format?.() || 0 }}원
                            </div>
                        </div>
                    </v-col>
                    <v-col>
                        <div class="txt txt--sm txt--dark">{{ item.remark }}</div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <!-- //반복 -->
    </div>
</template>

<script>
export default {
    props: {
        items: { type: Array, default: () => [] }, // points
    },
    methods: {
        formatExpiration(usage) {
            if (!usage) return "";
            let { endsAt, startsAt } = usage;
            let text = "";
            if (endsAt) text += dayjs(endsAt).format("YYYY.MM.DD");
            text += " ~ ";
            if (startsAt) text += dayjs(endsAt).format("YYYY.MM.DD");

            text = text.trim();
            if (text == "~") return "무제한";
            else return text;
        },
    },
};
</script>

<style>
</style>