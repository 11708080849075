var render = function render(){
  var _vm$summary;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tabs-wrap tabs-wrap--lg"
  }, [_c('v-tabs', {
    attrs: {
      "centered": "",
      "grow": ""
    }
  }, [_c('tab-primary', {
    attrs: {
      "exact": "",
      "to": "/mypage/coupons"
    }
  }, [_vm._v("보유한 쿠폰 (" + _vm._s((((_vm$summary = _vm.summary) === null || _vm$summary === void 0 ? void 0 : _vm$summary.totalCount) || 0).format()) + ")")]), _c('tab-primary', {
    attrs: {
      "exact": "",
      "to": "/mypage/coupons/download"
    }
  }, [_vm._v("다운로드 가능한 쿠폰")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }