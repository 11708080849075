var render = function render(){
  var _vm$value, _vm$value$createdAt, _vm$value$createdAt$t, _vm$value2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "persistent": "",
      "width": "1200",
      "max-width": "100%",
      "title": "주문상세"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "ma-n2px ma-sm-n6px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2px pa-sm-6px",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('span', {
    staticClass: "tit tit--xxs"
  }, [_vm._v(_vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$createdAt = _vm$value.createdAt) === null || _vm$value$createdAt === void 0 ? void 0 : (_vm$value$createdAt$t = _vm$value$createdAt.toDate) === null || _vm$value$createdAt$t === void 0 ? void 0 : _vm$value$createdAt$t.call(_vm$value$createdAt)))])]), _c('v-col', {
    staticClass: "pa-2px pa-sm-6px",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 주문번호 : " + _vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.orderNo) + " ")])])], 1)], 1), _c('order-view-purchases', _vm._b({}, 'order-view-purchases', _vm.form, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('page-section', {
    staticClass: "page-section--xs py-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('order-view-receiver', {
          on: {
            "input": _vm.init
          },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v;
            },
            expression: "form"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('page-section', {
    staticClass: "page-section--xs py-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('order-view-payment', {
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v;
            },
            expression: "form"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }