var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": "취소 신청",
      "persistent": "",
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm"
        }, [_c('v-col', [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.close
          }
        }, 'v-btn', Object.assign({}, _vm.btn_tertiary), false), [_vm._v("취소")])], 1), _c('v-col', [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.save
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v(" " + _vm._s(_vm.isClaimCompleted ? "취소 신청" : "취소 요청") + " ")])], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', _vm._b({}, 'div', {
    loading: _vm.loading
  }, false), [_vm._l(_vm.purchases, function (purchase, index) {
    var _purchase$createdAt, _purchase$createdAt$t;
    return _c('v-row', {
      key: index
    }, [_c('v-col', [_c('div', {
      staticClass: "tit tit--xs pb-12px"
    }, [_vm._v(_vm._s(purchase === null || purchase === void 0 ? void 0 : (_purchase$createdAt = purchase.createdAt) === null || _purchase$createdAt === void 0 ? void 0 : (_purchase$createdAt$t = _purchase$createdAt.toDate) === null || _purchase$createdAt$t === void 0 ? void 0 : _purchase$createdAt$t.call(_purchase$createdAt)))]), _c('v-divider', {
      staticClass: "grey darken-4",
      staticStyle: {
        "border-width": "2px 0 0"
      }
    }), _c('purchase-item', {
      attrs: {
        "value": purchase,
        "mode": "view",
        "withQuantity": ""
      }
    })], 1)], 1);
  }), _c('v-divider'), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "취소 사유"
    }
  }), _c('v-select', _vm._b({
    attrs: {
      "placeholder": "취소 사유 선택해주세요",
      "items": _vm.claimReasonItems
    },
    model: {
      value: _vm.form.claimReason,
      callback: function ($$v) {
        _vm.$set(_vm.form, "claimReason", $$v);
      },
      expression: "form.claimReason"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "취소 상세"
    }
  }, [_c('span', {
    staticClass: "font-size-12 red--text"
  }, [_vm._v("*카드결제 하신 고객께서는 바로 \"취소 신청\" 버튼을 눌러 주세요.")])]), _c('v-textarea', _vm._b({
    attrs: {
      "placeholder": "취소 상세를 입력해주세요"
    },
    model: {
      value: _vm.form.claimReasonDetails,
      callback: function ($$v) {
        _vm.$set(_vm.form, "claimReasonDetails", $$v);
      },
      expression: "form.claimReasonDetails"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1)], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }